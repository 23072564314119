import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../helpers/general';

export const coursesApiSlice = createApi({
    reducerPath: 'coursesApiSlice',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL.demo }),
    endpoints: (builder) => ({
        getCoursesData: builder.query({
            query: ({ lang, searchParams }) => ({
                url: 'courses',
                headers: {
                    'Content-Type': 'application/json',
                    locale: lang,
                },
                params: { ...searchParams },
            }),
            transformResponse: (res) => {
                 console.log('test',res);
                return res?.data ? {...res.data} : {};
            },
        }),

        getCoursesDataById: builder.query({
            query: ({ lang, searchParams }) => ({
                url: `/course/show`,
                headers: {
                    'Content-Type': 'application/json',
                    locale: lang,
                },
                method: 'GET',
                params: { ...searchParams },
            }),
            transformResponse: (res) => {
                console.log('test',res);

                return res?.data ? { ...res.data } : {};
            },
        }),
    }),
});

export const { useGetCoursesDataQuery, useGetCoursesDataByIdQuery } = coursesApiSlice;
