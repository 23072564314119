import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	CloseButton,
	Col,
	FormGroup,
	FormLabel,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../helpers/general';

// Icons
import { FaDownload } from 'react-icons/fa';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CertificateCheckModalComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';
import CertificateRequestModalComponent from './../CertificateRequestModalComponent/CertificateRequestModalComponent';

const CertificateCheckModalComponent = ({
	setCertificateCheckModalShow,
	...props
}) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const checkCertificateRequest = new AbortController();

	const certificateCodeRef = useRef();

	const [certificate, setCertificate] = useState(null);

	const [certificateRequestModalShow, setCertificateRequestModalShow] =
		useState(false);

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'code':
				certificateCodeRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);

				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const certificateCheckSchema = object().shape({
		code: string()
			.min(2, t('validations:certificateCode.min', { min: 2 }))
			.max(100, t('validations:certificateCode.max', { max: 100 }))
			.required(t('validations:certificateCode.required')),
	});

	const submitCheckCertificateForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		setCertificate(null);
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/check-certificate',
			data: {
				code: values.code,
			},
			headers: {
				locale: language,
				'Content-Type': 'multipart/form-data',
			},
			signal: checkCertificateRequest.signal,
		})
			.then((response) => {
				setCertificate({
					file: response.data.data.certificate.certificate,
					filename: response.data.data.certificate.certificate.split('/').pop(),
				});

				displayToast(response.data.status, response.data.msg);

				// reset submitting
				setSubmitting(false);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				console.log(error);

				if (error.code !== 'ERR_CANCELED') {
					typeof error.response.data.data === typeof {}
						? Object.keys(error.response.data.data).forEach((key) => {
								displayErrors(key);
								displayToast(
									error.response.status,
									error.response.data.data[key][0]
								);
						  })
						: displayToast(error.response.status, error.response.data.data);
				}
			});
	};

	return (
		<Modal
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='certificate-check-modal'
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header>
				<Modal.Title
					id='contained-modal-title-vcenter'
					className='text-capitalize'
				>
					{t('words:verifyCertificate')}
				</Modal.Title>
				<CloseButton
					className={`close-modal mx-0 ${
						lang === 'en' ? 'ms-auto' : 'me-auto'
					}`}
					aria-label='Hide'
					onClick={props.onHide}
				/>
			</Modal.Header>
			<Modal.Body className='position-relative'>
				<Formik
					initialValues={{
						code: '',
					}}
					validationSchema={certificateCheckSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						submitCheckCertificateForm(values, setSubmitting, resetForm, lang);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => (
						<>
							{/* Certificate Form */}
							<Form
								onSubmit={(event) => {
									event.preventDefault();
									handleSubmit();
								}}
								className='p-4 pb-0 overflow-hidden'
							>
								<Row xs={1} sm={2} className='pb-3 overflow-hidden'>
									{/* Certificate Code */}
									<Fade direction='down' delay={20}>
										<FormGroup as={Col} className='h-100'>
											<FormLabel htmlFor='code' className='text-capitalize'>
												{t('words:labels.certificateCode')}
											</FormLabel>
											<Field
												id='code'
												type='text'
												innerRef={certificateCodeRef}
												autoComplete='off'
												name='code'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.code}
												className={`form-control ${
													touched.code && errors.code ? 'is-invalid' : ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='code'
												className='invalid-feedback'
											/>
										</FormGroup>
									</Fade>

									{/* Download Button */}
									<Zoom delay={100}>
										<Col className='h-100 d-flex justify-content-center align-items-end'>
											<ButtonComponent
												title={t('words:buttons.downloadCertificate')}
												href={certificate ? certificate.file : ''}
												target={true}
												icon={
													<FaDownload
														className='position-relative'
														style={{
															margin: lang === 'en' ? '0 8px 0 0' : '0 0 0 8px',
															zIndex: '10',
														}}
													/>
												}
												styles={{
													button: {
														width: 'fit-content',
														margin: '0 auto',
													},
												}}
												disabled={
													isSubmitting || certificate === null ? true : false
												}
											/>
										</Col>
									</Zoom>
								</Row>

								<Row xs={1} className='mt-3 overflow-hidden'>
									{/* Submit Form */}
									<Zoom delay={300}>
										<FormGroup className='h-100 d-flex justify-content-center'>
											<ButtonComponent
												title={
													isSubmitting
														? t('words:buttons.sending')
														: t('words:buttons.findCertificate')
												}
												icon={
													isSubmitting ? (
														<Spinner
															animation='border'
															variant='light'
															size='sm'
															className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
														/>
													) : (
														<></>
													)
												}
												type='submit'
												styles={{
													button: {
														padding: '0.5rem 1rem',
													},
													title: {
														fontWeight: '200',
														fontSize: '16px',
													},
												}}
												disabled={isSubmitting ? true : false}
											/>
										</FormGroup>
									</Zoom>
								</Row>
							</Form>
						</>
					)}
				</Formik>
			</Modal.Body>

			{/* Forgot certificate code? */}
			<Fade direction='up' delay={400}>
				<Modal.Footer
					id='request-certificate'
					className='h-100 border-0 p-3 pt-0 text-muted d-flex justify-content-center align-items-center'
				>
					<span
						className='text-capitalize'
						onClick={(event) => {
							setCertificateRequestModalShow(true);
						}}
					>
						{t('words:buttons.forgotCertificate')}
					</span>
				</Modal.Footer>
			</Fade>

			{/* Request Certificate Modal */}
			<CertificateRequestModalComponent
				show={certificateRequestModalShow}
				onHide={() => {
					setCertificateRequestModalShow(false);
				}}
				setCertificateRequestModalShow={setCertificateRequestModalShow}
				setCertificateCheckModalShow={setCertificateCheckModalShow}
				checkCertificateRequest={checkCertificateRequest}
			/>
		</Modal>
	);
};

export default CertificateCheckModalComponent;
