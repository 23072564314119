import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Icons
import { FaDownload } from 'react-icons/fa';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './TrainingPlanCardComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const TrainingPlanCardComponent = ({ plan }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<Card
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			className='plan-card text-center d-flex flex-column justify-content-center align-items-center p-2'
		>
			<Card.Img
				fluid='true'
				src={plan.image}
				alt='plan thumbnail'
				className='text-capitalize w-100'
				style={{
					height: '6rem',
					objectFit: 'contain',
					objectPosition: 'center',
				}}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = require('./../../assets/images/logos/logo.png');
				}}
			/>

			<Card.Body>
				<Card.Title
					className='text-limit display-6 mb-4 text-break'
					style={{
						fontSize: '22px',
						'--lines': 1,
					}}
				>
					{plan.title}
				</Card.Title>

				<ButtonComponent
					title={t('words:buttons.downloadFile')}
					href={plan.file}
					target={true}
					icon={
						<FaDownload
							className='position-relative'
							style={{
								margin: lang === 'en' ? '0 8px 0 0' : '0 0 0 8px',
								zIndex: '10',
							}}
						/>
					}
					styles={{
						button: {
							width: 'fit-content',
							margin: '0 auto',
						},
					}}
				/>
			</Card.Body>
		</Card>
	);
};

export default TrainingPlanCardComponent;
