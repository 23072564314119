import { Formik } from 'formik';
import 'moment/locale/ar';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Container,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { object } from 'yup';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Components
import ButtonComponent from './../../ButtonComponent/ButtonComponent';

const SearchFormComponent = ({ styles = {} }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	moment.locale('en');

	const navigate = useNavigate();

	const { sections } = useSelector((state) => state.sections);
	const { categories } = useSelector((state) => state.categories);
	const { cities } = useSelector((state) => state.cities);
	const { years } = useSelector((state) => state.years);
	const { months } = useSelector((state) => state.months);

	const searchSchema = object().shape({
		// fname: string()
		// 	.min(2, t('validations:firstName.min', { min: 2 }))
		// 	.max(100, t('validations:firstName.max', { max: 100 }))
		// 	.required(t('validations:firstName.required')),
		// lname: string()
		// 	.min(2, t('validations:lastName.min', { min: 2 }))
		// 	.max(100, t('validations:lastName.max', { max: 100 }))
		// 	.required(t('validations:lastName.required')),
		// email: string()
		// 	.email(t('validations:email.format'))
		// 	.required(t('validations:email.required')),
		// phone: string()
		// 	.min(6, t('validations:phone.min', { min: 6 }))
		// 	.matches(/^[0-9+]+/, t('validations:phone.format'))
		// 	.required(t('validations:phone.required')),
		// message: string()
		// 	.min(2, t('validations:message.min', { min: 2 }))
		// 	.max(255, t('validations:message.max', { max: 255 }))
		// 	.required(t('validations:message.required')),
	});

	const submitSearchForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		const validFilters = Object.entries(values).filter((entry) => {
			return entry[1] !== '' && entry[1] !== null && entry[1].length > 0;
		});

		navigate({
			pathname: `/${lang}/search`,
			search: createSearchParams({
				...Object.fromEntries(validFilters),
			}).toString(),
		});
	};

	return (
		<Container fluid='lg' style={styles.container}>
			<Formik
				initialValues={{
					title: '',
					section_id: '',
					category_id: '',
					city_id: '',
					year: '',
					month: '',
				}}
				validationSchema={searchSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitSearchForm(values, setSubmitting, resetForm, lang);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<>
						{/* Search Form */}
						<Form
							onSubmit={(event) => {
								event.preventDefault();
								// console.log(values);
								handleSubmit();
							}}
							className='p-3 overflow-hidden'
							style={{
								backgroundColor: '#f9fafd',
								border: '1px solid #e5f1fb',
								borderRadius: '0.375rem',
							}}
						>
							<Row xs={3} lg={6} className='g-4 mb-4'>
								{/* Title Filter */}
								<Fade delay={50} direction={lang === 'en' ? 'left' : 'right'}>
									<FormGroup as={Col}>
										<FormLabel
											htmlFor='course_title'
											className='text-capitalize'
											style={{
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												maxWidth: '100%',
												marginBottom: '2px',
											}}
										>
											{t('words:labels.courseKeywords')}
										</FormLabel>
										<FormControl
											id='course_title'
											type='text'
											autoComplete='off'
											name='title'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											className={`text-capitalize ${
												touched.title && errors.title ? 'is-invalid' : ''
											}`}
										/>
									</FormGroup>
								</Fade>

								{/* Sections Filter */}
								<Fade delay={50} direction='down'>
									<FormGroup as={Col}>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_section'
										>
											{t('words:labels.section')}
										</FormLabel>

										<Form.Select
											id='course_section'
											value={values.section_id}
											name='section_id'
											className='text-capitalize'
											onChange={(event) => {
												setFieldValue(
													'section_id',
													Array.from(event.currentTarget.children)
														.slice(1)
														.filter(
															(option) => event.target.value === option.value
														)[0].value
												);
											}}
										>
											<option hidden>{t('words:placeholders.section')}</option>

											{sections &&
												sections.map((section) => (
													<option
														key={section.title}
														value={section.id}
														style={{
															color: '#11142d',
														}}
													>
														{section.title}
													</option>
												))}
										</Form.Select>
									</FormGroup>
								</Fade>

								{/* Categories Filter */}
								<Fade delay={50} direction={lang === 'en' ? 'right' : 'left'}>
									<FormGroup as={Col}>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_category'
										>
											{t('words:labels.category')}
										</FormLabel>

										<Form.Select
											id='course_category'
											value={values.category_id}
											name='category_id'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('category_id', event.target.value)
											}
											disabled={
												categories &&
												categories.filter(
													(category) =>
														+category.section_id === +values.section_id
												).length === 0
													? true
													: false
											}
										>
											{categories &&
											categories.filter(
												(category) =>
													+category.section_id === +values.section_id
											).length === 0 ? (
												values.section_id === '' ? (
													<option hidden>
														{t('sentences:selectSectionFirst')}
													</option>
												) : (
													<option
														style={{
															color: '#11142d',
														}}
													>
														{t('sentences:noNeedForCategory')}
													</option>
												)
											) : (
												<>
													<option hidden>
														{t('words:placeholders.category')}
													</option>

													{categories &&
														categories
															.filter(
																(category) =>
																	+category.section_id === +values.section_id
															)
															.map((category) => (
																<option
																	key={category.title}
																	value={category.id}
																	style={{
																		color: '#11142d',
																	}}
																>
																	{category.title}
																</option>
															))}
												</>
											)}
										</Form.Select>
									</FormGroup>
								</Fade>

								{/* Cities Filter */}
								<Fade delay={100} direction={lang === 'en' ? 'left' : 'right'}>
									<FormGroup as={Col}>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_city'
										>
											{t('words:labels.city')}
										</FormLabel>

										<Form.Select
											id='course_city'
											value={values.city_id}
											name='city_id'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('city_id', event.target.value)
											}
										>
											<option hidden>{t('words:placeholders.city')}</option>

											{cities &&
												cities.map((city) => (
													<option
														key={city.title}
														value={city.id}
														style={{
															color: '#11142d',
														}}
													>
														{city.title}
													</option>
												))}
										</Form.Select>
									</FormGroup>
								</Fade>

								{/* Months Filter */}
								<Fade delay={100} direction='down'>
									<FormGroup as={Col}>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_start_month'
										>
											{t('words:labels.month')}
										</FormLabel>

										<Form.Select
											id='course_start_month'
											value={values.month}
											name='month'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('month', event.target.value)
											}
										>
											<option hidden>{t('words:placeholders.month')}</option>
											{months &&
												months.map((month, index) => (
													<option
														key={index + 1}
														value={index + 1}
														style={{
															color: '#11142d',
														}}
													>
														{month}
													</option>
												))}
										</Form.Select>
									</FormGroup>
								</Fade>

								{/* Years Filter */}
								<Fade delay={100} direction={lang === 'en' ? 'right' : 'left'}>
									<FormGroup as={Col}>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_start_year'
										>
											{t('words:labels.year')}
										</FormLabel>

										<Form.Select
											id='course_start_year'
											value={values.year}
											name='year'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('year', event.target.value)
											}
										>
											<option hidden>{t('words:placeholders.year')}</option>

											{years &&
												years.map((year) => (
													<option
														key={year}
														value={year}
														style={{
															color: '#11142d',
														}}
													>
														{moment(new Date(year, 0, 1)).format('YYYY')}
													</option>
												))}
										</Form.Select>
									</FormGroup>
								</Fade>
							</Row>

							{/* Submit Form */}
							<Zoom delay={150}>
								<FormGroup className='d-flex justify-content-center'>
									<ButtonComponent
										title={
											isSubmitting
												? t('words:buttons.loading')
												: t('words:buttons.searchCourses')
										}
										icon={
											isSubmitting ? (
												<Spinner
													animation='border'
													variant='light'
													size='sm'
													className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
												/>
											) : (
												<></>
											)
										}
										type='submit'
										styles={{
											button: {
												padding: '0.5rem 1rem',
											},
											title: {
												fontWeight: '200',
												fontSize: '16px',
											},
										}}
										disabled={isSubmitting ? true : false}
									/>
								</FormGroup>
							</Zoom>
						</Form>
					</>
				)}
			</Formik>
		</Container>
	);
};

export default SearchFormComponent;
