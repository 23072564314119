import { configureStore } from '@reduxjs/toolkit';

// Slices
import aboutDataReducer from './reducers/aboutData.reducer';
import categoriesReducer from './reducers/categories.reducer';
import citiesReducer from './reducers/cities.reducer';
import coursesReducer from './reducers/courses.reducer';
import homeDataReducer from './reducers/homeData.reducer';
import monthsReducer from './reducers/months.reducer';
import plansReducer from './reducers/plans.reducer';
import searchReducer from './reducers/search.reducer';
import sectionsReducer from './reducers/sections.reducer';
import settingsDataReducer from './reducers/settingsData.reducer';
import yearsReducer from './reducers/years.reducer';
import { coursesApiSlice } from './RTKQuery/coursesApiSlice';


export const store = configureStore({
	reducer: {
		homeData: homeDataReducer,
		sections: sectionsReducer,
		categories: categoriesReducer,
		courses: coursesReducer,
		cities: citiesReducer,
		plans: plansReducer,
		years: yearsReducer,
		months: monthsReducer,
		aboutData: aboutDataReducer,
		settingsData: settingsDataReducer,
		search: searchReducer,
		[coursesApiSlice.reducerPath]: coursesApiSlice.reducer,
	},

	devTools: false,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([coursesApiSlice.middleware]),
});
