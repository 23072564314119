import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchSections = createAsyncThunk(
	'sectionsSlice/fetchSections',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/sections',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const fetchSingleSection = createAsyncThunk(
	'sectionsSlice/fetchSingleSection',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/section',
			params: {
				id: searchParams?.id,
			},
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const sectionsSlice = createSlice({
	initialState: {
		sections: [],
		section: {},
		sectionCategories: [],
		sectionCourses: [],
		isSectionsLoading: true,
		isSingleSectionLoading: true,
	},
	name: 'sectionsSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchSections.pending, (state, action) => {
			state.isSectionsLoading = true;
		});
		builder.addCase(fetchSingleSection.pending, (state, action) => {
			state.isSingleSectionLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchSections.fulfilled, (state, action) => {
			state.sections = action.payload.section;
			state.isSectionsLoading = false;
		});
		builder.addCase(fetchSingleSection.fulfilled, (state, action) => {
			state.section = action.payload.section;
			state.sectionCategories = action.payload.categories;
			state.sectionCourses = action.payload.courses;
			state.isSingleSectionLoading = false;
		});

		// Rejected
		builder.addCase(fetchSections.rejected, (state, action) => {
			state.isSectionsLoading = true;
			console.log('rejected:', action.payload);
		});
		builder.addCase(fetchSingleSection.rejected, (state, action) => {
			state.isSingleSectionLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = sectionsSlice.actions;

// Reducer
export default sectionsSlice.reducer;
