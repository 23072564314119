import 'aos/dist/aos.css'; // You can also use <link> for styles
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect } from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCategory } from './../../store/reducers/categories.reducer';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CategoryDetailsPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';
import MainPreloaderComponent from './../../components/MainPreloaderComponent/MainPreloaderComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const CategoryDetailsPage = () => {
	// i18next
	const { lang, section_id, category_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	moment.locale('en');

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			fetchSingleCategory({
				language: lang ?? 'ar',
				searchParams: { id: category_id },
			})
		);
		// eslint-disable-next-line
	}, [lang, section_id, category_id]);

	const { category, categoryCourses, isSingleCategoryLoading } = useSelector(
		(state) => state.categories
	);

	const breadcrumbItems = category && [
		{ title: t('words:breadcrumb.home'), href: `/${lang}`, isActive: false },
		{
			title: category?.section,
			href: `/${lang}/section/${section_id}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.availableCourses'),
			href: '',
			isActive: true,
		},
	];

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return isSingleCategoryLoading ? (
		<MainPreloaderComponent />
	) : (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id={`section-${section_id}`}
			className='page'
		>
			<>
				{/* Meta Tags */}
				<MetaTagsComponent pageTitle={t('words:windowTab.categoryDetails')} />

				{/* Breadcrumb */}
				<BreadcrumbComponent title={category.title} items={breadcrumbItems} />

				<Container fluid='md'>
					<Row
						xs={1}
						className='table-responsive table-scroll'
						style={{
							position: 'relative',
							maxHeight: '500px',
						}}
					>
						<Table variant='light' striped hover>
							<thead className='table-dark text-white'>
								<tr>
									<th className='text-capitalize'>{t('words:labels.code')}</th>
									<th className='text-capitalize'>
										{t('words:labels.courseTitle')}
									</th>
									<th className='text-capitalize'>{t('words:labels.city')}</th>
									<th className='text-capitalize'>
										{t('words:labels.startDate')}
									</th>
									<th className='text-capitalize'>
										{t('words:labels.endDate')}
									</th>
									<th className='text-capitalize'>{t('words:labels.price')}</th>
									<th className='text-capitalize'>
										{t('words:labels.details')}
									</th>
								</tr>
							</thead>
							<tbody>
								{categoryCourses.map((course, index) => (
									<tr key={index}>
										<td>{course.code}</td>
										<td className='course-title text-capitalize'>
											<Link
												to={`/${lang}/section/${section_id}/course/${course.id}`}
												style={{
													textDecoration: 'none',
												}}
											>
												{course.title}
											</Link>
										</td>
										<td className='text-capitalize'>{course.city}</td>
										<td className='text-capitalize'>
											{moment(course.start_date).format(
												t('words:dateFormatter')
											)}
										</td>
										<td className='text-capitalize'>
											{moment(course.end_date).format(t('words:dateFormatter'))}
										</td>
										<td className='text-capitalize'>{`$${course.price}`}</td>
										<td className='text-capitalize'>
											<Link
												to={`/${lang}/section/${section_id}/course/${course.id}`}
												style={{
													textDecoration: 'none',
												}}
											>
												<ButtonComponent
													title={t('words:buttons.inquiry')}
													styles={{
														button: {
															padding: '0.25rem 1rem',
														},
														title: {
															fontWeight: '400',
														},
													}}
												/>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Row>
				</Container>
			</>
		</section>
	);
};

export default CategoryDetailsPage;
