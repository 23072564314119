import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

const LoadingPageComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<div
			className='d-flex justify-content-center align-items-center w-100'
			style={{
				minHeight: '100vh',
			}}
		>
			<Spinner
				animation='border'
				role='status'
				style={{
					borderColor: '#b94a4b',
					borderRightColor: 'transparent',
				}}
			>
				<span className='visually-hidden text-capitalize'>
					{t('words:buttons.loading')}
				</span>
			</Spinner>
		</div>
	);
};

export default LoadingPageComponent;
