import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CategoryCardComponent.styles.css';

const CategoryCardComponent = ({ category }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<Card className='category-card text-center'>
			<Card.Img
				variant='top'
				fluid='true'
				src={category.image ?? require('./../../assets/images/logos/logo.png')}
				alt='category thumbnail'
				className='text-capitalize w-100'
				style={{
					height: '8rem',
					objectFit: 'cover',
					objectPosition: 'center',
				}}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = require('./../../assets/images/logos/logo.png');
				}}
			/>
			<Card.Body>
				<Card.Title
					className='category-card-title text-capitalize text-limit'
					style={{
						fontWeight: '500',
						fontSize: '18px',
						'--lines': 1,
					}}
				>
					{category.title}
				</Card.Title>
				<Card.Text
					className='text-capitalize'
					style={{
						fontSize: '14px',
						fontWeight: '500',
						color: '#808191',
						lineHeight: '1.2',
					}}
				>
					 {t('words:browse_courses')}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default CategoryCardComponent;
