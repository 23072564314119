import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchPlans = createAsyncThunk(
	'plansSlice/fetchPlans',
	async (language = 'ar', { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/plans',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const plansSlice = createSlice({
	initialState: {
		plans: [],
		isPlansLoading: true,
	},
	name: 'plansSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchPlans.pending, (state, action) => {
			state.isPlansLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchPlans.fulfilled, (state, action) => {
			state.plans = action.payload.plans;
			state.isPlansLoading = false;
		});

		// Rejected
		builder.addCase(fetchPlans.rejected, (state, action) => {
			state.isPlansLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = plansSlice.actions;

// Reducer
export default plansSlice.reducer;
