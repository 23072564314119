import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchYears = createAsyncThunk(
	'yearsSlice/fetchYears',
	async (language = 'ar', { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/years',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const yearsSlice = createSlice({
	initialState: {
		years: [],
		isYearsLoading: true,
	},
	name: 'yearsSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchYears.pending, (state, action) => {
			state.isYearsLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchYears.fulfilled, (state, action) => {
			state.years = action.payload.sort((a, b) => a - b);
			state.isYearsLoading = false;
		});

		// Rejected
		builder.addCase(fetchYears.rejected, (state, action) => {
			state.isYearsLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = yearsSlice.actions;

// Reducer
export default yearsSlice.reducer;
