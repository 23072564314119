import { Form, Formik } from 'formik';
import 'moment/locale/ar';
import React, { useEffect } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Container,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
	Row,
	Spinner,
} from 'react-bootstrap';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { object } from 'yup';

// Icons
import { FaRegKeyboard } from 'react-icons/fa';
import { FiCalendar, FiFilter, FiLayers } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';

// i18next
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './MainSearchFormComponent.styles.css';

// Components
import ButtonComponent from './../../ButtonComponent/ButtonComponent';
import IconTextComponent from './../../IconTextComponent/IconTextComponent';

const MainSearchFormComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const navigate = useNavigate();

	const { sections } = useSelector((state) => state.sections);
	const { categories } = useSelector((state) => state.categories);
	const { cities } = useSelector((state) => state.cities);
	const { months } = useSelector((state) => state.months);

	const searchSchema = object().shape({
		// fname: string()
		// 	.min(2, t('validations:firstName.min', { min: 2 }))
		// 	.max(100, t('validations:firstName.max', { max: 100 }))
		// 	.required(t('validations:firstName.required')),
		// lname: string()
		// 	.min(2, t('validations:lastName.min', { min: 2 }))
		// 	.max(100, t('validations:lastName.max', { max: 100 }))
		// 	.required(t('validations:lastName.required')),
		// email: string()
		// 	.email(t('validations:email.format'))
		// 	.required(t('validations:email.required')),
		// phone: string()
		// 	.min(6, t('validations:phone.min', { min: 6 }))
		// 	.matches(/^[0-9+]+/, t('validations:phone.format'))
		// 	.required(t('validations:phone.required')),
		// message: string()
		// 	.min(2, t('validations:message.min', { min: 2 }))
		// 	.max(255, t('validations:message.max', { max: 255 }))
		// 	.required(t('validations:message.required')),
	});

	const submitSearchForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		const validFilters = Object.entries(values).filter((entry) => {
			return entry[1] !== '' && entry[1] !== null && entry[1].length > 0;
		});

		navigate({
			pathname: `/${lang}/search`,
			search: createSearchParams({
				...Object.fromEntries(validFilters),
			}).toString(),
		});
	};

	return (
		<Container fluid className='main-search-form-component px-0'>
			<Formik
				initialValues={{
					title: '',
					section_id: '',
					category_id: '',
					city_id: '',
					year: '',
					month: '',
				}}
				validationSchema={searchSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitSearchForm(values, setSubmitting, resetForm, lang);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<fieldset
						className='p-0'
						style={{
							border: '1px solid #ffffff',
							borderRadius: '1rem',
							maxWidth: '25rem',
						}}
					>
						<legend id='form-title' className='text-capitalize float-none'>
							<Zoom delay={10}>{t('words:courseFinder')}</Zoom>
						</legend>
						<Form
							onSubmit={(event) => {
								event.preventDefault();
								handleSubmit();
							}}
							id='search-form'
							className='p-4 pt-0 position-relative'
						>
							<Row xs={1} className='overflow-hidden g-2 gy-3'>
								{/* Title Filter */}
								<Fade direction='down' delay={20}>
									<FormGroup className='form-group px-0'>
										<FormLabel className='group-title' htmlFor='course_title'>
											<IconTextComponent
												text={t('words:labels.courseKeywords')}
												icon={<FaRegKeyboard size={20} />}
											/>
										</FormLabel>
										<FormControl
											id='course_title'
											type='text'
											autoComplete='off'
											placeholder={t('words:placeholders.courseTitle')}
											name='title'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											className={`text-capitalize ${
												touched.title && errors.title ? 'is-invalid' : ''
											}`}
										/>
									</FormGroup>
								</Fade>

								{/* Sections Filter */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={40}>
									<FormGroup className='form-group px-0'>
										<FormLabel className='group-title' htmlFor='course_section'>
											<IconTextComponent
												text={t('words:labels.section')}
												icon={<FiLayers size={20} />}
											/>
										</FormLabel>

										<FormSelect
											id='course_section'
											value={values.section_id}
											name='section_id'
											className='text-capitalize'
											onChange={(event) => {
												setFieldValue(
													'section_id',
													Array.from(event.currentTarget.children)
														.slice(1)
														.filter(
															(option) => event.target.value === option.value
														)[0].value
												);
											}}
										>
											<option hidden>{t('words:placeholders.section')}</option>

											{sections.map((section) => (
												<option
													key={section.title}
													value={section.id}
													style={{
														color: '#11142d',
													}}
												>
													{section.title}
												</option>
											))}
										</FormSelect>
									</FormGroup>
								</Fade>

								{/* Categories Filter */}
								<Fade direction={lang === 'en' ? 'right' : 'left'} delay={40}>
									<FormGroup className='form-group px-0'>
										<FormLabel
											className='group-title'
											htmlFor='course_category'
										>
											<IconTextComponent
												text={t('words:labels.category')}
												icon={<FiFilter size={20} />}
											/>
										</FormLabel>

										<FormSelect
											id='course_category'
											value={values.category_id}
											name='category_id'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('category_id', event.target.value)
											}
											disabled={
												categories.filter(
													(category) =>
														+category.section_id === +values.section_id
												).length === 0
													? true
													: false
											}
										>
											{categories.filter(
												(category) =>
													+category.section_id === +values.section_id
											).length === 0 ? (
												values.section_id === '' ? (
													<option hidden>
														{t('sentences:selectSectionFirst')}
													</option>
												) : (
													<option
														style={{
															color: '#11142d',
														}}
													>
														{t('sentences:noNeedForCategory')}
													</option>
												)
											) : (
												<>
													<option hidden>
														{t('words:placeholders.category')}
													</option>

													{categories
														.filter(
															(category) =>
																+category.section_id === +values.section_id
														)
														.map((category) => (
															<option
																key={category.title}
																value={category.id}
																style={{
																	color: '#11142d',
																}}
															>
																{category.title}
															</option>
														))}
												</>
											)}
										</FormSelect>
									</FormGroup>
								</Fade>

								{/* Cities Filter */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={60}>
									<FormGroup className='form-group px-0'>
										<FormLabel className='group-title' htmlFor='course_city'>
											<IconTextComponent
												text={t('words:labels.city')}
												icon={<MdOutlineLocationOn size={20} />}
											/>
										</FormLabel>

										<FormSelect
											id='course_city'
											value={values.city_id}
											name='city_id'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('city_id', event.target.value)
											}
										>
											<option hidden>{t('words:placeholders.city')}</option>

											{cities.map((city) => (
												<option
													key={city.title}
													value={city.id}
													style={{
														color: '#11142d',
													}}
												>
													{city.title}
												</option>
											))}
										</FormSelect>
									</FormGroup>
								</Fade>

								{/* Months Filter */}
								<Fade direction='down' delay={80}>
									<FormGroup className='form-group px-0'>
										<FormLabel
											className='group-title'
											htmlFor='course_start_month'
										>
											<IconTextComponent
												text={t('words:labels.month')}
												icon={<FiCalendar size={20} />}
											/>
										</FormLabel>

										<FormSelect
											id='course_start_month'
											value={values.month}
											name='month'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('month', event.target.value)
											}
										>
											<option hidden>{t('words:placeholders.month')}</option>

											{months.map((month, index) => (
												<option
													key={index + 1}
													value={index + 1}
													style={{
														color: '#11142d',
													}}
												>
													{month}
												</option>
											))}
										</FormSelect>
									</FormGroup>
								</Fade>
							</Row>

							{/* Submit Form */}
							<Zoom delay={100}>
								<FormGroup className='d-flex justify-content-center mt-4'>
									<ButtonComponent
										title={
											isSubmitting
												? t('words:buttons.loading')
												: t('words:buttons.searchCourses')
										}
										icon={
											isSubmitting ? (
												<Spinner
													animation='border'
													variant='light'
													size='sm'
													className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
												/>
											) : (
												<></>
											)
										}
										type='submit'
										styles={{
											button: {
												padding: '0.5rem 1rem',
											},
											title: {
												fontWeight: '200',
												fontSize: '16px',
											},
										}}
										disabled={isSubmitting ? true : false}
									/>
								</FormGroup>
							</Zoom>
						</Form>
					</fieldset>
				)}
			</Formik>
		</Container>
	);
};

export default MainSearchFormComponent;
