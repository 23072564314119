import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './NotFoundPage.styles.css';

const NotFoundPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			fluid
			id='not-found-page'
			className='page text-center d-flex align-items-center bg-dark text-white'
		>
			<Container fluid='md' className='my-auto'>
				<Row xs={1} lg={2} className='g-4'>
					<Fade direction={lang === 'en' ? 'left' : 'right'} delay={100}>
						<Col className='h-100 align-middle'>
							{/* 404 Image */}
							<Image
								fluid
								src={require('./../../assets/images/not-found.png')}
								alt='not found page'
								className='img-fluid text-capitalize w-100 h-100'
								style={{
									objectFit: 'contain',
									objectPosition: 'center',
								}}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = require('./../../assets/images/logos/logo.png');
								}}
							/>
						</Col>
					</Fade>

					<Fade direction={lang === 'en' ? 'right' : 'left'} delay={100}>
						<Col className='h-100 d-flex flex-column justify-content-center align-items-center p-5'>
							{/* Main Text */}
							<h2 className='display-6 mb-4'>{t('sentences:errors.404')}</h2>

							{/* Redirect Button To Home */}
							<Link
								to={`/${lang}`}
								className='text-decoration-none text-reset'
								style={{
									fontWeight: '600',
									fontSize: '18px',
								}}
							>
								<div className='not-found-btn rounded-2 px-4 py-2'>
									<span
										className='position-relative'
										style={{
											zIndex: '10',
										}}
									>
										{t('words:buttons.goHome')}
									</span>
								</div>
							</Link>
						</Col>
					</Fade>
				</Row>
			</Container>
		</Container>
	);
};

export default NotFoundPage;
