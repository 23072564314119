import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleSection } from './../../store/reducers/sections.reducer';

// i18next
import { useTranslation } from 'react-i18next';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import CategoryCardComponent from './../../components/CategoryCardComponent/CategoryCardComponent';
import CourseCardComponent from './../../components/CourseCardComponent/CourseCardComponent';
import MainPreloaderComponent from './../../components/MainPreloaderComponent/MainPreloaderComponent';
import SearchFormComponent from './../../components/_Forms/SearchFormComponent/SearchFormComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const SectionPage = () => {
	// i18next
	const { lang, section_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	moment.locale('en');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			fetchSingleSection({
				language: lang ?? 'ar',
				searchParams: { id: section_id },
			})
		);
		// eslint-disable-next-line
	}, [lang, section_id]);

	const { section, sectionCategories, sectionCourses, isSingleSectionLoading } =
		useSelector((state) => state.sections);

	const breadcrumbItems = section && [
		{ title: t('words:breadcrumb.home'), href: `/${lang}`, isActive: false },
		{ title: section?.title, href: '', isActive: true },
	];

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return isSingleSectionLoading ? (
		<MainPreloaderComponent />
	) : (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id={`section-${section_id}`}
			className='page'
		>
			<>
				{/* Meta Tags */}
				<MetaTagsComponent pageTitle={section?.title} />

				{/* Breadcrumb */}
				<BreadcrumbComponent title={section?.title} items={breadcrumbItems} />

				{/* Search Form */}
				{section && sectionCategories && sectionCategories?.length > 0 && (
					<SearchFormComponent
						styles={{
							container: {
								marginTop: '-1rem',
								paddingBottom: '2.5rem',
							},
						}}
					/>
				)}

				<Container fluid='md'>
					{/* Categories Listing */}
					{section && sectionCategories && sectionCategories?.length > 0 && (
						<Row xs={1} md={2} lg={4} className='g-4'>
							{sectionCategories?.map((category, index) => (
								<Fade key={index} delay={index * 50} direction='up'>
									<Col>
										<Link
											to={
												category.courses_number > 0
													? `/${lang}/section/${section_id}/category/${category.id}`
													: ''
											}
											style={{
												textDecoration: 'none',
											}}
										>
											<CategoryCardComponent category={category} />
										</Link>
									</Col>
								</Fade>
							))}
						</Row>
					)}

					{/* Courses Without Category */}
					{section && sectionCourses && sectionCourses?.length > 0 && (
						<Row xs={1} md={2} lg={4} className='g-4'>
							{sectionCourses?.map((course, index) => (
								<Fade key={index} delay={index * 50} direction='up'>
									<Col>
										<CourseCardComponent course={course} />
									</Col>
								</Fade>
							))}
						</Row>
					)}

					{/* No Data Available */}
					{section &&
						sectionCourses?.length === 0 &&
						sectionCategories?.length === 0 && (
							<Row xs={1}>
								<Fade delay={50} direction='up'>
									<Col className='error'>
										{t('sentences:errors.noData', { title: t('words:data') })}
									</Col>
								</Fade>
							</Row>
						)}
				</Container>
			</>
		</section>
	);
};

export default SectionPage;
