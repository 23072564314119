import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import './BreadcrumbComponent.styles.css';

const BreadcrumbComponent = ({
	title,
	items,
	animationType = 'animate__fadeInDown',
}) => {
	const { lang } = useParams();

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			fluid
			className='mb-5'
			id='breadcrumb'
			style={{
				'--bg-image': `url(${require('./../../assets/images/breadcrumb/happy-groupmates_1098-13346.webp')})`,
			}}
		>
			<Container
				fluid='md'
				style={{
					padding: '120px 0 80px',
				}}
			>
				<Breadcrumb
					className={`animate__animated ${animationType}`}
					style={{
						marginBottom: '40px',
					}}
				>
					{items.map((item, index) => (
						<Link
							to={item.href}
							className={`breadcrumb-item text-capitalize ${
								item.isActive ? 'active' : ''
							}`}
							key={`${item.title}-${index}`}
						>
							{item.title}
						</Link>
					))}
				</Breadcrumb>

				<h1
					className={`m-0 p-0 text-capitalize animate__animated ${animationType} animate__delay-1s`}
					style={{
						fontSize: '23px',
						'--animate-delay': '0.5s',
					}}
				>
					{title}
				</h1>
			</Container>
		</Container>
	);
};

export default BreadcrumbComponent;
