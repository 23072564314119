import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import TrainingPlanCardComponent from './../../components/TrainingPlanCardComponent/TrainingPlanCardComponent';
import SearchFormComponent from './../../components/_Forms/SearchFormComponent/SearchFormComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const TrainingPlanPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{ title: t('words:breadcrumb.home'), href: `/${lang}`, isActive: false },
		{
			title: t('words:breadcrumb.trainingPlans'),
			href: '',
			isActive: true,
		},
	];

	const { plans } = useSelector((state) => state.plans);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='training-plan-page'
			className='page'
		>
			{/* Meta Tags */}
			<MetaTagsComponent pageTitle={t('words:windowTab.trainingPlans')} />

			{/* Breadcrumb */}
			<BreadcrumbComponent
				title={t('words:breadcrumb.trainingPlans')}
				items={breadcrumbItems}
			/>

			<Container fluid='md'>
				{/* Search Form */}
				<SearchFormComponent
					styles={{
						container: {
							paddingBottom: '2.5rem',
						},
					}}
				/>

				<Row xs={1} md={2} lg={4} className='g-4'>
					{plans.map(
						(plan, index) =>
							plan.file && (
								<Fade key={index} delay={index * 50} direction='up'>
									<Col>
										<TrainingPlanCardComponent plan={plan} />
									</Col>
								</Fade>
							)
					)}
				</Row>
			</Container>
		</section>
	);
};

export default TrainingPlanPage;
