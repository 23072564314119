import { Formik } from "formik";
import moment from "moment";
import "moment/locale/ar";
import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import {
  Badge,
  CloseButton,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Pagination,
  Row,
  Stack,
} from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { object } from "yup";

// Icons
import { BsCalendar2Month } from "react-icons/bs";
import { FaRegKeyboard } from "react-icons/fa";
import { FiCalendar, FiFilter, FiLayers } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchCourses } from "./../../store/reducers/search.reducer";
import { useGetCoursesDataQuery } from "../../store/RTKQuery/coursesApiSlice";

// i18next
import { useTranslation } from "react-i18next";

// Styles
import "react-toastify/dist/ReactToastify.css";
import "./SearchResultPage.styles.css";

// Components
import BreadcrumbComponent from "./../../components/BreadcrumbComponent/BreadcrumbComponent";
import ButtonComponent from "./../../components/ButtonComponent/ButtonComponent";
import CourseCardComponent from "./../../components/CourseCardComponent/CourseCardComponent";
import IconTextComponent from "./../../components/IconTextComponent/IconTextComponent";
import LoadingPageComponent from "./../../components/LoadingPageComponent/LoadingPageComponent";
import NoResultsComponent from "./../../components/NoResultsComponent/NoResultsComponent";
import MetaTagsComponent from "./../../components/_MetaTagsComponent/MetaTagsComponent";

const SearchResultPage = () => {
  // i18next
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang ?? "ar");
    // eslint-disable-next-line
  }, [lang]);

  //pagination
  const [page, setPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  moment.locale("en");

  const breadcrumbItems = [
    {
      title: t("words:breadcrumb.home"),
      href: `/${lang}`,
      isActive: false,
    },
    {
      title: t("words:breadcrumb.searchResults"),
      href: "",
      isActive: true,
    },
  ];

  const searchSchema = object().shape({
    // fname: string()
    // 	.min(2, t('validations:firstName.min', { min: 2 }))
    // 	.max(100, t('validations:firstName.max', { max: 100 }))
    // 	.required(t('validations:firstName.required')),
    // lname: string()
    // 	.min(2, t('validations:lastName.min', { min: 2 }))
    // 	.max(100, t('validations:lastName.max', { max: 100 }))
    // 	.required(t('validations:lastName.required')),
    // email: string()
    // 	.email(t('validations:email.format'))
    // 	.required(t('validations:email.required')),
    // phone: string()
    // 	.min(6, t('validations:phone.min', { min: 6 }))
    // 	.matches(/^[0-9+]+/, t('validations:phone.format'))
    // 	.required(t('validations:phone.required')),
    // message: string()
    // 	.min(2, t('validations:message.min', { min: 2 }))
    // 	.max(255, t('validations:message.max', { max: 255 }))
    // 	.required(t('validations:message.required')),
  });

  const addNewValue = (setFieldValue, values, fieldName, value) => {
    if (fieldName === "title") {
      searchParams.set(fieldName, value);
      setSearchParams(searchParams);
      return;
    }

    setFieldValue(
      fieldName,
      [...values[fieldName], value].sort((a, b) => a - b)
    );
    values[fieldName] = [...values[fieldName], value].sort((a, b) => a - b);

    // Update Search Params
    if (searchParams.has(fieldName)) {
      searchParams.set(
        fieldName,
        [
          ...searchParams
            .get(fieldName)
            .split(",")
            .filter((currentValue) => currentValue !== "")
            .sort((a, b) => a - b),
          value,
        ].sort((a, b) => a - b)
      );
    } else {
      searchParams.set(fieldName, value);
    }

    setSearchParams(searchParams);
  };

  const removeExistingValue = (setFieldValue, values, fieldName, value) => {
    if (fieldName === "title") {
      setFieldValue(fieldName, "");
      values[fieldName] = "";

      searchParams.delete(fieldName);
      setSearchParams(searchParams);
    } else {
      setFieldValue(
        fieldName,
        values[fieldName].filter((currentValue) => +currentValue !== +value)
      );

      values[fieldName] = values[fieldName].filter(
        (currentValue) => +currentValue !== +value
      );
    }

    if (searchParams.has(fieldName)) {
      searchParams.set(
        fieldName,
        searchParams
          .get(fieldName)
          .split(",")
          .filter(
            (currentValue) => currentValue !== "" && currentValue !== value
          )
      );

      if (
        searchParams
          .get(fieldName)
          .split(",")
          .filter((value) => value !== "").length === 0
      ) {
        searchParams.delete(fieldName);
      }

      setSearchParams(searchParams);
    }
  };

  const handleBadgeRemoval = (values, setFieldValue, fieldName, value) => {
    removeExistingValue(setFieldValue, values, fieldName, value);
  };

  //! NO NEED TO USE THIS FUNCTION ... IT'S EMPTY
  //! WE USE (searchParams) WITHIN (useEffect) TO HANDLE FETCHING NEW REQUESTS
  const submitSearchForm = () => {};

  // Fetch New Search Query
  const dispatch = useDispatch();

  // RTKQuery
  const {
    data: CoursesData,
    isLoading,
    isError,
    refetch,
  } = useGetCoursesDataQuery({
    lang: lang ?? "ar",
    searchParams: {
      title: searchParams.has("title") ? searchParams.get("title") : "",
      section_id: searchParams.has("section_id")
        ? JSON.stringify([searchParams.get("section_id")])
        : "",
      category_id: searchParams.has("category_id")
        ? JSON.stringify([searchParams.get("category_id")])
        : "",
      city_id: searchParams.has("city_id")
        ? JSON.stringify([searchParams.get("city_id")])
        : "",
      year: searchParams.has("year") ? searchParams.get("year") : "",
      month: searchParams.has("month") ? searchParams.get("month") : "",
      page: page,
    },
  });

  useEffect(() => {
    refetch();
    // dispatch(
    // 	fetchSearchCourses({
    // 		language: lang ?? 'ar',
    // 		searchParams: {
    // 			title: searchParams.has('title') ? searchParams.get('title') : '',
    // 			section_id: searchParams.has('section_id')
    // 				? JSON.stringify([searchParams.get('section_id')])
    // 				: '',
    // 			category_id: searchParams.has('category_id')
    // 				? JSON.stringify([searchParams.get('category_id')])
    // 				: '',
    // 			city_id: searchParams.has('city_id')
    // 				? JSON.stringify([searchParams.get('city_id')])
    // 				: '',
    // 			year: searchParams.has('year') ? searchParams.get('year') : '',
    // 			month: searchParams.has('month') ? searchParams.get('month') : '',
    // 		},
    // 	})
    // );

    // eslint-disable-next-line
  }, [lang, searchParams]);

  // Redux
  const { sections } = useSelector((state) => state.sections);
  const { categories } = useSelector((state) => state.categories);
  const { cities } = useSelector((state) => state.cities);
  const { years } = useSelector((state) => state.years);
  const { months } = useSelector((state) => state.months);
  // const { courses, isSearchLoading } = useSelector((state) => state.search);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [lang, searchParams]);

  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      lang={lang ?? "ar"}
      id="search-result-page"
      className="page"
    >
      {/* Meta Tags */}
      <MetaTagsComponent pageTitle={t("words:windowTab.searchResults")} />

      {/* Breadcrumb */}
      <BreadcrumbComponent
        title={t("words:breadcrumb.searchResults")}
        items={breadcrumbItems}
      />

      <Container fluid="md" className="overflow-hidden">
        <Row xs={1} lg={2} className="g-4">
          {/* Search Form */}
          <Col lg={3}>
            <Formik
              initialValues={{
                title: searchParams.has("title")
                  ? searchParams.get("title")
                  : "",
                section_id: searchParams.has("section_id")
                  ? searchParams.get("section_id").split(",")
                  : "",
                category_id: searchParams.has("category_id")
                  ? searchParams.get("category_id").split(",")
                  : [],
                city_id: searchParams.has("city_id")
                  ? searchParams.get("city_id").split(",")
                  : [],
                year: searchParams.has("year")
                  ? searchParams.get("year").split(",")
                  : "",
                month: searchParams.has("month")
                  ? searchParams.get("month").split(",")
                  : "",
              }}
              validationSchema={searchSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                submitSearchForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <>
                  {/* Search Badges */}
                  <Row className="px-2 mb-3">
                    {/* Title Badge */}
                    {values?.title !== "" && (
                      <Badge>
                        {values?.title ?? ""}
                        <CloseButton
                          className={`${lang === "en" ? "ms-2" : "me-2"}`}
                          onClick={() => {
                            handleBadgeRemoval(
                              values,
                              setFieldValue,
                              "title",
                              ""
                            );
                            setPage(1);
                          }}
                        />
                      </Badge>
                    )}

                    {/* Section Badge */}
                    {values?.section_id?.length > 0 &&
                      values?.section_id.map((currentSection, index) => (
                        <Badge key={index} id={currentSection}>
                          {
                            sections.find(
                              (section) => +currentSection === +section.id
                            )?.title
                          }

                          <CloseButton
                            className={`${lang === "en" ? "ms-2" : "me-2"}`}
                            onClick={(event) => {
                              handleBadgeRemoval(
                                values,
                                setFieldValue,
                                "section_id",
                                event.target.parentElement.id
                              );
                              setPage(1);
                            }}
                          />
                        </Badge>
                      ))}

                    {/* Category Badge */}
                    {values?.category_id?.length > 0 &&
                      values?.category_id.map((currentCategory, index) => (
                        <Badge key={index} id={currentCategory}>
                          {
                            categories.find(
                              (category) => +currentCategory === +category.id
                            )?.title
                          }

                          <CloseButton
                            className={`${lang === "en" ? "ms-2" : "me-2"}`}
                            onClick={(event) => {
                              handleBadgeRemoval(
                                values,
                                setFieldValue,
                                "category_id",
                                event.target.parentElement.id
                              );
                              setPage(1);
                            }}
                          />
                        </Badge>
                      ))}

                    {/* City Badge */}
                    {values?.city_id?.length > 0 &&
                      values?.city_id.map((currentCity, index) => (
                        <Badge key={index} id={currentCity}>
                          {
                            cities.find((city) => +currentCity === +city.id)
                              ?.title
                          }

                          <CloseButton
                            className={`${lang === "en" ? "ms-2" : "me-2"}`}
                            onClick={(event) => {
                              handleBadgeRemoval(
                                values,
                                setFieldValue,
                                "city_id",
                                event.target.parentElement.id
                              );
                              setPage(1);
                            }}
                          />
                        </Badge>
                      ))}

                    {/* Month Badge */}
                    {values?.month?.length > 0 &&
                      values?.month.map((currentMonth, index) => (
                        <Badge key={index} id={currentMonth}>
                          {months.find(
                            (_, index) => +currentMonth === +index + 1
                          )}

                          <CloseButton
                            className={`${lang === "en" ? "ms-2" : "me-2"}`}
                            onClick={(event) => {
                              handleBadgeRemoval(
                                values,
                                setFieldValue,
                                "month",
                                event.target.parentElement.id
                              );
                              setPage(1);
                            }}
                          />
                        </Badge>
                      ))}

                    {/* Year Badge */}
                    {values?.year?.length > 0 &&
                      values?.year.map((currentYear, index) => (
                        <Badge key={index} id={currentYear}>
                          {years.find((year) => +currentYear === +year)}

                          <CloseButton
                            className={`${lang === "en" ? "ms-2" : "me-2"}`}
                            onClick={(event) => {
                              handleBadgeRemoval(
                                values,
                                setFieldValue,
                                "year",
                                event.target.parentElement.id
                              );
                              setPage(1);
                            }}
                          />
                        </Badge>
                      ))}
                  </Row>

                  {/* Search Form */}
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      handleSubmit();
                    }}
                    className="p-4 overflow-hidden"
                    style={{
                      backgroundColor: "#f9fafd",
                      border: "1px solid #e5f1fb",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <Stack>
                      {/* Title Filter */}
                      <FormGroup as={Col}>
                        <FormLabel
                          htmlFor="course_title"
                          className="text-capitalize"
                        >
                          <IconTextComponent
                            text={t("words:labels.courseKeywords")}
                            icon={<FaRegKeyboard size={20} />}
                          />
                        </FormLabel>
                        <FormControl
                          id="course_title"
                          type="text"
                          autoComplete="off"
                          name="title"
                          onChange={handleChange}
                          onBlur={(event) => {
                            handleBlur(event);

                            addNewValue(
                              setFieldValue,
                              values,
                              "title",
                              event.target.value
                            );
                          }}
                          value={values.title}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              addNewValue(
                                setFieldValue,
                                values,
                                "title",
                                event.target.value
                              );
                              setPage(1);
                            }
                          }}
                          className={`text-capitalize ${
                            touched.title && errors.title ? "is-invalid" : ""
                          }`}
                        />

                        <FormText className="text-muted">
                          {t("words:hints.title.enterKey")}
                        </FormText>
                      </FormGroup>

                      {/* Sections Filter */}
                      <Row xs={1} className="search-filter-container g-2">
                        <Col className="search-filter-head">
                          <IconTextComponent
                            text={t("words:labels.section")}
                            icon={<FiLayers size={20} />}
                          />
                          {values.section_id.length > 0 && (
                            <>
                              <Badge className="accordion-badge">
                                {values.section_id.length}
                              </Badge>
                              <span className="visually-hidden text-capitalize">
                                {t("words:selectedFilters")}
                              </span>
                            </>
                          )}
                        </Col>

                        <Col>
                          {sections.map((section) => (
                            <Form.Check
                              key={section.title}
                              // inline
                              reverse={lang === "en" ? false : true}
                              type="checkbox"
                              id={section.title}
                              name="section_id"
                              value={section.id}
                              checked={
                                values.section_id.includes(`${section.id}`) ||
                                values.section_id.includes(section.id)
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? addNewValue(
                                      setFieldValue,
                                      values,
                                      "section_id",
                                      event.target.value
                                    )
                                  : removeExistingValue(
                                      setFieldValue,
                                      values,
                                      "section_id",
                                      event.target.value
                                    );
                                setPage(1);
                              }}
                              label={section.title}
                              className="filter-option"
                            />
                          ))}
                        </Col>
                      </Row>

                      {/* Categories Filter */}
                      <Row xs={1} className="search-filter-container g-2">
                        <Col className="search-filter-head">
                          <IconTextComponent
                            text={t("words:labels.category")}
                            icon={<FiFilter size={20} />}
                          />
                          {values.category_id.length > 0 && (
                            <>
                              <Badge className="accordion-badge">
                                {values.category_id.length}
                              </Badge>
                              <span className="visually-hidden text-capitalize">
                                {t("words:selectedFilters")}
                              </span>
                            </>
                          )}
                        </Col>

                        <Col>
                          {categories.map((category) => (
                            <Form.Check
                              key={category.title}
                              // inline
                              reverse={lang === "en" ? false : true}
                              type="checkbox"
                              id={category.title}
                              name="category_id"
                              value={category.id}
                              checked={
                                values.category_id.includes(`${category.id}`) ||
                                values.category_id.includes(category.id)
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? addNewValue(
                                      setFieldValue,
                                      values,
                                      "category_id",
                                      event.target.value
                                    )
                                  : removeExistingValue(
                                      setFieldValue,
                                      values,
                                      "category_id",
                                      event.target.value
                                    );
                                setPage(1);
                              }}
                              label={category.title}
                              className="filter-option"
                            />
                          ))}
                        </Col>
                      </Row>

                      {/* Cities Filter */}
                      <Row xs={1} className="search-filter-container g-2">
                        <Col className="search-filter-head">
                          <IconTextComponent
                            text={t("words:labels.city")}
                            icon={<MdOutlineLocationOn size={20} />}
                          />
                          {values.city_id.length > 0 && (
                            <>
                              <Badge className="accordion-badge">
                                {values.city_id.length}
                              </Badge>
                              <span className="visually-hidden text-capitalize">
                                {t("words:selectedFilters")}
                              </span>
                            </>
                          )}
                        </Col>

                        <Col>
                          {cities.map((city) => (
                            <Form.Check
                              key={city.title}
                              // inline
                              reverse={lang === "en" ? false : true}
                              type="checkbox"
                              id={city.title}
                              name="city_id"
                              value={city.id}
                              checked={
                                values.city_id.includes(`${city.id}`) ||
                                values.city_id.includes(city.id)
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? addNewValue(
                                      setFieldValue,
                                      values,
                                      "city_id",
                                      event.target.value
                                    )
                                  : removeExistingValue(
                                      setFieldValue,
                                      values,
                                      "city_id",
                                      event.target.value
                                    );
                                setPage(1);
                              }}
                              label={city.title}
                              className="filter-option"
                            />
                          ))}
                        </Col>
                      </Row>

                      {/* Months Filter */}
                      <Row xs={1} className="search-filter-container g-2">
                        <Col className="search-filter-head">
                          <IconTextComponent
                            text={t("words:labels.month")}
                            icon={<BsCalendar2Month size={20} />}
                          />
                          {values.month.length > 0 && (
                            <>
                              <Badge className="accordion-badge">
                                {values.month.length}
                              </Badge>
                              <span className="visually-hidden text-capitalize">
                                {t("words:selectedFilters")}
                              </span>
                            </>
                          )}
                        </Col>

                        <Col>
                          {months.map((month, index) => (
                            <Form.Check
                              key={month}
                              // inline
                              reverse={lang === "en" ? false : true}
                              type="checkbox"
                              id={month}
                              name="month"
                              value={index + 1}
                              checked={
                                values.month.includes(`${index + 1}`) ||
                                values.month.includes(index + 1)
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? addNewValue(
                                      setFieldValue,
                                      values,
                                      "month",
                                      event.target.value
                                    )
                                  : removeExistingValue(
                                      setFieldValue,
                                      values,
                                      "month",
                                      event.target.value
                                    );
                                setPage(1);
                              }}
                              label={month}
                              className="filter-option"
                            />
                          ))}
                        </Col>
                      </Row>

                      {/* Years Filter */}
                      <Row xs={1} className="search-filter-container g-2">
                        <Col className="search-filter-head">
                          <IconTextComponent
                            text={t("words:labels.year")}
                            icon={<FiCalendar size={20} />}
                          />
                          {values.year.length > 0 && (
                            <>
                              <Badge className="accordion-badge">
                                {values.year.length}
                              </Badge>
                              <span className="visually-hidden text-capitalize">
                                {t("words:selectedFilters")}
                              </span>
                            </>
                          )}
                        </Col>

                        <Col>
                          {years.map((year) => (
                            <Form.Check
                              key={year}
                              // inline
                              reverse={lang === "en" ? false : true}
                              type="checkbox"
                              id={year}
                              name="year"
                              value={year}
                              checked={
                                values.year.includes(`${year}`) ||
                                values.year.includes(year)
                              }
                              onChange={(event) => {
                                event.target.checked
                                  ? addNewValue(
                                      setFieldValue,
                                      values,
                                      "year",
                                      event.target.value
                                    )
                                  : removeExistingValue(
                                      setFieldValue,
                                      values,
                                      "year",
                                      event.target.value
                                    );
                                setPage(1);
                              }}
                              //   label={moment(new Date(year, 0, 1)).format(
                              //     "YYYY"
                              //   )}
                              className="filter-option"
                            />
                          ))}
                        </Col>
                      </Row>
                    </Stack>

                    {/* Submit Form */}
                    <Zoom delay={100}>
                      <FormGroup className="d-flex justify-content-center mt-3">
                        <ButtonComponent
                          title={t("words:buttons.applyFilters")}
                          styles={{
                            button: {
                              backgroundColor: "#b94a4b",
                              padding: "0.5rem 1rem",
                            },
                          }}
                          type="submit"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        />
                      </FormGroup>
                    </Zoom>
                  </Form>
                </>
              )}
            </Formik>
          </Col>

          {/* Search Results */}
          <Col lg={9}>
            {isLoading || isError ? (
              // Loading Indicator
              <LoadingPageComponent />
            ) : CoursesData?.data?.length > 0 ? (
              // Display Courses Data
              <>
                <Row
                  xs={1}
                  md={2}
                  xxl={3}
                  id="courses-filter-result"
                  className="g-4 overflow-hidden"
                >
                  {CoursesData?.data?.map((course, index) => (
                    <Fade key={index} delay={50} direction="up">
                      <Col>
                        <CourseCardComponent course={course} />
                      </Col>
                    </Fade>
                  ))}
                </Row>

                {/* Pagination */}
                {CoursesData?.meta?.last_page > 1 && (
                  <Row className="paginationRow">
                    <Col md={12} className="paginationCol d-flex justify-content-center align-items-center">
                      <Pagination>
                        <Pagination.First
                          onClick={() => {
                            setPage(1);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          disabled={page === 1}
                        />

                        <Pagination.Prev
                          onClick={() => {
                            setPage((prev) => prev - 1);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          disabled={page === 1}
                        />

                        {CoursesData?.meta?.links
                          ?.slice(1, -1)
                          .map((link, index) => (
                            <Pagination.Item
                              key={index}
                              active={
                                +link?.label ===
                                +CoursesData?.meta?.current_page
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                setPage(+link?.label);
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              {link.label}
                            </Pagination.Item>
                          ))}
                        <Pagination.Next
                          onClick={() => {
                            setPage((prev) => prev + 1);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          disabled={page === CoursesData?.meta?.last_page}
                        />

                        <Pagination.Last
                          onClick={() => {
                            setPage(CoursesData?.meta?.last_page);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          disabled={page === CoursesData?.meta?.last_page}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              // No Results Found
              <NoResultsComponent />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SearchResultPage;
