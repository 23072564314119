import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CourseCardComponent from "../CourseCardComponent/CourseCardComponent";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Col, Container, Row } from "react-bootstrap";

const CoursesSliderComponent = ({
  courses,
  autoplayDelay = 2000,
  hasNavigation = true,
  hasPagination = true,
  breakpoints = {
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    991: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 4,
      spaceBetween: 25,
    },
  },
}) => {
  // i18next
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang ?? "ar");
    // eslint-disable-next-line
  }, [lang]);

  return (
    <Swiper
      dir={lang === "en" ? "ltr" : "rtl"}
      key={lang}
      slidesPerView={1}
      spaceBetween={20}
      breakpoints={breakpoints}
      autoplay={{
        delay: autoplayDelay,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      grabCursor={true}
      loop={true}
      navigation={{
        enabled: hasNavigation,
      }}
      pagination={{
        enabled: hasPagination,
        dynamicBullets: true,
        clickable: true,
      }}
      modules={[Autoplay, Navigation, Pagination]}
    >
      <Container>
        <Row xs={1} md={4}>
          {courses?.length <= 4
            ? courses?.map((course, index) => (
                <Col
                  style={{
                    paddingRight: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <CourseCardComponent course={course} />
                </Col>
              ))
            : courses?.map((course, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    paddingRight: "0.5rem",
                    paddingLeft: "0.5rem",
                  }}
                >
                  <CourseCardComponent course={course} />
                </SwiperSlide>
              ))}
        </Row>
      </Container>
      {/* <Container>
        <Row xs={1} md={3}>
          {courses?.map((course, index) => (
            <SwiperSlide
              key={index}
              style={{
                paddingRight: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              <h2>{index}</h2>
              <CourseCardComponent course={course} />
            </SwiperSlide>
          ))}
        </Row>
      </Container> */}
    </Swiper>
  );
};

export default CoursesSliderComponent;
