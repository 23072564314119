import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './MailboxSubscriptionFormComponent.styles.css';

// Components
import ButtonComponent from './../../ButtonComponent/ButtonComponent';

const MailboxSubscriptionFormComponent = ({
	mailboxModalShow,
	setMailboxModalShow,
}) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const mailboxFirstNameRef = useRef();
	const mailboxLastNameRef = useRef();
	const mailboxEmailRef = useRef();

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				mailboxFirstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				mailboxLastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				mailboxEmailRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);

				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const mailboxSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
	});

	const submitMailboxRegistrationForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/news-letter',
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);
				setMailboxModalShow(false);

				return mailboxModalShow
					? displayToast(response.data.status, response.data.msg)
					: null;
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	return (
		<Container fluid className='mailbox-subscription-form-component px-0'>
			<Formik
				initialValues={{
					fname: '',
					lname: '',
					email: '',
				}}
				validationSchema={mailboxSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitMailboxRegistrationForm(values, setSubmitting, resetForm, lang);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<Form
						className='position-relative'
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit();
						}}
					>
						<Row xs={1} sm={2} className='overflow-hidden'>
							{/* First Name */}
							<Fade direction={lang === 'en' ? 'left' : 'right'} delay={100}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='first_name' className='text-capitalize'>
										{t('words:labels.firstName')}
									</FormLabel>
									<Field
										id='first_name'
										type='text'
										innerRef={mailboxFirstNameRef}
										placeholder={t('words:placeholders.firstName')}
										autoComplete='off'
										name='fname'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.fname}
										className={`form-control text-capitalize ${
											touched.fname && errors.fname ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='fname'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>

							{/* Last Name */}
							<Fade direction={lang === 'en' ? 'right' : 'left'} delay={100}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='last_name' className='text-capitalize'>
										{t('words:labels.lastName')}
									</FormLabel>
									<Field
										id='last_name'
										type='text'
										innerRef={mailboxLastNameRef}
										placeholder={t('words:placeholders.lastName')}
										autoComplete='off'
										name='lname'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.lname}
										className={`form-control text-capitalize ${
											touched.lname && errors.lname ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='lname'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>
						</Row>

						<Row xs={1} className='overflow-hidden'>
							{/* Email */}
							<Fade direction='down' delay={200}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='email' className='text-capitalize'>
										{t('words:labels.email')}
									</FormLabel>
									<Field
										id='email'
										type='email'
										innerRef={mailboxEmailRef}
										placeholder='mail@domain.com'
										autoComplete='off'
										name='email'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.email}
										className={`form-control ${
											touched.email && errors.email ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='email'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>
						</Row>

						{/* Submit Form */}
						<Zoom delay={300}>
							<FormGroup className='d-flex justify-content-center my-3 h-100'>
								<ButtonComponent
									title={
										isSubmitting
											? t('words:buttons.loading')
											: t('words:buttons.subscribeToMailbox')
									}
									icon={
										isSubmitting ? (
											<Spinner
												animation='border'
												variant='light'
												size='sm'
												className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
											/>
										) : (
											<></>
										)
									}
									type='submit'
									styles={{
										button: {
											padding: '0.5rem 1rem',
										},
										title: {
											fontWeight: '200',
											fontSize: '16px',
										},
									}}
									disabled={isSubmitting ? true : false}
								/>
							</FormGroup>
						</Zoom>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default MailboxSubscriptionFormComponent;
