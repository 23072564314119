import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutData } from './../../store/reducers/aboutData.reducer';
import { fetchCategories } from './../../store/reducers/categories.reducer';
import { fetchCities } from './../../store/reducers/cities.reducer';
// import { fetchCourses } from './../../store/reducers/courses.reducer';
import { fetchHomeData } from './../../store/reducers/homeData.reducer';
import { fetchMonths } from './../../store/reducers/months.reducer';
import { fetchPlans } from './../../store/reducers/plans.reducer';
import { fetchSections } from './../../store/reducers/sections.reducer';
import { fetchSettingsData } from './../../store/reducers/settingsData.reducer';
import { fetchYears } from './../../store/reducers/years.reducer';

// Components
import FooterComponent from './../../components/FooterComponent/FooterComponent';
import MainPreloaderComponent from './../../components/MainPreloaderComponent/MainPreloaderComponent';
import NavbarComponent from './../../components/NavbarComponent/NavbarComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const MainTemplatePage = ({ children }) => {
	const { lang } = useParams();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchSettingsData(lang ?? 'ar'));
		dispatch(fetchHomeData(lang ?? 'ar'));
		dispatch(fetchAboutData(lang ?? 'ar'));
		dispatch(fetchSections({ language: lang ?? 'ar', searchParams: {} }));
		dispatch(fetchCategories({ language: lang ?? 'ar', searchParams: {} }));
		// dispatch(fetchCourses({ language: lang ?? 'ar', searchParams: {} }));
		dispatch(fetchPlans(lang ?? 'ar'));
		dispatch(fetchCities(lang ?? 'ar'));
		dispatch(fetchYears(lang ?? 'ar'));
		dispatch(fetchMonths(lang ?? 'ar'));
		// eslint-disable-next-line
	}, [lang]);

	const { isSettingsLoading } = useSelector((state) => state.settingsData);
	const { isHomeDataLoading } = useSelector((state) => state.homeData);
	const { isSectionsLoading } = useSelector((state) => state.sections);
	const { isCategoriesLoading } = useSelector((state) => state.categories);
	// const { isCoursesLoading } = useSelector((state) => state.courses);
	const { isAboutDataLoading } = useSelector((state) => state.aboutData);
	const { isPlansLoading } = useSelector((state) => state.plans);
	const { isCitiesLoading } = useSelector((state) => state.cities);
	const { isMonthsLoading } = useSelector((state) => state.months);

	return isSettingsLoading ||
		isHomeDataLoading ||
		isAboutDataLoading ||
		isSectionsLoading ||
		isCategoriesLoading ||
		// isCoursesLoading ||
		isPlansLoading ||
		isCitiesLoading ||
		isMonthsLoading ? (
		<MainPreloaderComponent />
	) : (
		<>
			{/* Meta Tags */}
			<MetaTagsComponent />

			{/* Page Navbar */}
			<NavbarComponent />

			{/* Current Page Content */}
			{children}

			{/* Page Footer */}
			<FooterComponent />

			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={6000}
				newestOnTop
				pauseOnHover
				rtl={lang === 'en' ? false : true}
			/>
		</>
	);
};

export default MainTemplatePage;
