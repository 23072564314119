import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Components
import ButtonComponent from './../../ButtonComponent/ButtonComponent';

const ContactFormComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const messageRef = useRef();

	const contactSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		message: string()
			.min(2, t('validations:message.min', { min: 2 }))
			.max(255, t('validations:message.max', { max: 255 }))
			.required(t('validations:message.required')),
	});

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'message':
				messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const submitContactForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/contact',
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				message: values.message,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.msg);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	return (
		<Container fluid className='contact-form-component px-0'>
			<Formik
				initialValues={{
					fname: '',
					lname: '',
					email: '',
					phone: '',
					message: '',
				}}
				validationSchema={contactSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitContactForm(values, setSubmitting, resetForm, lang);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<Form
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit();
						}}
						className='p-4 overflow-hidden'
						style={{
							backgroundColor: '#f9fafd',
							border: '1px solid #e5f1fb',
							borderRadius: '0.375rem',
						}}
					>
						<Row xs={1} sm={2}>
							{/* First Name */}
							<Fade direction={lang === 'en' ? 'left' : 'right'} delay={100}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='first_name' className='text-capitalize'>
										{t('words:labels.firstName')}
									</FormLabel>
									<Field
										id='first_name'
										type='text'
										innerRef={firstNameRef}
										autoComplete='off'
										name='fname'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.fname}
										className={`form-control text-capitalize ${
											touched.fname && errors.fname ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='fname'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>

							{/* Last Name */}
							<Fade direction={lang === 'en' ? 'right' : 'left'} delay={100}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='last_name' className='text-capitalize'>
										{t('words:labels.lastName')}
									</FormLabel>
									<Field
										id='last_name'
										type='text'
										innerRef={lastNameRef}
										autoComplete='off'
										name='lname'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.lname}
										className={`form-control text-capitalize ${
											touched.lname && errors.lname ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='lname'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>

							{/* Email */}
							<Fade direction={lang === 'en' ? 'left' : 'right'} delay={200}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='email' className='text-capitalize'>
										{t('words:labels.email')}
									</FormLabel>
									<Field
										id='email'
										type='email'
										innerRef={emailRef}
										placeholder='mail@domain.com'
										autoComplete='off'
										name='email'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.email}
										className={`form-control ${
											touched.email && errors.email ? 'is-invalid' : ''
										}`}
									/>
									<ErrorMessage
										component='div'
										name='email'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>

							{/* Mobile Number */}
							<Fade direction={lang === 'en' ? 'right' : 'left'} delay={200}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='phone' className='text-capitalize'>
										{t('words:labels.phone')}
									</FormLabel>
									<Field name='phone' innerRef={phoneRef}>
										{(field, form, meta) => (
											<>
												<PhoneInput
													{...field}
													id='phone'
													dir='ltr'
													ref={phoneRef}
													placeholder='56 123 0620'
													defaultCountry='SA'
													autoComplete='off'
													onChange={(event) => {
														setFieldValue('phone', event);
													}}
													onBlur={handleBlur}
													value={values.phone}
													className={`${
														field.meta.touched && field.meta.error
															? 'is-invalid'
															: ''
													}`}
												/>
												{field.meta.error && (
													<div className='invalid-feedback'>
														{field.meta.error}
													</div>
												)}
											</>
										)}
									</Field>
								</FormGroup>
							</Fade>
						</Row>

						<Row xs={1}>
							{/* Message */}
							<Fade direction={lang === 'en' ? 'left' : 'right'} delay={300}>
								<FormGroup as={Col} className='mb-3 h-100'>
									<FormLabel htmlFor='message' className='text-capitalize'>
										{t('words:labels.message')}
									</FormLabel>
									<Field
										id='message'
										as='textarea'
										innerRef={messageRef}
										rows={8}
										style={{
											resize: 'none',
										}}
										placeholder={t('words:placeholders.message')}
										autoComplete='off'
										name='message'
										onChange={(event) => {
											handleChange(event);
										}}
										onBlur={handleBlur}
										value={values.message}
										className={`form-control text-capitalize ${
											touched.message && errors.message ? 'is-invalid' : ''
										}`}
									/>
									<div className='text-muted'>
										<span className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}>
											{new Intl.NumberFormat(
												lang === 'en' ? 'en-US' : 'ar-EG',
												{}
											).format(values.message.length)}
										</span>
										<span>
											{t('words:hints.message.length', {
												max: new Intl.NumberFormat(
													lang === 'en' ? 'en-US' : 'ar-EG',
													{}
												).format(255),
											})}
										</span>
									</div>
									<ErrorMessage
										component='div'
										name='message'
										className='invalid-feedback'
									/>
								</FormGroup>
							</Fade>
						</Row>

						{/* Submit Form */}
						<Zoom delay={400}>
							<FormGroup
								className='h-100 d-flex justify-content-center mt-3'
								style={{
									'--animate-delay': '1.25s',
								}}
							>
								<ButtonComponent
									title={
										isSubmitting
											? t('words:buttons.loading')
											: t('words:buttons.submitMessage')
									}
									icon={
										isSubmitting ? (
											<Spinner
												animation='border'
												variant='light'
												size='sm'
												className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
											/>
										) : (
											<></>
										)
									}
									type='submit'
									disabled={isSubmitting ? true : false}
								/>
							</FormGroup>
						</Zoom>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default ContactFormComponent;
