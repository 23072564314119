import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// Icons
import { MdCalendarToday, MdLocationOn } from 'react-icons/md';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CourseCardComponent.styles.css';

// Components
import IconTextComponent from './../IconTextComponent/IconTextComponent';

const CourseCardComponent = ({ course }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const [badgeColor, setBadgeColor] = useState('black');

	useEffect(() => {
		if (course && course.level) {
			switch (course.level.toLowerCase()) {
				case 'beginner':
				case 'مبتدئ':
					setBadgeColor('rgb(0, 113, 220)');
					break;

				case 'expert':
				case 'خبير':
					setBadgeColor('#eb5757');
					break;

				case 'all levels':
				case 'جميع المستويات':
					setBadgeColor('#27ae60');
					break;
				default:
					break;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	return (
		<>
			<Card
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				lang={lang ?? 'ar'}
				className='course-card overflow-hidden text-dark'
				style={{
					border: '1px solid #f2f2f2',
				}}
			>
				{/* Level Badge */}
				{course.category_id !== null && course.level && (
					<span
						className='position-absolute course-badge text-capitalize'
						style={{
							backgroundColor: `${badgeColor}`,
							top: '15px',
							left: lang === 'en' ? '20px' : 'unset',
							right: lang === 'en' ? 'unset' : '20px',
						}}
					>
						{course.level}
					</span>
				)}

				{/* Is New Badge */}
				{course.is_new && (
					<span
						className='position-absolute course-badge text-bg-warning text-capitalize fw-light shadow-lg animate__animated animate__heartBeat animate__infinite animate__slow animate__delay-2s'
						style={{
							'--animate-delay': '0.75s',
							top: '15px',
							left: lang === 'en' ? 'unset' : '20px',
							right: lang === 'en' ? '20px' : 'unset',
						}}
					>
						{t('words:new')}
					</span>
				)}
				<div className='overflow-hidden'>
					<Link
						to={`/${lang}/section/${course.section_id}/course/${course.id}`}
						aria-label={course.title}
						style={{
							textDecoration: 'none',
						}}
					>
						<Card.Img
							variant='top'
							fluid='true'
							src={
								course.image
							}
							alt='course thumbnail'
							className='card-thumbnail bg-white img-fluid text-capitalize w-100'
							style={{
								objectFit: 'cover',
								objectPosition: 'center',
							}}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = require('./../../assets/images/logos/logo.png');
							}}
						/>
					</Link>
				</div>
				<Card.Body
					style={{
						padding: '15px 20px',
					}}
					className='d-flex flex-column justify-content-between'
				>
					<Link
						to={`/${lang}/section/${course.section_id}/course/${course.id}`}
						aria-label={course.title}
						style={{
							textDecoration: 'none',
						}}
					>
						<Card.Title
							className='card-text text-capitalize text-limit m-0 p-0'
							style={{
								fontSize: '16px',
								'--lines': 2,
							}}
						>
							{course.title}
						</Card.Title>
					</Link>

					<Card.Text
						as='div'
						className='d-flex justify-content-between align-items-center pt-2'
					>
						{/* Start Date */}
						<IconTextComponent
							icon={<MdCalendarToday size={20} />}
							text={moment(course.start_date).format(t('words:dateFormatter'))}
							className='text-limit text-muted p-0 my-0 fw-bold text-capitalize'
							textStyles={{
								fontSize: '14px',
								fontWeight: '600',
								'--lines': 1,
							}}
						/>

						{/* City */}
						<IconTextComponent
							icon={<MdLocationOn size={20} />}
							text={course.city}
							className='text-limit text-muted p-0 my-0 fw-bold text-capitalize'
							textStyles={{
								fontSize: '14px',
								fontWeight: '600',
								'--lines': 1,
							}}
						/>
					</Card.Text>
				</Card.Body>
				{(course.category || course.price) && (
					<Card.Footer
						className='position-relative'
						style={{
							backgroundColor: 'transparent',
							borderTop: '1px solid #f2f2f2',
							borderRadius: '0 0 8px 8px',
						}}
					>
						<Card.Text
							as='div'
							className='d-flex justify-content-between align-items-center'
						>
							{/* Category */}
							{course.category ? <Link
								to={`/${lang}/section/${course.section_id}/category/${course.category_id}`}
								aria-label={course.category_id}
								className='d-block'
								style={{
									width: course.price ? '70%' : '100%',
									textDecoration: 'none',
								}}
							>
								<span
									className='card-text text-limit text-muted p-0 my-0 fw-bold text-capitalize'
									style={{
										fontSize: '14px',
										fontWeight: '500',
										'--lines': 1,
									}}
								>
									{course.category}
								</span>
							</Link> : <div style={{ width: course.price ? '70%' : '100%', }}></div>}

							{/* Cost */}
							{course.price && (
								<span
									style={{
										color: 'rgb(0, 113, 220)',
										fontSize: '18px',
										fontWeight: '600',
									}}
								>{`$${course.price.toLocaleString('en')}`}</span>
							)}
						</Card.Text>
					</Card.Footer>
				)}
			</Card>
		</>
	);
};

export default CourseCardComponent;
