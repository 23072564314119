import React, { useEffect, useState } from 'react';
import {
	Col,
	Container,
	Form,
	Image,
	InputGroup,
	ListGroup,
	Modal,
	Row,
} from 'react-bootstrap';
import {
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import { checkURL } from './../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector } from 'react-redux';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import './FooterComponent.styles.css';

// Components
import IconTextComponent from './../IconTextComponent/IconTextComponent';
import MailboxSubscriptionFormComponent from './../_Forms/MailboxSubscriptionFormComponent/MailboxSubscriptionFormComponent';

const FooterComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const { sections } = useSelector((state) => state.sections);
	const { settings } = useSelector((state) => state.settingsData);

	const [mailboxModalShow, setMailboxModalShow] = useState(false);

	return (
		<>
			<Container
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				lang={lang ?? 'ar'}
				as='footer'
				id='footer'
				fluid='xs'
				className='py-4'
				style={{
                    '--bg-image': `url(${settings?.footer_image})`,
					paddingRight: '1.2rem',
					paddingLeft: '1.2rem',
				}}
			>
				<Container
					fluid='md'
					className='position-relative text-white'
					style={{
						zIndex: '10',
					}}
				>
					<Row xs={1} className='justify-content-center g-4'>
						<Col>
							<Row className='g-4 py-4'>
								{/* Company Address */}
								<Col
									xs={12}
									sm={6}
									md={9}
									lg={6}
									xl={6}
									xxl={4}
									className='overflow-hidden'
								>
									<Row xs={1} className='g-3'>
										<Col
											className='display-6 fw-bold text-capitalize'
											style={{
												fontSize: '22px',
											}}
										>
											{settings.website_title}
										</Col>
										<Col>{settings.address}</Col>
									</Row>
								</Col>

								{/* Sections */}
								<Col
									xs={12}
									sm={6}
									md={3}
									lg={2}
									xl={3}
									xxl={2}
									className='overflow-hidden'
								>
									<Row xs={1} className='g-3'>
										<Col
											className='footer-title fw-bold text-capitalize'
											style={{
												fontSize: '18px',
											}}
										>
											{t('words:footer.headers.sections')}
										</Col>
										<Col>
											<ul>
												{sections.map((section) => (
													<li key={section.title}>
														<Link
															to={`/${lang}/section/${section.id}`}
															className='text-capitalize text-white'
															style={{
																textDecoration: 'none',
															}}
															onClick={() =>
																window.scrollTo({
																	top: 0,
																	left: 0,
																	behavior: 'smooth',
																})
															}
														>
															{section.title}
														</Link>
													</li>
												))}
											</ul>
										</Col>
									</Row>
								</Col>

								{/* Links */}
								<Col
									xs={{ span: 12, order: 'last' }}
									sm={6}
									md={3}
									lg={4}
									xl={3}
									xxl={3}
									className='overflow-hidden'
								>
									<Row xs={1} className='g-3'>
										<Col
											className='footer-title fw-bold text-capitalize'
											style={{
												fontSize: '18px',
											}}
										>
											{t('words:footer.headers.links')}
										</Col>
										<Col>
											<ul>
												{/* Training Plans */}
												<li>
													<Link
														to={`/${lang}/training-plan`}
														className='text-capitalize'
														style={{
															textDecoration: 'none',
														}}
													>
														{t('words:footer.trainingPlans')}
													</Link>
												</li>

												{/* Request Course - Contract */}
												<li>
													<Link
														to={`/${lang}/course-request/contract`}
														className='text-capitalize text-limit'
														style={{
															'--lines': 1,
															textDecoration: 'none',
														}}
													>
														{t('words:footer.requestContractCourse')}
													</Link>
												</li>

												{/* Request Course - Individual */}
												<li>
													<Link
														to={`/${lang}/course-request/individual`}
														className='text-capitalize text-limit'
														style={{
															'--lines': 1,
															textDecoration: 'none',
														}}
													>
														{t('words:footer.requestIndividualCourse')}
													</Link>
												</li>

												{/* About Us */}
												<li>
													<Link
														to={`/${lang}/about-us`}
														className='text-capitalize'
														style={{
															textDecoration: 'none',
														}}
													>
														{t('words:footer.aboutUs')}
													</Link>
												</li>

												{/* Contact Us */}
												<li>
													<Link
														to={`/${lang}/contact-us`}
														className='text-capitalize'
														style={{
															textDecoration: 'none',
														}}
													>
														{t('words:footer.contactUs')}
													</Link>
												</li>
											</ul>
										</Col>
									</Row>
								</Col>

								{/* Contacts */}
								<Col
									xs={12}
									sm={6}
									md={9}
									lg={{ span: 12, order: 'last' }}
									xl={12}
									xxl={3}
									className='overflow-hidden'
								>
									<Row xs={1} className='g-3'>
										<Col
											className='footer-title fw-bold text-capitalize'
											style={{
												fontSize: '18px',
											}}
										>
											{t('words:footer.headers.contactUs')}
										</Col>
										<Col>
											<Row xs={1} md={2} lg={2} xxl={1} className='g-1'>
												{/* Phone Numbers */}
												<Col>
													<ul className='py-0'>
														{settings.phones &&
															settings.phones
																.filter((phone, _) => phone.value !== null)
																.map((phone, index) => (
																	<IconTextComponent
																		key={index}
																		icon={<FiPhoneCall size={24} />}
																		label={phone.label}
																		text={
																			<li className='phone-container'>
																				<a href={`tel:${phone.value}`}>
																					<span
																						className='phone-number'
																						dir='ltr'
																					>
																						{phone.value}
																					</span>
																				</a>
																			</li>
																		}
																		isCentered={false}
																	/>
																))}
													</ul>
												</Col>

												{/* Emails */}
												<Col
													xs={{ order: 'first' }}
													md={{ order: 'last' }}
													lg={{ order: 'first' }}
													xxl={{ order: 'first' }}
												>
													{settings.emails &&
														settings.emails
															.filter((email, _) => email !== null)
															.map((email, index) => (
																<a key={index} href={`mailto:${email}`}>
																	<IconTextComponent
																		icon={<HiOutlineMail size={24} />}
																		text={email}
																		isCapitalized={false}
																	/>
																</a>
															))}
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						{/* Subscribe to mailbox */}
						<Col xs={12} sm={8} md={6}>
							<InputGroup
								className='text-capitalize'
								style={{
									cursor: 'pointer',
								}}
								onClick={() => setMailboxModalShow(true)}
							>
								<Form.Control
									placeholder={t('words:placeholders.mailbox')}
									aria-label={t('words:placeholders.mailbox')}
									aria-describedby='basic-addon2'
								/>
								<InputGroup.Text
									style={{
										color: '#ffffff',
										backgroundColor: '#b94a4b',
									}}
									id='basic-addon2'
								>
									{t('words:buttons.subscribe')}
								</InputGroup.Text>
							</InputGroup>
							<Modal
								dir={lang === 'en' ? 'ltr' : 'rtl'}
								lang={lang ?? 'ar'}
								show={mailboxModalShow}
								onHide={() => setMailboxModalShow(false)}
								size='lg'
								aria-labelledby='contained-modal-title-vcenter'
								centered
							>
								<Modal.Body>
									<MailboxSubscriptionFormComponent
										mailboxModalShow={mailboxModalShow}
										setMailboxModalShow={setMailboxModalShow}
									/>
								</Modal.Body>
							</Modal>
						</Col>

						<Col
							style={{
								paddingTop: '1rem',
							}}
						>
							<Row xs={1} md={2} lg={3} className='g-2'>
								{/* Company Logo */}
								<Col
									lg={3}
									className={`d-flex ${
										window.innerWidth < 768 ? 'justify-content-center' : ''
									}`}
								>
									<Link
										to={`/${lang}`}
										style={{
											textDecoration: 'none',
										}}
									>
										<Image
											fluid
											src={settings.white_logo}
											alt='company logo'
											className='text-capitalize w-100 h-100'
											style={{
												maxHeight: window.innerWidth < 768 ? '6rem' : '4rem',
												objectFit: 'contain',
												objectPosition: 'center'
											}}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null; // prevents looping
												currentTarget.src = require('./../../assets/images/logos/logo.png');
											}}
										/>
									</Link>
								</Col>

								<Col lg={9}>
									<Row xs={1} lg={2} className='g-2 h-100'>
										{/* Copyrights */}
										<Col
											className={`display-6 d-flex align-items-center ${
												window.innerWidth < 768
													? 'justify-content-center'
													: 'justify-content-start'
											}`}
											style={{
												fontSize: '13px',
											}}
										>
											{settings.copyrights}
										</Col>

										{/* Social Media Accounts */}
										<Col
											as={ListGroup}
											horizontal
											className={`social-accounts d-flex align-items-center ${
												window.innerWidth < 768
													? 'justify-content-center'
													: 'justify-content-end'
											} px-0`}
										>
											{/* Facebook Account */}
											{settings.facebook && (
												<ListGroup.Item
													as='a'
													href={checkURL(settings.facebook)}
													target='_blank'
													aria-label='Facebook'
												>
													<FaFacebookF size={20} />
												</ListGroup.Item>
											)}

											{/* Twitter Account */}
											{settings.twitter && (
												<ListGroup.Item
													as='a'
													href={checkURL(settings.twitter)}
													target='_blank'
													aria-label='Twitter'
												>
													<FaTwitter size={20} />
												</ListGroup.Item>
											)}

											{/* LinkedIn Account */}
											{settings.linkedin && (
												<ListGroup.Item
													as='a'
													href={checkURL(settings.linkedin)}
													target='_blank'
													aria-label='LinkedIn'
												>
													<FaLinkedinIn size={20} />
												</ListGroup.Item>
											)}

											{/* Instagram Account */}
											{settings.instagram && (
												<ListGroup.Item
													as='a'
													href={checkURL(settings.instagram)}
													target='_blank'
													aria-label='Instagram'
												>
													<FaInstagram size={20} />
												</ListGroup.Item>
											)}

											{/* Youtube Account */}
											{settings.youtube && (
												<ListGroup.Item
													as='a'
													href={checkURL(settings.youtube)}
													target='_blank'
													aria-label='YouTube'
												>
													<FaYoutube size={20} />
												</ListGroup.Item>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Container>

			{/* Company Signature */}
			<Container fluid className='company-signature'>
				<Row>
					<Col className='p-2 m-0 bg-dark text-capitalize text-white text-center align-middle'>
						<p
							className='m-0 text-capitalize'
							style={{
								fontSize: '13px',
							}}
						>
							{t('words:copyrights.developed')}

							<a
								href='https://marwan.tech/ar/service-request'
								target='_blank'
								rel='noreferrer'
								className='text-capitalize'
							>
								{t('words:copyrights.company')}
							</a>
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default FooterComponent;
