import React from 'react';
import { useParams } from 'react-router-dom';

// Styles
import './IconTextComponent.styles.css';

const IconTextComponent = ({
	icon,
	text,
	label = null,
	dropShadow = false,
	isCapitalized = true,
	isCentered = true,
	textStyles = {},
	...props
}) => {
	const { lang } = useParams();

	return (
		<div
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			className={`icon-text d-flex flex-row ${
				isCentered ? 'align-items-center' : ''
			} py-1`}
			{...props}
		>
			{/* Icon */}
			{icon && (
				<span
					className={lang === 'en' ? 'me-2' : 'ms-2'}
					style={{
						filter: dropShadow ? 'drop-shadow(1px 1px 0 #000000)' : '',
					}}
				>
					{icon}
				</span>
			)}

			<div className='d-flex flex-column'>
				{/* Main Label */}
				{label && (
					<span
						className={`${isCapitalized ? 'text-capitalize' : ''} fw-bold ${
							lang === 'en' ? 'ps-1' : 'pe-1'
						} text-limit`}
						style={{
							minWidth: '8rem',
							'--lines': 1,
						}}
					>{`${label}:`}</span>
				)}

				{/* Main Text */}
				{text && (
					<span
						className={`${isCapitalized ? 'text-capitalize' : ''}`}
						style={textStyles}
					>
						{text}
					</span>
				)}
			</div>
		</div>
	);
};

export default IconTextComponent;
