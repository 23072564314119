import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { fixGoogleMaps } from './../../helpers/general';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import './ContactPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import ContactInfoComponent from './../../components/ContactInfoComponent/ContactInfoComponent';
import ContactFormComponent from './../../components/_Forms/ContactFormComponent/ContactFormComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const ContactPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const { settings } = useSelector((state) => state.settingsData);

	const breadcrumbItems = [
		{ title: t('words:breadcrumb.home'), href: `/${lang}`, isActive: false },
		{ title: t('words:breadcrumb.contactUs'), href: '', isActive: true },
	];

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='contact-us-page'
			className='page'
		>
			<>
				{/* Meta Tags */}
				<MetaTagsComponent pageTitle={t('words:windowTab.contactUs')} />

				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.contactUs')}
					items={breadcrumbItems}
				/>

				<Container fluid='md'>
					<Row xs={1} lg={2} className='gx-5 gy-4'>
						{/* Contact Form */}
						<Col>
							<ContactFormComponent />
						</Col>

						{/* Contact Information */}
						<Col
							id='contact-info'
							className='p-4'
							style={{
								backgroundColor: '#f9fafd',
								border: '1px solid #e5f1fb',
								borderRadius: '0.375rem',
							}}
						>
							<ContactInfoComponent />
						</Col>
					</Row>

					{/* Company Map */}
					<Row xs={1} className='g-4 mt-3 overflow-hidden'>
						<Fade direction='down' delay={200}>
							<Col>
								<div
									id='company-map'
									dangerouslySetInnerHTML={{
										__html: fixGoogleMaps(settings.map),
									}}
								></div>
							</Col>
						</Fade>
					</Row>
				</Container>
			</>
		</section>
	);
};

export default ContactPage;
