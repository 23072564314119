import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	ButtonGroup,
	Col,
	Container,
	Dropdown,
	DropdownButton,
	Image,
	Row,
} from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './NoResultsComponent.styles.css';

const NoResultsComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			fluid
			className='text-center d-flex align-items-center text-dark'
			id='no-results'
		>
			<Container fluid='xs' className='my-auto'>
				<Row xs={1} className='g-4'>
					<Fade direction='down' delay={100}>
						<Col className='h-100 align-middle'>
							{/* 404 Image */}
							<Image
								fluid
								src={require('./../../assets/images/no-results.png')}
								alt='no results found'
								className='img-fluid text-capitalize w-100 h-100'
								style={{
									objectFit: 'contain',
									objectPosition: 'center',
									maxHeight: '20rem',
								}}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = require('./../../assets/images/logos/logo.png');
								}}
							/>
						</Col>
					</Fade>

					<Fade direction='down' delay={300}>
						<Col className='h-100 d-flex flex-column justify-content-center align-items-center p-5'>
							{/* Main Text */}
							<h2 className='display-6 mb-4 text-capitalize'>
								{t('sentences:errors.noData', { title: t('words:data') })}
							</h2>

							{/* Redirect Button To Request Course */}
							<DropdownButton
								as={ButtonGroup}
								id='dropdown-basic-button'
								variant='danger'
								menuVariant='dark'
								title={
									<>
										<span
											className={`text-capitalize ${
												lang === 'en' ? 'me-1' : 'ms-1'
											}`}
										>
											{t('words:buttons.requestCourse')}
										</span>
										<IoIosArrowDown />
									</>
								}
							>
								<Dropdown.Item
									as={Link}
									eventKey='0'
									to={`/${lang}/course-request/contract`}
									className='text-capitalize'
								>
									{t('words:contract')}
								</Dropdown.Item>
								<Dropdown.Item
									as={Link}
									eventKey='1'
									to={`/${lang}/course-request/individual`}
									className='text-capitalize'
								>
									{t('words:individual')}
								</Dropdown.Item>
							</DropdownButton>
						</Col>
					</Fade>
				</Row>
			</Container>
		</Container>
	);
};

export default NoResultsComponent;
