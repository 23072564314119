import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Pages
import AboutPage from './pages/AboutPage/AboutPage';
import CategoryDetailsPage from './pages/CategoryDetailsPage/CategoryDetailsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import CourseDetailsPage from './pages/CourseDetailsPage/CourseDetailsPage';
import CourseRequestPage from './pages/CourseRequestPage/CourseRequestPage';
import HomePage from './pages/HomePage/HomePage';
import SearchResultPage from './pages/SearchResultPage/SearchResultPage';
import SectionPage from './pages/SectionPage/SectionPage';
import TrainingPlanPage from './pages/TrainingPlanPage/TrainingPlanPage';
import MainTemplatePage from './pages/_MainTemplatePage/MainTemplatePage';
import NotFoundPage from './pages/_NotFoundPage/NotFoundPage';

const App = () => {
	return (
		<>
			<BrowserRouter>
				{/* Available Routes */}
				<Routes>
					{/* Home Page */}
					<Route path='/' element={<Navigate to='/ar' />} />
					<Route
						path='/:lang'
						element={
							<MainTemplatePage>
								<HomePage />
							</MainTemplatePage>
						}
					/>

					{/* Sections */}
					<Route
						path='/:lang/section/:section_id'
						element={
							<MainTemplatePage>
								<SectionPage />
							</MainTemplatePage>
						}
					/>

					{/* Training Plan Page */}
					<Route
						path='/:lang/training-plan'
						element={
							<MainTemplatePage>
								<TrainingPlanPage />
							</MainTemplatePage>
						}
					/>

					{/* Course Request Page */}
					<Route
						path='/:lang/course-request/:type'
						element={
							<MainTemplatePage>
								<CourseRequestPage />
							</MainTemplatePage>
						}
					/>

					{/* About Page */}
					<Route
						path='/:lang/about-us'
						element={
							<MainTemplatePage>
								<AboutPage />
							</MainTemplatePage>
						}
					/>

					{/* Contact Page */}
					<Route
						path='/:lang/contact-us'
						element={
							<MainTemplatePage>
								<ContactPage />
							</MainTemplatePage>
						}
					/>

					{/* Category Details Page */}
					<Route
						path='/:lang/section/:section_id/category/:category_id'
						element={
							<MainTemplatePage>
								<CategoryDetailsPage />
							</MainTemplatePage>
						}
					/>

					{/* Course Details Page */}
					<Route
						path='/:lang/section/:section_id/course/:course_id'
						element={
							<MainTemplatePage>
								<CourseDetailsPage />
							</MainTemplatePage>
						}
					/>

					{/* Search Result Page */}
					<Route
						path='/:lang/search'
						element={
							<MainTemplatePage>
								<SearchResultPage />
							</MainTemplatePage>
						}
					/>

					{/* Not Found Page: Error 404 */}
					<Route path='/:lang/*' element={<NotFoundPage />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
