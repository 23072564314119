import React, { useEffect } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './WhatsappIconComponent.styles.css';

const WhatsappIconComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const { whatsappNumber } = useSelector((state) => state.settingsData);

	return (
		<OverlayTrigger
			placement='top'
			overlay={
				<Tooltip className='text-capitalize'>
					{t('sentences:contactUsThroughWhatsapp')}
				</Tooltip>
			}
		>
			<div
				className='whatsapp-chat'
				style={{
					display: 'flex',
				}}
			>
				<a
					href={`https://wa.me/${whatsappNumber}`}
					target='_blank'
					rel='noreferrer'
				>
					<Image
						fluid
						src={require('./../../assets/images/whatsapp.png')}
						alt='whatsapp icon'
						className='rounded-circle text-capitalize w-100 h-100'
						style={{
							objectFit: 'contain',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>
				</a>
			</div>
		</OverlayTrigger>
	);
};

export default WhatsappIconComponent;
