import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import 'moment/locale/ar';
import React, { useEffect, useRef } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormControl,
	FormGroup,
	FormLabel,
	FormText,
	Image,
	InputGroup,
	OverlayTrigger,
	Popover,
	Row,
	Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mixed, object, string } from 'yup';
import { BASE_URL } from './../../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Icons
import { FaCamera } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

// Styles
import './InstructorRegistrationFormComponent.styles.css';

// Components
import ButtonComponent from './../../ButtonComponent/ButtonComponent';

const InstructorRegistrationFormComponent = ({ setInstructorModalShow }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const instructorImageRef = useRef();
	const instructorImagePreviewRef = useRef();
	const instructorFirstNameRef = useRef();
	const instructorLastNameRef = useRef();
	const instructorEmailRef = useRef();
	const instructorJobRef = useRef();
	const instructorPhoneRef = useRef();
	const instructorCVRef = useRef();
	const cv_fileNameRef = useRef();
	const cv_fileInputGroupRef = useRef();

	const IMAGE_FILE_SIZE = 900 * 1000; // 900 KB
	const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg'];

	const FILE_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
	const FILE_SUPPORTED_FORMATS = ['application/pdf'];

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				instructorFirstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				instructorLastNameRef.current.classList.add('is-invalid');

				break;

			case 'email':
				instructorEmailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				instructorPhoneRef.current.classList.add('is-invalid');
				break;

			case 'job':
				instructorJobRef.current.classList.add('is-invalid');
				break;

			case 'cv':
				instructorCVRef.current.classList.add('is-invalid');
				cv_fileInputGroupRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);

				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const instructorSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		job: string()
			.min(2, t('validations:academicTitle.min', { min: 2 }))
			.max(100, t('validations:academicTitle.max', { max: 100 }))
			.required(t('validations:academicTitle.required')),
		image: mixed()
			.nullable()
			.notRequired()
			.test(
				'fileFormat',
				t('validations:file.type'),
				(value) =>
					!value || (value && IMAGE_SUPPORTED_FORMATS.includes(value.type))
			)
			.test(
				'fileSize',
				t('validations:file.size'),
				(value) => !value || (value && value.size <= IMAGE_FILE_SIZE)
			),
		cv: mixed()
			.test(
				'fileFormat',
				t('validations:file.type'),
				(value) =>
					!value || (value && FILE_SUPPORTED_FORMATS.includes(value.type))
			)
			.test(
				'fileSize',
				t('validations:file.size'),
				(value) => !value || (value && value.size <= FILE_FILE_SIZE)
			)
			.required(t('validations:file.required')),
	});

	const submitInstructorRegistrationForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/join',
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				job: values.job,
				image: values.image,
				cv: values.cv,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				displayToast(response.data.status, response.data.msg);

				// reset submitting
				setSubmitting(false);
				resetForm(true);
				setInstructorModalShow(false);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	return (
		<Container fluid className='instructor-registration-form-component px-0'>
			<Formik
				initialValues={{
					fname: '',
					lname: '',
					email: '',
					phone: '',
					job: '',
					image: null,
					cv: null,
					cv_filename: '',
				}}
				validationSchema={instructorSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitInstructorRegistrationForm(
						values,
						setSubmitting,
						resetForm,
						lang
					);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<Form
						className='position-relative overflow-visible'
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit();
						}}
						style={{
							paddingTop: '4rem',
						}}
					>
						{/* Avatar Selection */}
						<FormGroup
							className={`avatar-image ${
								errors.image ? 'is-invalid' : ''
							} position-absolute rounded-circle`}
							style={{
								top: '-20px',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								zIndex: '99',
							}}
						>
							{/* Avatar Preview */}
							{!errors.image && values.image && (
								<OverlayTrigger
									trigger={['hover', 'focus']}
									placement='top'
									overlay={
										<Popover
											dir={lang === 'en' ? 'ltr' : 'rtl'}
											lang={lang ?? 'ar'}
										>
											<Popover.Body className='text-capitalize'>
												{t('sentences:editProfileImage')}
											</Popover.Body>
										</Popover>
									}
								>
									<Image
										fluid
										src={URL.createObjectURL(values.image)}
										alt='avatar thumbnail'
										className={`rounded-circle text-capitalize w-100 h-100 ${
											touched.image && errors.image ? 'is-invalid' : ''
										}`}
										style={{
											objectFit: 'cover',
											objectPosition: 'top',
											height: '8rem',
											width: '8rem',
											cursor: 'pointer',
										}}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = require('./../../../assets/images/logos/logo.png');
										}}
										onClick={() => instructorImageRef.current.click()}
									/>
								</OverlayTrigger>
							)}

							{/* Remove Avatar Icon */}
							{!errors.image && values.image && (
								<MdClose
									className='position-absolute rounded-circle p-1'
									style={{
										top: '0',
										right: lang === 'en' ? '0' : 'unset',
										left: lang === 'en' ? 'unset' : '0',
										backgroundColor: '#f9fafd',
										border: '1px solid #e5f1fb',
										cursor: 'pointer',
									}}
									size={30}
									color='#b94a4b'
									onClick={() => setFieldValue('image', null)}
								/>
							)}

							{/* Add Avatar Icon */}
							{(!values.image || errors.image) && (
								<OverlayTrigger
									trigger={['hover', 'focus']}
									placement='top'
									overlay={
										<Popover
											dir={lang === 'en' ? 'ltr' : 'rtl'}
											lang={lang ?? 'ar'}
										>
											<Popover.Body className='text-capitalize'>
												{t('sentences:addProfileImage')}
											</Popover.Body>
										</Popover>
									}
								>
									<div
										ref={instructorImagePreviewRef}
										className='avatar-icon p-1 d-flex justify-content-center align-items-center rounded-circle border'
										style={{
											width: '8rem',
											height: '8rem',
											backgroundColor: 'rgb(147, 202, 255)',
											cursor: 'pointer',
										}}
										onClick={() => instructorImageRef.current.click()}
									>
										<FaCamera size={40} />
									</div>
								</OverlayTrigger>
							)}

							{/* Avatar Input File */}
							<Field name='image' innerRef={instructorImageRef}>
								{(field, form, meta) => (
									<>
										<FormControl
											{...field}
											type='file'
											accept='image/jpg,image/png,image/jpeg'
											ref={instructorImageRef}
											hidden
											onChange={(event) => {
												setFieldValue('image', event.currentTarget.files[0]);

												// Remove (invalid input) styles
												field.meta.error
													? (instructorImagePreviewRef.current.style.borderColor =
															'#dc3545')
													: (instructorImagePreviewRef.current.style.borderColor =
															'');
											}}
											onBlur={handleBlur}
											className={`form-control ${
												field.meta.error ? 'is-invalid' : ''
											}`}
										/>

										{field.meta.error && (
											<div className='invalid-feedback'>{field.meta.error}</div>
										)}
									</>
								)}
							</Field>
						</FormGroup>

						<div id='instructor-form'>
							<Row xs={1} sm={2} className='overflow-hidden'>
								{/* First Name */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={20}>
									<FormGroup as={Col} className='mb-3 h-100'>
										<FormLabel htmlFor='first_name' className='text-capitalize'>
											{t('words:labels.firstName')}
										</FormLabel>
										<Field
											id='first_name'
											type='text'
											innerRef={instructorFirstNameRef}
											placeholder={t('words:placeholders.firstName')}
											autoComplete='off'
											name='fname'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={handleBlur}
											value={values.fname}
											className={`form-control text-capitalize ${
												touched.fname && errors.fname ? 'is-invalid' : ''
											}`}
										/>
										<ErrorMessage
											component='div'
											name='fname'
											className='invalid-feedback'
										/>
									</FormGroup>
								</Fade>

								{/* Last Name */}
								<Fade direction={lang === 'en' ? 'right' : 'left'} delay={20}>
									<FormGroup as={Col} className='mb-3 h-100'>
										<FormLabel htmlFor='last_name' className='text-capitalize'>
											{t('words:labels.lastName')}
										</FormLabel>
										<Field
											id='last_name'
											type='text'
											innerRef={instructorLastNameRef}
											placeholder={t('words:placeholders.lastName')}
											autoComplete='off'
											name='lname'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={handleBlur}
											value={values.lname}
											className={`form-control text-capitalize ${
												touched.lname && errors.lname ? 'is-invalid' : ''
											}`}
										/>
										<ErrorMessage
											component='div'
											name='lname'
											className='invalid-feedback'
										/>
									</FormGroup>
								</Fade>

								{/* Email */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={40}>
									<FormGroup as={Col} className='mb-3 h-100'>
										<FormLabel htmlFor='email' className='text-capitalize'>
											{t('words:labels.email')}
										</FormLabel>
										<Field
											id='email'
											type='email'
											innerRef={instructorEmailRef}
											placeholder='mail@domain.com'
											autoComplete='off'
											name='email'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={handleBlur}
											value={values.email}
											className={`form-control ${
												touched.email && errors.email ? 'is-invalid' : ''
											}`}
										/>
										<ErrorMessage
											component='div'
											name='email'
											className='invalid-feedback'
										/>
									</FormGroup>
								</Fade>

								{/* Mobile Number */}
								<Fade direction={lang === 'en' ? 'right' : 'left'} delay={40}>
									<FormGroup as={Col} className='mb-3 h-100'>
										<FormLabel htmlFor='phone' className='text-capitalize'>
											{t('words:labels.phone')}
										</FormLabel>
										<Field name='phone' innerRef={instructorPhoneRef}>
											{(field, form, meta) => (
												<>
													<PhoneInput
														{...field}
														id='phone'
														dir='ltr'
														ref={instructorPhoneRef}
														placeholder='56 123 0620'
														defaultCountry='SA'
														autoComplete='off'
														onChange={(event) => {
															setFieldValue('phone', event);
														}}
														onBlur={handleBlur}
														value={values.phone}
														className={`${
															field.meta.touched && field.meta.error
																? 'is-invalid'
																: ''
														}`}
													/>
													{field.meta.error && (
														<div className='invalid-feedback'>
															{field.meta.error}
														</div>
													)}
												</>
											)}
										</Field>
									</FormGroup>
								</Fade>

								{/* Academic Title */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={60}>
									<FormGroup as={Col} className='mb-3 h-100'>
										<FormLabel
											htmlFor='academic_title'
											className='text-capitalize'
										>
											{t('words:labels.academicTitle')}
										</FormLabel>
										<Field
											id='academic_title'
											type='text'
											innerRef={instructorJobRef}
											placeholder={t('words:placeholders.academicTitle')}
											autoComplete='off'
											name='job'
											onChange={(event) => {
												setFieldValue('job', event.target.value);
											}}
											onBlur={handleBlur}
											value={values.job}
											className={`form-control text-capitalize ${
												touched.job && errors.job ? 'is-invalid' : ''
											}`}
										/>
										<ErrorMessage
											component='div'
											name='job'
											className='invalid-feedback'
										/>
									</FormGroup>
								</Fade>
							</Row>

							<Row xs={1} className='overflow-hidden'>
								{/* CV or Resume */}
								<Fade direction='down' delay={60}>
									<FormGroup as={Col} className='mb-3'>
										<FormLabel className='text-capitalize'>
											{t('words:labels.cvFile')}
										</FormLabel>
										<InputGroup
											ref={cv_fileInputGroupRef}
											className={`${errors.cv ? 'is-invalid' : ''}`}
										>
											<InputGroup.Text
												id='file-label'
												className='text-capitalize'
												onClick={() => instructorCVRef.current.click()}
											>
												{t('words:labels.fileInput')}
											</InputGroup.Text>

											{/* File Name */}
											<Field
												readOnly
												id='filename'
												type='text'
												aria-label='Choose File'
												aria-describedby='file-label'
												innerRef={cv_fileNameRef}
												placeholder={t('words:placeholders.fileName')}
												autoComplete='off'
												name='cv_filename'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												onClick={() => instructorCVRef.current.click()}
												value={values.cv_filename}
												className={`form-control ${
													touched.cv_filename && errors.cv_filename
														? 'is-invalid'
														: ''
												}`}
											/>

											{/* Actual Hidden File */}
											<Field
												hidden
												id='file'
												type='file'
												aria-label='Choose File'
												aria-describedby='file-label'
												accept='application/pdf'
												innerRef={instructorCVRef}
												placeholder={t('words:placeholders.file')}
												autoComplete='off'
												name='cv'
												onChange={(event) => {
													setFieldValue('cv', event.currentTarget.files[0]);
													setFieldValue(
														'cv_filename',
														event.currentTarget.files[0].name
													);
												}}
												onBlur={handleBlur}
												value={''}
												className={`form-control ${
													touched.file && errors.file ? 'is-invalid' : ''
												}`}
											/>
										</InputGroup>

										{/* File Hints */}
										<FormText className='d-block text-muted'>
											{t('words:hints.file.size', {
												max: new Intl.NumberFormat(
													lang === 'en' ? 'en-US' : 'ar-EG',
													{}
												).format(FILE_FILE_SIZE / 1024 / 1024),
											})}
										</FormText>
										<FormText className='text-muted d-flex align-items-center'>
											<div>{t('words:hints.file.type')}</div>
											<div
												className={`${lang === 'en' ? 'ms-1' : 'me-1'}`}
												style={{ direction: 'ltr' }}
											>
												{'.pdf'}
											</div>
										</FormText>

										{errors.cv && (
											<FormControl.Feedback className='invalid-feedback p-0 d-block'>
												{errors.cv}
											</FormControl.Feedback>
										)}
									</FormGroup>
								</Fade>
							</Row>

							{/* Submit Form */}
							<Zoom delay={100}>
								<FormGroup className='d-flex justify-content-center mt-3'>
									<ButtonComponent
										title={
											isSubmitting
												? t('words:buttons.loading')
												: t('words:buttons.joinAsInstructor')
										}
										icon={
											isSubmitting ? (
												<Spinner
													animation='border'
													variant='light'
													size='sm'
													className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
												/>
											) : (
												<></>
											)
										}
										type='submit'
										styles={{
											button: {
												padding: '0.5rem 1rem',
											},
											title: {
												fontWeight: '200',
												fontSize: '16px',
											},
										}}
										disabled={isSubmitting ? true : false}
									/>
								</FormGroup>
							</Zoom>
						</div>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default InstructorRegistrationFormComponent;
