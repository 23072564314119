import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect, useRef, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	ButtonGroup,
	Col,
	Container,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	OverlayTrigger,
	Row,
	Spinner,
} from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import PhoneInput from 'react-phone-number-input';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { date, number, object, ref, string } from 'yup';
import { BASE_URL } from './../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import './../../styles/MobileNumberComponent.styles.css';
import './CourseRequestPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const CourseRequestPage = () => {
	// i18next
	const { lang, type } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	moment.locale('en');

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const chargeablePersonRef = useRef();
	const emailRef = useRef();
	const jobTitleRef = useRef();
	const phoneRef = useRef();
	const employerRef = useRef();
	const countryRef = useRef();
	const cityRef = useRef();
	const courseTitleRef = useRef();
	const courseCountryRef = useRef();
	const courseCityRef = useRef();
	const courseStartDateRef = useRef();
	const courseEndDateRef = useRef();
	const coursePeriodRef = useRef();
	const attendeesCountRef = useRef();
	const notesRef = useRef();

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:requestSpecialCourse'),
			href: '',
			isActive: true,
		},
	];

	const availableLocations = [
		{ value: 'hotel', title: t('words:hotel') },
		{ value: 'other', title: t('words:others') },
	];

	const requestSchema = {
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		employer: string()
			.min(2, t('validations:employer.min', { min: 2 }))
			.max(100, t('validations:employer.max', { max: 100 }))
			.required(t('validations:employer.required')),
		country: string()
			.min(2, t('validations:country.min', { min: 2 }))
			.max(100, t('validations:country.max', { max: 100 }))
			.required(t('validations:country.required')),
		city: string()
			.min(2, t('validations:city.min', { min: 2 }))
			.max(100, t('validations:city.max', { max: 100 }))
			.required(t('validations:city.required')),
		course_title: string()
			.min(2, t('validations:courseTitle.min', { min: 2 }))
			.max(100, t('validations:courseTitle.max', { max: 100 }))
			.required(t('validations:courseTitle.required')),
		course_country: string()
			.min(2, t('validations:courseCountry.min', { min: 2 }))
			.max(100, t('validations:courseCountry.max', { max: 100 }))
			.required(t('validations:courseCountry.required')),
		course_city: string()
			.min(2, t('validations:courseCity.min', { min: 2 }))
			.max(100, t('validations:courseCity.max', { max: 100 }))
			.required(t('validations:courseCity.required')),
		start_date: date()
			// .min(new Date())
			.min(new Date(), t('validations:startDate.invalid'))
			// .default(new Date().setDate(new Date().getDate() + 1))
			.required(t('validations:startDate.required')),
		end_date: date()
			.min(ref('start_date'), t('validations:endDate.invalid'))
			.required(t('validations:endDate.required')),
		course_location: string().required(
			t('validations:courseLocation.required')
		),
		notes: string()
			.nullable()
			.notRequired()
			.max(255, t('validations:notes.max', { max: 255 })),
	};

	const contractSchema = object().shape({
		...requestSchema,
		chargeable_person: string()
			.min(2, t('validations:chargeablePerson.min', { min: 2 }))
			.max(100, t('validations:chargeablePerson.max', { max: 100 }))
			.required(t('validations:chargeablePerson.required')),
		job_title: string()
			.min(2, t('validations:jobTitle.min', { min: 2 }))
			.max(100, t('validations:jobTitle.max', { max: 100 }))
			.required(t('validations:jobTitle.required')),
		attendees_count: number()
			.integer(t('validations:attendeesCount.integer'))
			.positive(t('validations:attendeesCount.positive'))
			.min(1, t('validations:attendeesCount.min', { min: 1 }))
			.moreThan(0, t('validations:attendeesCount.moreThan', { min: 0 }))
			.max(30, t('validations:attendeesCount.max', { max: 30 }))
			.lessThan(31, t('validations:attendeesCount.lessThan', { max: 31 }))
			.required(t('validations:attendeesCount.required')),
	});

	const individualSchema = object().shape({
		...requestSchema,
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
	});

	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState(false);

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'chargeable_person':
				chargeablePersonRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'job_title':
				jobTitleRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'employer':
				employerRef.current.classList.add('is-invalid');
				break;

			case 'country':
				countryRef.current.classList.add('is-invalid');
				break;

			case 'city':
				cityRef.current.classList.add('is-invalid');
				break;

			case 'course_title':
				courseTitleRef.current.classList.add('is-invalid');
				break;

			case 'course_country':
				courseCountryRef.current.classList.add('is-invalid');
				break;

			case 'course_city':
				courseCityRef.current.classList.add('is-invalid');
				break;

			case 'start_date':
				setStartDateError(true);
				break;

			case 'end_date':
				setEndDateError(true);
				break;

			case 'course_period':
				coursePeriodRef.current.classList.add('is-invalid');
				break;

			case 'attendees_count':
				attendeesCountRef.current.classList.add('is-invalid');
				break;

			case 'notes':
				notesRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const handleDatePeriod = (setFieldValue) => {
		setFieldValue('course_period', t('validations:coursePeriod.invalid'));
	};

	const formatFormFields = (formFields) => {
		const validKeys = Object.keys(formFields).filter(
			(key) =>
				formFields[key] !== '' &&
				formFields[key] !== null &&
				formFields[key] !== undefined &&
				JSON.stringify(formFields[key]) !== JSON.stringify([])
		);

		const data = Object.fromEntries(
			validKeys.map((key) => {
				return [key, formFields[key]];
			})
		);

		data['course_period'] = moment
			.duration(new moment(data.end_date).diff(new moment(data.start_date)))
			.locale(lang ?? 'ar')
			.humanize(true);

		data['start_date'] = moment(data.start_date)
			.locale('en')
			.format('DD-MM-YYYY');

		data['end_date'] = moment(data.end_date).locale('en').format('DD-MM-YYYY');

		data['course_location'] =
			data.location_name === '' ||
				data.location_name === null ||
				data.location_name === undefined
				? data.course_location
				: `${data.course_location} - ${data.location_name}`;

		return data;
	};

	const submitRequestForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		const data = formatFormFields(values);
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/training-request',
			data: {
				...data,
				course_type: type.toLowerCase(),
			},
			headers: {
				locale: language,
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.msg);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.status === 401) {
					displayToast(error.response.status, error.response.data);
				} else {
					typeof error.response.data.data === typeof {}
						? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
						})
						: displayToast(error.response.status, error.response.data.data);
				}
			});
	};

	// Validate Course Start Date
	useEffect(() => {
		if (startDateError) {
			document
				.getElementsByClassName('rmdp-input')[0]
				.classList.add('is-invalid');
		} else {
			setTimeout(() => {
				if (
					document
						.getElementsByClassName('rmdp-input')[0]
						.classList.contains('is-invalid')
				) {
					document
						.getElementsByClassName('rmdp-input')[0]
						.classList.remove('is-invalid');
				}
			}, 1000);
		}
	}, [startDateError]);

	// Validate Course End Date
	useEffect(() => {
		if (endDateError) {
			document
				.getElementsByClassName('rmdp-input')[1]
				.classList.add('is-invalid');
		} else {
			setTimeout(() => {
				if (
					document
						.getElementsByClassName('rmdp-input')[1]
						.classList.contains('is-invalid')
				) {
					document
						.getElementsByClassName('rmdp-input')[1]
						.classList.remove('is-invalid');
				}
			}, 1000);
		}
	}, [endDateError]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='course-request-page'
			className='page position-relative'
		>
			<>
				{/* Stacked Buttons */}
				<ButtonGroup vertical className='action-btns'>
					<Link
						to={`/${lang}/contact-us`}
						target='_blank'
						rel='noreferrer'
						className='mb-3'
						style={{
							textDecoration: 'none',
						}}
					>
						<ButtonComponent
							title={t('words:buttons.contactUs')}
							styles={{
								button: {
									padding: '0.5rem 1.5rem',
								},
							}}
						/>
					</Link>
					<Link
						to={`/${lang}/search`}
						target='_blank'
						rel='noreferrer'
						style={{
							textDecoration: 'none',
						}}
					>
						<ButtonComponent
							title={t('words:buttons.allCourses')}
							styles={{
								button: {
									padding: '0.5rem 1.5rem',
								},
							}}
						/>
					</Link>
				</ButtonGroup>

				{/* Meta Tags */}
				<MetaTagsComponent pageTitle={t('words:windowTab.courseRequest')} />

				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={
						type === 'individual'
							? t('words:breadcrumb.individualTrainingCourse')
							: t('words:breadcrumb.contractTrainingCourse')
					}
					items={breadcrumbItems}
				/>

				<Container
					fluid='md'
					style={{
						maxWidth: '1000px',
					}}
				>
					<Row xs={1} className='g-4'>
						<Col>
							<Formik
								initialValues={{
									fname: '',
									lname: '',
									chargeable_person: '',
									email: '',
									job_title: '',
									phone: '',
									employer: '',
									country: '',
									city: '',
									course_title: '',
									course_country: '',
									course_city: '',
									start_date: new Date(),
									end_date: new Date(
										moment().add(1, 'days').locale('en').format('llll')
									),
									course_period: moment
										.duration(
											new moment(
												new Date(
													moment().add(1, 'days').locale('en').format('llll')
												)
											)
												.add(1, 'days')
												.diff(new moment(new Date()))
										)
										.locale(lang ?? 'ar')
										.humanize(true)
										.split(' ')
										.filter((_, index) => index > 0)
										.join(' '),
									attendees_count: 1,
									course_location: availableLocations[0].title,
									location_name: '',
									notes: '',
								}}
								validationSchema={
									type === 'contract' ? contractSchema : individualSchema
								}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setSubmitting(true);
									submitRequestForm(values, setSubmitting, resetForm, lang);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
									<Form
										onSubmit={(event) => {
											event.preventDefault();

											handleSubmit();
										}}
										className='p-4 overflow-hidden'
										style={{
											backgroundColor: '#f9fafd',
											border: '1px solid #e5f1fb',
											borderRadius: '0.375rem',
										}}
									>
										<Row className='overflow-hidden'>
											<Fade direction='down' delay={20}>
												<p
													className={`form-title lead text-capitalize py-1 px-0 ${lang === 'en' ? 'pe-3' : 'ps-3'
														} m-0 mb-3`}
												>
													{type === 'contract'
														? t('words:contractCourseApplicationForm')
														: t('words:individualCourseApplicationForm')}
												</p>
											</Fade>
										</Row>

										<Row xs={1} sm={2} lg={3}>
											{/* First Name (within individual) --: OR :-- Organization Name (within contract) */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={40}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													{type === 'contract' ? (
														<>
															{/* Contract: Organization Name */}
															<FormLabel
																htmlFor='company_name'
																className='text-capitalize'
															>
																{t('words:labels.organization')}
															</FormLabel>
															<Field
																id='company_name'
																type='text'
																innerRef={employerRef}
																autoComplete='off'
																name='employer'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.employer}
																className={`form-control text-capitalize ${touched.employer && errors.employer
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='employer'
																className='invalid-feedback'
															/>
														</>
													) : (
														<>
															{/* Individual: First Name */}
															<FormLabel
																htmlFor='first_name'
																className='text-capitalize'
															>
																{t('words:labels.firstName')}
															</FormLabel>
															<Field
																id='first_name'
																type='text'
																innerRef={firstNameRef}
																autoComplete='off'
																name='fname'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.fname}
																className={`form-control text-capitalize ${touched.fname && errors.fname
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='fname'
																className='invalid-feedback'
															/>
														</>
													)}
												</FormGroup>
											</Fade>

											{/* Last Name (within individual) --: OR :-- Chargeable Person (within contract) */}
											<Fade direction='down' delay={40}>
												<FormGroup as={Col} className='mb-3 h-100'>
													{type === 'contract' ? (
														<>
															{/* Contract: Chargeable Person Name */}
															<FormLabel
																htmlFor='chargeable_person'
																className='text-capitalize'
															>
																{t('words:labels.chargeablePerson')}
															</FormLabel>
															<Field
																id='chargeable_person'
																type='text'
																innerRef={chargeablePersonRef}
																placeholder={t(
																	'words:placeholders.chargeablePerson'
																)}
																autoComplete='off'
																name='chargeable_person'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.chargeable_person}
																className={`form-control text-capitalize ${touched.chargeable_person &&
																		errors.chargeable_person
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='chargeable_person'
																className='invalid-feedback'
															/>
														</>
													) : (
														<>
															{/* Individual: Last Name */}
															<FormLabel
																htmlFor='last_name'
																className='text-capitalize'
															>
																{t('words:labels.lastName')}
															</FormLabel>
															<Field
																id='last_name'
																type='text'
																innerRef={lastNameRef}
																autoComplete='off'
																name='lname'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.lname}
																className={`form-control text-capitalize ${touched.lname && errors.lname
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='lname'
																className='invalid-feedback'
															/>
														</>
													)}
												</FormGroup>
											</Fade>

											{/* Email (within individual) --: OR :-- Job Title (within contract) */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={40}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													{type === 'contract' ? (
														<>
															<FormLabel
																htmlFor='job_title'
																className='text-capitalize'
															>
																{t('words:labels.jobTitle')}
															</FormLabel>
															<Field
																id='job_title'
																type='text'
																innerRef={jobTitleRef}
																autoComplete='off'
																name='job_title'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.job_title}
																className={`form-control ${touched.job_title && errors.job_title
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='job_title'
																className='invalid-feedback'
															/>
														</>
													) : (
														<>
															<FormLabel
																htmlFor='email'
																className='text-capitalize'
															>
																{t('words:labels.email')}
															</FormLabel>
															<Field
																id='email'
																type='email'
																innerRef={emailRef}
																placeholder='mail@domain.com'
																autoComplete='off'
																name='email'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.email}
																className={`form-control ${touched.email && errors.email
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='email'
																className='invalid-feedback'
															/>
														</>
													)}
												</FormGroup>
											</Fade>
										</Row>

										<Row xs={1} sm={2}>
											{/* Mobile Number */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={60}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='phone'
														className='text-capitalize'
													>
														{t('words:labels.phone')}
													</FormLabel>
													<Field name='phone' innerRef={phoneRef}>
														{(field, form, meta) => (
															<>
																<PhoneInput
																	{...field}
																	id='phone'
																	dir='ltr'
																	ref={phoneRef}
																	placeholder='56 123 0620'
																	defaultCountry='SA'
																	autoComplete='off'
																	onChange={(event) => {
																		setFieldValue('phone', event);
																	}}
																	onBlur={handleBlur}
																	value={values.phone}
																	className={`${field.meta.touched && field.meta.error
																			? 'is-invalid'
																			: ''
																		}`}
																/>
																{field.meta.error && (
																	<div className='invalid-feedback'>
																		{field.meta.error}
																	</div>
																)}
															</>
														)}
													</Field>
												</FormGroup>
											</Fade>

											{/* Company Name (within individual) --: OR :-- Email (within contract) */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={60}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													{type === 'contract' ? (
														<>
															<FormLabel
																htmlFor='email'
																className='text-capitalize'
															>
																{t('words:labels.email')}
															</FormLabel>
															<FormControl
																id='email'
																type='email'
																ref={emailRef}
																placeholder='mail@domain.com'
																autoComplete='off'
																name='email'
																onChange={(event) => {
																	handleChange(event);

																	// Remove (invalid input) styles
																	if (
																		emailRef.current.classList.contains(
																			'is-invalid'
																		)
																	)
																		emailRef.current.classList.remove(
																			'is-invalid'
																		);
																}}
																onBlur={handleBlur}
																value={values.email}
																className={`${touched.email && errors.email
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='email'
																className='invalid-feedback'
															/>
														</>
													) : (
														<>
															<FormLabel
																htmlFor='company_name'
																className='text-capitalize'
															>
																{t('words:labels.employer')}
															</FormLabel>
															<FormControl
																id='company_name'
																type='text'
																ref={employerRef}
																autoComplete='off'
																name='employer'
																onChange={(event) => {
																	handleChange(event);

																	// Remove (invalid input) styles
																	if (
																		employerRef.current.classList.contains(
																			'is-invalid'
																		)
																	)
																		employerRef.current.classList.remove(
																			'is-invalid'
																		);
																}}
																onBlur={handleBlur}
																value={values.employer}
																className={`text-capitalize ${touched.employer && errors.employer
																		? 'is-invalid'
																		: ''
																	}`}
															/>
															<ErrorMessage
																component='div'
																name='employer'
																className='invalid-feedback'
															/>
														</>
													)}
												</FormGroup>
											</Fade>
										</Row>

										<Row xs={1} sm={2} className='mb-3'>
											{/* Country */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={80}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='country'
														className='text-capitalize'
													>
														{t('words:labels.country')}
													</FormLabel>
													<Field
														id='country'
														type='text'
														innerRef={countryRef}
														autoComplete='off'
														name='country'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.country}
														className={`form-control text-capitalize ${touched.country && errors.country
																? 'is-invalid'
																: ''
															}`}
													/>
													<ErrorMessage
														component='div'
														name='country'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* City */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={80}
											>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel htmlFor='city' className='text-capitalize'>
														{t('words:labels.cityName')}
													</FormLabel>
													<Field
														id='city'
														type='text'
														innerRef={cityRef}
														autoComplete='off'
														name='city'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.city}
														className={`form-control text-capitalize ${touched.city && errors.city ? 'is-invalid' : ''
															}`}
													/>
													<ErrorMessage
														component='div'
														name='city'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>
										</Row>

										<Row className='overflow-hidden'>
											<Fade direction='down' delay={100}>
												<p
													className={`form-title lead text-capitalize py-1 px-0 ${lang === 'en' ? 'pe-3' : 'ps-3'
														} m-0 mb-3`}
												>
													{t('words:requestedCourseInfo')}
												</p>
											</Fade>
										</Row>

										<Row xs={1} sm={2} lg={3} className='overflow-hidden'>
											{/* Course Title */}
											<Fade direction='up' delay={120}>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='course_title'
														className='text-capitalize'
													>
														{t('words:labels.courseTitle')}
													</FormLabel>
													<Field
														id='course_title'
														type='text'
														innerRef={courseTitleRef}
														autoComplete='off'
														name='course_title'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.course_title}
														className={`form-control text-capitalize ${touched.course_title && errors.course_title
																? 'is-invalid'
																: ''
															}`}
													/>
													<ErrorMessage
														component='div'
														name='course_title'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Course Country */}
											<Fade direction='up' delay={140}>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='course_country'
														className='text-capitalize'
													>
														{t('words:labels.courseCountry')}
													</FormLabel>
													<Field
														id='course_country'
														type='text'
														innerRef={courseCountryRef}
														autoComplete='off'
														name='course_country'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.course_country}
														className={`form-control text-capitalize ${touched.course_country && errors.course_country
																? 'is-invalid'
																: ''
															}`}
													/>
													<ErrorMessage
														component='div'
														name='course_country'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Course City */}
											<Fade direction='up' delay={160}>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='course_city'
														className='text-capitalize'
													>
														{t('words:labels.courseCity')}
													</FormLabel>
													<Field
														id='course_city'
														type='text'
														innerRef={courseCityRef}
														autoComplete='off'
														name='course_city'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.course_city}
														className={`form-control text-capitalize ${touched.course_city && errors.course_city
																? 'is-invalid'
																: ''
															}`}
													/>
													<ErrorMessage
														component='div'
														name='course_city'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>
										</Row>

										<Row xs={1} sm={2} lg={3}>
											{/* Course Start Date */}
											<Fade direction='up' delay={200}>
												<FormGroup
													as={Col}
													ref={courseStartDateRef}
													className='mb-3 h-100'
													id='start_date_container'
												>
													<FormLabel
														htmlFor='start_date'
														className='text-capitalize'
													>
														{t('words:labels.courseStartDate')}
													</FormLabel>
													<Field name='start_date'>
														{(field, form, meta) => (
															<>
																<DatePicker
																	id='start_date'
																	onOpen={() => {
																		// courseStartDateRef?.current?.scrollIntoView({
																		// 	block: 'end',
																		// 	behavior: 'smooth',
																		// });
																		document.querySelector('#start_date_container').scrollIntoView({
																			block: 'end',
																			behavior: 'smooth',
																		});
																		setTimeout(() => {
																			window.scrollBy({
																				behavior: 'smooth',
																				top: 5,
																			});
																		}, 400);
																	}}
																	onChange={(date) => {
																		setStartDateError(false);
																		setEndDateError(false);

																		// Set Start Date
																		setFieldValue(
																			'start_date',
																			new Date(
																				date.year,
																				date.month.index,
																				date.day
																			)
																		);

																		// Set Period
																		setFieldValue(
																			'course_period',
																			moment
																				.duration(
																					new moment(values.end_date)
																						.add(1, 'days')
																						.diff(
																							new Date(
																								date.year,
																								date.month.index,
																								date.day
																							)
																						)
																				)
																				.locale(lang ?? 'ar')
																				.humanize(true)
																				.split(' ')
																				.filter((_, index) => index > 0)
																				.join(' ')
																		);

																		// console.log(
																		// 	'START_course_period',
																		// 	moment
																		// 		.duration(
																		// 			new moment(values.end_date)
																		// 				.add(1, 'days')
																		// 				.diff(
																		// 					new Date(
																		// 						date.year,
																		// 						date.month.index,
																		// 						date.day
																		// 					)
																		// 				)
																		// 		)
																		// 		.locale(lang ?? 'ar')
																		// 		.humanize(true)
																		// 		.split(' ')
																		// 		.filter((_, index) => index > 0)
																		// 		.join(' ')
																		// );

																		if (
																			moment(
																				new Date(
																					date.year,
																					date.month.index,
																					date.day
																				)
																			).isAfter(values.end_date)
																		) {
																			setStartDateError(true);
																			setEndDateError(true);

																			// Invalid Period Of Time
																			handleDatePeriod(setFieldValue);
																		}
																	}}
																	value={values.start_date}
																	dateFormat='dd/MM/yyyy'
																	minDate={new Date()}
																	render={<InputIcon />}
																	containerStyle={{
																		width: '100%',
																	}}
																/>
															</>
														)}
													</Field>
												</FormGroup>
											</Fade>

											{/* Course End Date */}
											<Fade direction='up' delay={220}>
												<FormGroup
													as={Col}
													ref={courseEndDateRef}
													className='mb-3 h-100'
													id='end_date_container'
												>
													<FormLabel
														htmlFor='end_date'
														className='text-capitalize'
													>
														{t('words:labels.courseEndDate')}
													</FormLabel>
													<DatePicker
														id='end_date'
														onOpen={() => {
															// courseEndDateRef?.current?.scrollIntoView({
															// 	block: 'end',
															// 	behavior: 'smooth',
															// });
															document.querySelector('#end_date_container').scrollIntoView({
																block: 'end',
																behavior: 'smooth',
															});
															setTimeout(() => {
																window.scrollBy({
																	behavior: 'smooth',
																	top: 5,
																});
															}, 400);
														}}
														onChange={(date) => {
															setStartDateError(false);
															setEndDateError(false);

															// Set End Date
															setFieldValue(
																'end_date',
																new Date(date.year, date.month.index, date.day)
															);

															// {
															// 	console.log(
															// 		moment
															// 			.duration(
															// 				moment(
															// 					new Date(
															// 						date.year,
															// 						date.month.index,
															// 						date.day
															// 					)
															// 				).diff(values.start_date)
															// 			)
															// 			.humanize(true)
															// 	);
															// }

															// Set Period
															setFieldValue(
																'course_period',
																moment
																	.duration(
																		new moment(
																			new Date(
																				date.year,
																				date.month.index,
																				date.day
																			)
																		)
																			.add(1, 'days')
																			.diff(values.start_date)
																	)
																	.locale(lang ?? 'ar')
																	.humanize(true)
																	.split(' ')
																	.filter((_, index) => index > 0)
																	.join(' ')
															);

															// console.log(
															// 	'END_course_period',
															// 	moment
															// 		.duration(
															// 			new moment(
															// 				new Date(
															// 					date.year,
															// 					date.month.index,
															// 					date.day
															// 				)
															// 			)
															// 				.add(2, 'days')
															// 				.diff(values.start_date)
															// 		)
															// 		.locale(lang ?? 'ar')
															// 		.humanize(true)
															// 		.split(' ')
															// 		.filter((_, index) => index > 0)
															// 		.join(' ')
															// );

															if (
																moment(values.start_date).isAfter(
																	new Date(
																		date.year,
																		date.month.index,
																		date.day
																	)
																)
															) {
																setStartDateError(true);
																setEndDateError(true);

																// Invalid Period Of Time
																handleDatePeriod(setFieldValue);
															}
														}}
														value={values.end_date}
														dateFormat='dd/MM/yyyy'
														minDate={new Date().setDate(
															new Date().getDate() + 1
														)}
														render={<InputIcon />}
														containerStyle={{
															width: '100%',
														}}
													/>
												</FormGroup>
											</Fade>

											{/* Course Period */}
											<Fade direction='up' delay={240}>
												<FormGroup as={Col} className='mb-3 h-100'>
													<FormLabel
														htmlFor='course_period'
														className='text-capitalize'
													>
														{t('words:labels.coursePeriod')}
													</FormLabel>
													<Field
														id='course_period'
														disabled
														readOnly
														innerRef={coursePeriodRef}
														className='form-control text-capitalize'
														type='text'
														value={values.course_period}
														autoComplete='off'
														name='course_period'
													/>
													<ErrorMessage
														component='div'
														name='course_period'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>
										</Row>

										<Row
											xs={1}
											sm={type === 'contract' ? 2 : 1}
											className='overflow-hidden'
										>
											{/* Attendees Count */}
											{type === 'contract' && (
												<Fade direction='up' delay={260}>
													<FormGroup as={Col} sm={5} className='mb-3 h-100'>
														<FormLabel
															htmlFor='attendees_count'
															className='text-capitalize'
														>
															{t('words:labels.attendeesCount')}
														</FormLabel>
														<Field
															id='attendees_count'
															type='number'
															min={1}
															max={30}
															innerRef={attendeesCountRef}
															placeholder={t(
																'words:placeholders.attendeesCount'
															)}
															autoComplete='off'
															name='attendees_count'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.attendees_count}
															className={`form-control ${touched.attendees_count &&
																	errors.attendees_count
																	? 'is-invalid'
																	: ''
																}`}
														/>
														<ErrorMessage
															component='div'
															name='attendees_count'
															className='invalid-feedback'
														/>
													</FormGroup>
												</Fade>
											)}

											{/* Desired Location */}
											<Fade direction='up' delay={280}>
												<FormGroup
													as={Col}
													sm={type === 'contract' ? 7 : 12}
													className='mb-3 h-100'
												>
													<FormLabel className='d-block text-capitalize'>
														{t('words:labels.courseLocation')}
													</FormLabel>
													<div className='d-flex flex-wrap align-items-center flex-1'>
														{availableLocations.map((availableLocation) => (
															<FormCheck
																key={availableLocation.value}
																inline
																reverse={lang === 'en' ? false : true}
																type='radio'
																id={availableLocation.value}
																name='course_location'
																value={availableLocation.title}
																checked={
																	availableLocation.title ===
																		values.course_location
																		? true
																		: false
																}
																onChange={(event) => {
																	setFieldValue(
																		'course_location',
																		event.target.value
																	);
																}}
																label={availableLocation.title}
																className={`filter-option text-capitalize text-nowrap flex-1 ${lang === 'en' ? 'me-4' : 'ms-4'
																	}`}
																style={{
																	paddingTop: '0.375rem',
																	paddingBottom: '0.375rem',
																}}
															/>
														))}

														{values.course_location.includes(
															t('words:others')
														) && (
																<Fade direction='down' delay={20}>
																	<Field
																		id='location_name'
																		type='text'
																		innerRef={courseTitleRef}
																		placeholder={t(
																			'words:placeholders.courseLocation'
																		)}
																		autoComplete='off'
																		name='location_name'
																		onChange={(event) => {
																			setFieldValue(
																				'location_name',
																				event.target.value
																			);
																		}}
																		onBlur={handleBlur}
																		value={values.location_name}
																		className={`location-name text-capitalize form-control flex-1 ${touched.location_name &&
																				errors.location_name
																				? 'is-invalid'
																				: ''
																			}`}
																	/>
																</Fade>
															)}
													</div>
												</FormGroup>
											</Fade>
										</Row>

										<Row xs={1} className='overflow-hidden'>
											{/* Notes */}
											<Fade direction='up' delay={320}>
												<FormGroup
													as={Col}
													className='mb-4 position-relative h-100'
												>
													<FormLabel
														htmlFor='notes'
														className='text-capitalize'
													>
														{t('words:labels.notes')}
													</FormLabel>
													<Field
														id='notes'
														as='textarea'
														innerRef={notesRef}
														rows={6}
														style={{
															resize: 'none',
														}}
														placeholder={t('words:placeholders.notes')}
														autoComplete='off'
														name='notes'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.notes}
														className={`form-control text-capitalize ${touched.notes && errors.notes ? 'is-invalid' : ''
															}`}
													/>
													<div className='text-muted'>
														<span
															className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
														>
															{new Intl.NumberFormat(
																lang === 'en' ? 'en-US' : 'ar-EG',
																{}
															).format(values.notes.length)}
														</span>
														<span>
															{t('words:hints.message.length', {
																max: new Intl.NumberFormat(
																	lang === 'en' ? 'en-US' : 'ar-EG',
																	{}
																).format(255),
															})}
														</span>
													</div>
													<ErrorMessage
														component='div'
														name='notes'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>
										</Row>

										{/* Submit Form */}
										<Zoom delay={360}>
											<FormGroup className='d-flex justify-content-center mt-3 h-100'>
												<OverlayTrigger
													trigger={['hover', 'focus']}
													placement='top'
													overlay={<></>}
												>
													<span className='d-inline-block'>
														<ButtonComponent
															title={
																isSubmitting
																	? t('words:buttons.loading')
																	: t('words:buttons.submitRequest')
															}
															icon={
																isSubmitting ? (
																	<Spinner
																		animation='border'
																		variant='light'
																		size='sm'
																		className={`${lang === 'en' ? 'me-2' : 'ms-2'
																			}`}
																	/>
																) : (
																	<></>
																)
															}
															type='submit'
															disabled={isSubmitting ? true : false}
														/>
													</span>
												</OverlayTrigger>
											</FormGroup>
										</Zoom>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</Container>
			</>
		</section>
	);
};

export default CourseRequestPage;
