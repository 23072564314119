import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CityCardComponent.styles.css';

const CityCardComponent = ({ city }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const navigate = useNavigate();

	return (
		<Card
			className='city-component overflow-hidden'
			onClick={() => {
				navigate({
					pathname: `/${lang}/search`,
					search: createSearchParams({
						city_id: city.id,
					}).toString(),
				});
			}}
		>
			<Card.Img
				fluid='true'
				src={city.image}
				alt='city image'
				className='h-100 w-100 text-capitalize'
				style={{
					objectFit: 'cover',
					objectPosition: 'center',
				}}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = require('./../../assets/images/logos/logo.png');
				}}
			/>
			<Card.ImgOverlay className='text-container d-flex flex-column justify-content-center align-items-center'>
				<Card.Title
					className='title text-capitalize text-limit'
					style={{
						'--lines': 1,
					}}
				>
					{city.title}
				</Card.Title>
				<Card.Text className='text-capitalize'>
					{t('sentences:availableCourses', {
						count: city.courses_count,
					})}
				</Card.Text>
			</Card.ImgOverlay>
		</Card>
	);
};

export default CityCardComponent;
