import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Icons
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ContactInfoComponent.styles.css';

// Components
import IconTextComponent from './../IconTextComponent/IconTextComponent';

const ContactInfoComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const { settings } = useSelector((state) => state.settingsData);

	return (
		<Container fluid className='contact-info-component px-0'>
			<Row xs={1} className='overflow-hidden gy-4'>
				{/* Main Title */}
				<Fade direction={lang === 'en' ? 'left' : 'right'} delay={10}>
					<Col
						className='h-100 display-3 fw-bold text-capitalize'
						style={{
							fontSize: '32px',
							color: '#0071dc',
						}}
					>
						{t('words:contactUs')}
					</Col>
				</Fade>

				{/* Company Name */}
				<Fade direction={lang === 'en' ? 'left' : 'right'} delay={200}>
					<Col
						className='h-100 display-6 fw-bold text-capitalize'
						style={{
							fontSize: '22px',
							color: '#b94a4b',
						}}
					>
						{settings.website_title}
					</Col>
				</Fade>

				{/* Company Address */}
				<Fade direction={lang === 'en' ? 'left' : 'right'} delay={300}>
					<Col className='h-100 text-capitalize'>
						<IconTextComponent
							icon={<HiOutlineLocationMarker size={24} color='#343439' />}
							text={settings.address}
							isCentered={false}
						/>
					</Col>
				</Fade>
			</Row>

			<Row className='overflow-hidden mt-3'>
				{/* Phone Numbers */}
				<Col
					xs={12}
					sm={6}
					md={6}
					lg={5}
					xl={6}
					id='phone-numbers'
					className='h-100 overflow-hidden'
				>
					<Fade direction={lang === 'en' ? 'left' : 'right'} delay={400}>
						{settings.phones && (
							<ul className='py-0'>
								{settings.phones
									.filter((phone, _) => phone.value !== null)
									.map((phone, index) => (
										<IconTextComponent
											key={index}
											icon={<FiPhoneCall size={24} color='#343439' />}
											label={phone.label}
											text={
												<li>
													<a href={`tel:${phone.value}`}>
														<div className='phone-number' dir='ltr'>
															{phone.value}
														</div>
													</a>
												</li>
											}
											isCentered={false}
										/>
									))}
							</ul>
						)}
					</Fade>
				</Col>

				{/* Emails */}
				<Col
					xs={{ span: 12, order: 'first' }}
					sm={{ span: 6, order: 'last' }}
					md={6}
					lg={7}
					xl={6}
					className='h-100 overflow-hidden'
				>
					<Fade direction={lang === 'en' ? 'right' : 'left'} delay={400}>
						{settings.emails &&
							settings.emails
								.filter((email, _) => email !== null)
								.map((email, index) => (
									<a key={index} href={`mailto:${email}`}>
										<IconTextComponent
											icon={<HiOutlineMail size={24} />}
											text={email}
											isCapitalized={false}
										/>
									</a>
								))}
					</Fade>
				</Col>
			</Row>
		</Container>
	);
};

export default ContactInfoComponent;
