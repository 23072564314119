import AOS from 'aos';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// Fonts
import '@fontsource/cairo';
import '@fontsource/inter';

// CSS Files
import 'animate.css';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import './index.css';

// i18next
import './i18n';

// Redux
import { Provider } from 'react-redux';
import { store } from './store/index.store';

// Components
import App from './App';
import LoadingPageComponent from './components/LoadingPageComponent/LoadingPageComponent';

// AOS Setup
AOS.init({
	duration: 1000,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Suspense fallback={<LoadingPageComponent />}>
			<App />
		</Suspense>
	</Provider>
);
