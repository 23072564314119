import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MONTH_NAMES } from './../../constants/months.constant';

export const fetchMonths = createAsyncThunk(
	'monthsSlice/fetchMonths',
	async (language = 'ar', { rejectWithValue }) => {
		return MONTH_NAMES[language.toUpperCase()];
	}
);

export const monthsSlice = createSlice({
	initialState: {
		months: [],
		isMonthsLoading: true,
	},
	name: 'monthsSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchMonths.pending, (state, action) => {
			state.isMonthsLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchMonths.fulfilled, (state, action) => {
			state.months = action.payload;
			state.isMonthsLoading = false;
		});

		// Rejected
		builder.addCase(fetchMonths.rejected, (state, action) => {
			state.isMonthsLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = monthsSlice.actions;

// Reducer
export default monthsSlice.reducer;
