import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { trimEmptyTags } from './../../helpers/general';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './AboutPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import SearchFormComponent from './../../components/_Forms/SearchFormComponent/SearchFormComponent';
import MetaTagsComponent from './../../components/_MetaTagsComponent/MetaTagsComponent';

const AboutPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{ title: t('words:breadcrumb.home'), href: `/${lang}`, isActive: false },
		{ title: t('words:breadcrumb.aboutUs'), href: '', isActive: true },
	];

	const { aboutUs, vision, message, service, value } = useSelector(
		(state) => state.aboutData
	);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='about-us-page'
			className='page'
		>
			<>
				{/* Meta Tags */}
				<MetaTagsComponent pageTitle={t('words:windowTab.aboutUs')} />

				{/* Breadcrumb */}
				<BreadcrumbComponent
					title={t('words:breadcrumb.aboutUs')}
					items={breadcrumbItems}
				/>

				<Container fluid='md'>
					{/* Search Form */}
					<SearchFormComponent
						styles={{
							container: {
								paddingBottom: '2.5rem',
							},
						}}
					/>

					<Row xs={1} className='g-4'>
						{/* Main About Title */}
						<Fade direction='up' delay={50}>
							<Col>
								<h2
									className='display-5 fw-bold text-capitalize'
									style={{
										fontSize: '32px',
									}}
								>
									{aboutUs.title.toLowerCase()}
								</h2>
							</Col>
						</Fade>

						{/* Available About Sections */}
						<Col className='available-sections'>
							<Row xs={1} className='g-4'>
								{/* Vision */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={100}>
									<Col className='about-card'>
										<Row xs={1} sm={2} className='g-0'>
											<Col>
												<Image
													fluid
													src={vision.image}
													alt='vision thumbnail'
													className='text-capitalize w-100 h-100'
													style={{
														objectFit: 'cover',
														objectPosition: 'center',
													}}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = require('./../../assets/images/logos/logo.png');
													}}
												/>
											</Col>
											<Col
												className={`about-card-description ${
													window.innerWidth < 576 ? 'text-center' : ''
												}`}
											>
												<h4
													className='display-6 fw-bold text-capitalize mb-4 text-center'
													style={{
														fontSize: '30px',
													}}
												>
													{vision.title.toLowerCase()}
												</h4>
												<p
													className='text-black-50'
													dangerouslySetInnerHTML={{
														__html: trimEmptyTags(vision.description),
													}}
												></p>
											</Col>
										</Row>
									</Col>
								</Fade>

								{/* Message */}
								<Fade direction={lang === 'en' ? 'right' : 'left'} delay={100}>
									<Col className='about-card'>
										<Row xs={1} sm={2} className='g-0'>
											<Col xs={{ order: 'first' }} sm={{ order: 'last' }}>
												<Image
													fluid
													src={message.image}
													alt='mission thumbnail'
													className='text-capitalize w-100 h-100'
													style={{
														objectFit: 'cover',
														objectPosition: 'center',
													}}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = require('./../../assets/images/logos/logo.png');
													}}
												/>
											</Col>
											<Col
												className={`about-card-description ${
													window.innerWidth < 576 ? 'text-center' : ''
												}`}
											>
												<h4
													className='display-6 fw-bold text-capitalize mb-4 text-center'
													style={{
														fontSize: '30px',
													}}
												>
													{message.title.toLowerCase()}
												</h4>
												<p
													className='text-black-50'
													dangerouslySetInnerHTML={{
														__html: trimEmptyTags(message.description),
													}}
												></p>
											</Col>
										</Row>
									</Col>
								</Fade>

								{/* Value */}
								<Fade direction={lang === 'en' ? 'left' : 'right'} delay={100}>
									<Col className='about-card'>
										<Row xs={1} sm={2} className='g-0'>
											<Col>
												<Image
													fluid
													src={value.image}
													alt='values thumbnail'
													className='text-capitalize w-100 h-100'
													style={{
														objectFit: 'cover',
														objectPosition: 'center',
													}}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = require('./../../assets/images/logos/logo.png');
													}}
												/>
											</Col>
											<Col
												className={`about-card-description ${
													window.innerWidth < 576 ? 'text-center' : ''
												}`}
											>
												<h4
													className='display-6 fw-bold text-capitalize mb-4 text-center'
													style={{
														fontSize: '30px',
													}}
												>
													{value.title.toLowerCase()}
												</h4>
												<p
													className='text-black-50'
													dangerouslySetInnerHTML={{
														__html: trimEmptyTags(value.description),
													}}
												></p>
											</Col>
										</Row>
									</Col>
								</Fade>

								{/* Services */}
								<Fade direction={lang === 'en' ? 'right' : 'left'} delay={100}>
									<Col className='about-card'>
										<Row xs={1} sm={2} className='g-0'>
											<Col xs={{ order: 'first' }} sm={{ order: 'last' }}>
												<Image
													fluid
													src={service.image}
													alt='services thumbnail'
													className='text-capitalize w-100 h-100'
													style={{
														objectFit: 'cover',
														objectPosition: 'center',
													}}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = require('./../../assets/images/logos/logo.png');
													}}
												/>
											</Col>
											<Col
												className={`about-card-description ${
													window.innerWidth < 576 ? 'text-center' : ''
												}`}
											>
												<h4
													className='display-6 fw-bold text-capitalize mb-4 text-center'
													style={{
														fontSize: '30px',
													}}
												>
													{service.title.toLowerCase()}
												</h4>
												<p
													className='text-black-50'
													dangerouslySetInnerHTML={{
														__html: trimEmptyTags(service.description),
													}}
												></p>
											</Col>
										</Row>
									</Col>
								</Fade>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		</section>
	);
};

export default AboutPage;
