import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

const MetaTagsComponent = ({ pageTitle = '' }) => {
	const { lang } = useParams();

	const { settings } = useSelector((state) => state.settingsData);

	return (
		<HelmetProvider>
			<Helmet htmlAttributes={{ lang: lang }}>
				{/* Open Graph Meta Tags */}
				<meta property='og:title' content={settings.meta_title} />
				<meta property='og:description' content={settings.meta_description} />
				<meta property='og:image' content={settings.logo} />

				{/* Meta Tags */}
				<meta name='title' content={settings.meta_title} />
				<meta name='description' content={settings.meta_description} />
				<meta name='keywords' content={settings.meta_keywords} />

				{/* Tab Title */}
				<title>
					{pageTitle
						? `${pageTitle} | ${settings.website_title}`
						: `${settings.website_title}`}
				</title>

				{/* Favicon */}
				<link
					rel='shortcut icon'
					href={
						settings.fav_icon ?? require('./../../assets/images/logos/logo.png')
					}
				/>
			</Helmet>
		</HelmetProvider>
	);
};

export default MetaTagsComponent;
