import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	CloseButton,
	Col,
	FormGroup,
	FormLabel,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../helpers/general';

// Redux

// i18next
import { useTranslation } from 'react-i18next';

// Styles

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const CertificateRequestModalComponent = ({
	setCertificateRequestModalShow,
	setCertificateCheckModalShow,
	checkCertificateRequest,
	...props
}) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const courseTitleRef = useRef();

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'course_title':
				courseTitleRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);

				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const certificateRequestSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		courseTitle: string()
			.min(2, t('validations:courseTitle.min', { min: 2 }))
			.max(100, t('validations:courseTitle.max', { max: 100 }))
			.required(t('validations:courseTitle.required')),
	});

	const submitRequestCertificateForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		checkCertificateRequest.abort();
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/request-certificate',
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				course_title: values.courseTitle,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				displayToast(response.data.status, response.data.msg);

				// reset submitting
				setSubmitting(false);
				resetForm(true);
				setCertificateRequestModalShow(false);
				setCertificateCheckModalShow(false);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	return (
		<Modal
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			id='certificate-request-modal'
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header>
				<Modal.Title
					id='contained-modal-title-vcenter'
					className='text-capitalize'
				>
					{t('words:requestCertificate')}
				</Modal.Title>
				<CloseButton
					className={`close-modal mx-0 ${
						lang === 'en' ? 'ms-auto' : 'me-auto'
					}`}
					aria-label='Hide'
					onClick={props.onHide}
				/>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						fname: '',
						lname: '',
						email: '',
						phone: '',
						courseTitle: '',
					}}
					validationSchema={certificateRequestSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						submitRequestCertificateForm(
							values,
							setSubmitting,
							resetForm,
							lang
						);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => (
						<>
							{/* Certificate Form */}
							<Form
								onSubmit={(event) => {
									event.preventDefault();
									handleSubmit();
								}}
								className='p-4 overflow-hidden'
							>
								<Row xs={1} sm={2} className='overflow-hidden'>
									{/* First Name */}
									<Fade
										direction={lang === 'en' ? 'left' : 'right'}
										delay={100}
									>
										<FormGroup as={Col} className='h-100 mb-3'>
											<FormLabel
												htmlFor='first_name'
												className='text-capitalize'
											>
												{t('words:labels.firstName')}
											</FormLabel>
											<Field
												id='first_name'
												type='text'
												innerRef={firstNameRef}
												placeholder={t('words:placeholders.firstName')}
												autoComplete='off'
												name='fname'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.fname}
												className={`form-control text-capitalize ${
													touched.fname && errors.fname ? 'is-invalid' : ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='fname'
												className='invalid-feedback'
											/>
										</FormGroup>
									</Fade>

									{/* Last Name */}
									<Fade
										direction={lang === 'en' ? 'right' : 'left'}
										delay={100}
									>
										<FormGroup as={Col} className='h-100 mb-3'>
											<FormLabel
												htmlFor='last_name'
												className='text-capitalize'
											>
												{t('words:labels.lastName')}
											</FormLabel>
											<Field
												id='last_name'
												type='text'
												innerRef={lastNameRef}
												placeholder={t('words:placeholders.lastName')}
												autoComplete='off'
												name='lname'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.lname}
												className={`form-control text-capitalize ${
													touched.lname && errors.lname ? 'is-invalid' : ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='lname'
												className='invalid-feedback'
											/>
										</FormGroup>
									</Fade>
								</Row>

								<Row xs={1} sm={2} className='overflow-hidden'>
									{/* Email */}
									<Fade
										direction={lang === 'en' ? 'left' : 'right'}
										delay={200}
									>
										<FormGroup as={Col} className='h-100 mb-3'>
											<FormLabel htmlFor='email' className='text-capitalize'>
												{t('words:labels.email')}
											</FormLabel>
											<Field
												id='email'
												type='email'
												innerRef={emailRef}
												placeholder='mail@domain.com'
												autoComplete='off'
												name='email'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.email}
												className={`form-control ${
													touched.email && errors.email ? 'is-invalid' : ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='email'
												className='invalid-feedback'
											/>
										</FormGroup>
									</Fade>

									{/* Mobile Number */}
									<Fade
										direction={lang === 'en' ? 'right' : 'left'}
										delay={200}
									>
										<FormGroup as={Col} className='h-100 mb-3'>
											<FormLabel htmlFor='phone' className='text-capitalize'>
												{t('words:labels.phone')}
											</FormLabel>
											<Field name='phone' innerRef={phoneRef}>
												{(field, form, meta) => (
													<>
														<PhoneInput
															{...field}
															id='phone'
															dir='ltr'
															ref={phoneRef}
															placeholder='56 123 0620'
															defaultCountry='SA'
															autoComplete='off'
															onChange={(event) => {
																setFieldValue('phone', event);
															}}
															onBlur={handleBlur}
															value={values.phone}
															className={`${
																field.meta.touched && field.meta.error
																	? 'is-invalid'
																	: ''
															}`}
														/>
														{field.meta.error && (
															<div className='invalid-feedback'>
																{field.meta.error}
															</div>
														)}
													</>
												)}
											</Field>
										</FormGroup>
									</Fade>
								</Row>

								<Row xs={1} className='overflow-hidden'>
									{/* Course Title */}
									<Fade direction='down' delay={300}>
										<FormGroup as={Col} className='h-100 mb-3'>
											<FormLabel
												htmlFor='course_title'
												className='text-capitalize'
											>
												{t('words:labels.courseTitle')}
											</FormLabel>
											<Field
												id='course_title'
												type='text'
												innerRef={courseTitleRef}
												placeholder={t('words:placeholders.courseTitle')}
												autoComplete='off'
												name='courseTitle'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.courseTitle}
												className={`form-control text-capitalize ${
													touched.courseTitle && errors.courseTitle
														? 'is-invalid'
														: ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='courseTitle'
												className='invalid-feedback'
											/>
										</FormGroup>
									</Fade>
								</Row>

								{/* Submit Form */}
								<Zoom delay={400}>
									<FormGroup className='h-100 d-flex justify-content-center'>
										<ButtonComponent
											title={
												isSubmitting
													? t('words:buttons.sending')
													: t('words:buttons.requestCertificate')
											}
											icon={
												isSubmitting ? (
													<Spinner
														animation='border'
														variant='light'
														size='sm'
														className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
													/>
												) : (
													<></>
												)
											}
											type='submit'
											styles={{
												button: {
													padding: '0.5rem 1rem',
												},
												title: {
													fontWeight: '200',
													fontSize: '16px',
												},
											}}
											disabled={isSubmitting ? true : false}
										/>
									</FormGroup>
								</Zoom>
							</Form>
						</>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};

export default CertificateRequestModalComponent;
