import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { trimEmptyTags } from './../../helpers/general';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './FeatureCardComponent.styles.css';

const FeatureCardComponent = ({ feature, ...props }) => {
	// i18next
	const { lang } = useParams();
	const { i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	return (
		<Card
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang ?? 'ar'}
			className='feature-card align-items-center px-1 py-4'
			style={{
				border: 'none',
				cursor: 'pointer',
				height: '17rem',
			}}
			{...props}
		>
			<Card.Img
				variant='top'
				fluid='true'
				src={feature.image}
				alt='feature thumbnail'
				className='img-fluid text-capitalize w-100'
				style={{
					height: '5rem',
					objectFit: 'contain',
					objectPosition: 'center',
				}}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = require('./../../assets/images/logos/logo.png');
				}}
			/>
			<Card.Body className='text-center'>
				<Card.Title
					className='text-capitalize text-limit'
					style={{
						fontSize: '20px',
						fontWeight: '500',
						margin: '20px 0',
						'--lines': 1,
					}}
					dangerouslySetInnerHTML={{ __html: trimEmptyTags(feature.title) }}
				></Card.Title>
				<Card.Text
					className='text-limit text-break'
					style={{
						color: '#808191',
						marginBottom: '0',
						lineHeight: '1.6',
						'--lines': 3,
					}}
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(feature.description),
					}}
				></Card.Text>
			</Card.Body>
		</Card>
	);
};

export default FeatureCardComponent;
