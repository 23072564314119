import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	Modal,
	OverlayTrigger,
	Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../helpers/general';

// Styles
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './../../styles/MobileNumberComponent.styles.css';
import './CourseSubscriptionComponent.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const CourseSubscriptionComponent = ({ course, isConference }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const [subscribeModalShow, setSubscribeModalShow] = useState(false);

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const employerRef = useRef();
	const countryRef = useRef();

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'employer':
				employerRef.current.classList.add('is-invalid');
				break;

			case 'country':
				countryRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const subscribeSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		employer: string()
			.min(2, t('validations:employer.min', { min: 2 }))
			.max(100, t('validations:employer.max', { max: 100 }))
			.required(t('validations:employer.required')),
		country: string()
			.min(2, t('validations:country.min', { min: 2 }))
			.max(100, t('validations:country.max', { max: 100 }))
			.required(t('validations:country.required')),
	});

	const submitRegistrationForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			baseURL: BASE_URL.demo,
			url: '/store-subscription',
			data: {
				course_id: course.id,
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				employer: values.employer,
				country: values.country,
			},
			headers: {
				locale: language,
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.msg);
				setSubscribeModalShow(false);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.status === 401) {
					displayToast(error.response.status, error.response.data);
				} else {
					typeof error.response.data.data === typeof {}
						? Object.keys(error.response.data.data).forEach((key) => {
								displayErrors(key);
								displayToast(
									error.response.status,
									error.response.data.data[key][0]
								);
						  })
						: displayToast(error.response.status, error.response.data.data);
				}
			});
	};

	return (
		<>
			<OverlayTrigger
				trigger={['hover', 'focus']}
				placement='top'
				overlay={<></>}
			>
				<span className='custom-btn-container d-inline-block'>
					<ButtonComponent
						title={
							isConference
								? t('words:buttons.conferenceKeynotes')
								: t('words:buttons.courseKeynotes')
						}
						disabled={false}
						onClick={() => setSubscribeModalShow(true)}
					/>

					{/* Course Subscription */}
					<Modal
						dir={lang === 'en' ? 'ltr' : 'rtl'}
						lang={lang ?? 'ar'}
						show={subscribeModalShow}
						onHide={() => setSubscribeModalShow(false)}
						size='lg'
						aria-labelledby='contained-modal-title-vcenter'
						centered
					>
						<Modal.Body className='p-0'>
							<Formik
								initialValues={{
									fname: '',
									lname: '',
									email: '',
									phone: '',
									employer: '',
									country: '',
								}}
								validationSchema={subscribeSchema}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setSubmitting(true);
									submitRegistrationForm(
										values,
										setSubmitting,
										resetForm,
										lang
									);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
									<Form
										onSubmit={(event) => {
											event.preventDefault();

											handleSubmit();
										}}
										className='p-4 overflow-hidden'
										style={{
											backgroundColor: '#f9fafd',
											border: '1px solid #e5f1fb',
											borderRadius: '0.375rem',
										}}
									>
										<Row xs={1} sm={2}>
											{/* First Name */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={100}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='first_name'
														className='text-capitalize'
													>
														{t('words:labels.firstName')}
													</FormLabel>
													<FormControl
														id='first_name'
														type='text'
														ref={firstNameRef}
														autoComplete='off'
														name='fname'
														onChange={(event) => {
															handleChange(event);

															// Remove (invalid input) styles
															if (
																firstNameRef.current.classList.contains(
																	'is-invalid'
																)
															)
																firstNameRef.current.classList.remove(
																	'is-invalid'
																);
														}}
														onBlur={handleBlur}
														value={values.fname}
														className={`text-capitalize ${
															touched.fname && errors.fname ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='fname'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Last Name */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={100}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='last_name'
														className='text-capitalize'
													>
														{t('words:labels.lastName')}
													</FormLabel>
													<FormControl
														id='last_name'
														type='text'
														ref={lastNameRef}
														autoComplete='off'
														name='lname'
														onChange={(event) => {
															handleChange(event);

															// Remove (invalid input) styles
															if (
																lastNameRef.current.classList.contains(
																	'is-invalid'
																)
															)
																lastNameRef.current.classList.remove(
																	'is-invalid'
																);
														}}
														onBlur={handleBlur}
														value={values.lname}
														className={`text-capitalize ${
															touched.lname && errors.lname ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='lname'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Email */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={200}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='email'
														className='text-capitalize'
													>
														{t('words:labels.email')}
													</FormLabel>
													<FormControl
														id='email'
														type='email'
														ref={emailRef}
														placeholder='mail@domain.com'
														autoComplete='off'
														name='email'
														onChange={(event) => {
															handleChange(event);

															// Remove (invalid input) styles
															if (
																emailRef.current.classList.contains(
																	'is-invalid'
																)
															)
																emailRef.current.classList.remove('is-invalid');
														}}
														onBlur={handleBlur}
														value={values.email}
														className={`${
															touched.email && errors.email ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='email'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Mobile Number */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={200}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='phone'
														className='text-capitalize'
													>
														{t('words:labels.phone')}
													</FormLabel>
													<PhoneInput
														id='phone'
														dir='ltr'
														ref={phoneRef}
														placeholder='56 123 0620'
														defaultCountry='SA'
														autoComplete='off'
														name='phone'
														onChange={(event) => {
															setFieldValue('phone', event);

															// Remove (invalid input) styles
															if (
																phoneRef.current.classList.contains(
																	'is-invalid'
																)
															)
																phoneRef.current.classList.remove('is-invalid');
														}}
														onBlur={handleBlur}
														value={values.phone}
														className={`${
															touched.phone && errors.phone ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='phone'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Company Name */}
											<Fade
												direction={lang === 'en' ? 'left' : 'right'}
												delay={300}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='company'
														className='text-capitalize'
													>
														{t('words:labels.employer')}
													</FormLabel>
													<FormControl
														id='company'
														type='text'
														ref={employerRef}
														autoComplete='off'
														name='employer'
														onChange={(event) => {
															handleChange(event);

															// Remove (invalid input) styles
															if (
																employerRef.current.classList.contains(
																	'is-invalid'
																)
															)
																employerRef.current.classList.remove(
																	'is-invalid'
																);
														}}
														onBlur={handleBlur}
														value={values.employer}
														className={`${
															touched.employer && errors.employer
																? 'is-invalid'
																: ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='employer'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>

											{/* Country */}
											<Fade
												direction={lang === 'en' ? 'right' : 'left'}
												delay={300}
											>
												<FormGroup as={Col} className='h-100 mb-3'>
													<FormLabel
														htmlFor='country'
														className='text-capitalize'
													>
														{t('words:labels.country')}
													</FormLabel>
													<FormControl
														id='country'
														type='text'
														ref={countryRef}
														autoComplete='off'
														name='country'
														onChange={(event) => {
															handleChange(event);

															// Remove (invalid input) styles
															if (
																countryRef.current.classList.contains(
																	'is-invalid'
																)
															)
																countryRef.current.classList.remove(
																	'is-invalid'
																);
														}}
														onBlur={handleBlur}
														value={values.country}
														className={`${
															touched.country && errors.country
																? 'is-invalid'
																: ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='country'
														className='invalid-feedback'
													/>
												</FormGroup>
											</Fade>
										</Row>

										{/* Submit Form */}
										<Zoom delay={400}>
											<FormGroup className='h-100 d-flex justify-content-center mt-3'>
												<OverlayTrigger
													trigger={['hover', 'focus']}
													placement='top'
													overlay={<></>}
												>
													<span className='d-inline-block'>
														<ButtonComponent
															title={
																isSubmitting
																	? t('words:buttons.loading')
																	: t('words:buttons.enroll')
															}
															type='submit'
															disabled={false}
														/>
													</span>
												</OverlayTrigger>
											</FormGroup>
										</Zoom>
									</Form>
								)}
							</Formik>
						</Modal.Body>
					</Modal>
				</span>
			</OverlayTrigger>
		</>
	);
};

export default CourseSubscriptionComponent;
