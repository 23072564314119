import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchCourses = createAsyncThunk(
	'coursesSlice/fetchCourses',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/courses',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const fetchSingleCourse = createAsyncThunk(
	'coursesSlice/fetchSingleCourse',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/course/show',
			params: {
				id: searchParams?.id,
			},
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const coursesSlice = createSlice({
	initialState: {
		courses: [],
		levels: {},
		prices: [],
		singleCourse: {},
		isCoursesLoading: true,
		isSingleCourseLoading: true,
	},
	name: 'coursesSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchCourses.pending, (state, action) => {
			state.isCoursesLoading = true;
		});
		builder.addCase(fetchSingleCourse.pending, (state, action) => {
			state.isSingleCourseLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchCourses.fulfilled, (state, action) => {
			state.courses = action.payload;
			state.levels = action.payload.levels;
			state.prices = Object.values(action.payload.prices);
			state.isCoursesLoading = false;
		});
		builder.addCase(fetchSingleCourse.fulfilled, (state, action) => {
			state.singleCourse.course = action.payload.course;
			state.singleCourse.relatedCourses = action.payload.related_courses.data;
			state.isSingleCourseLoading = false;
		});

		// Rejected
		builder.addCase(fetchCourses.rejected, (state, action) => {
			state.isCoursesLoading = true;
			console.log('rejected:', action.payload);
		});
		builder.addCase(fetchSingleCourse.rejected, (state, action) => {
			state.isSingleCourseLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = coursesSlice.actions;

// Reducer
export default coursesSlice.reducer;
