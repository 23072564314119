import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchCities = createAsyncThunk(
	'citiesSlice/fetchCities',
	async (language = 'ar', { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/cities',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const citiesSlice = createSlice({
	initialState: {
		cities: [],
		isCitiesLoading: true,
	},
	name: 'citiesSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchCities.pending, (state, action) => {
			state.isCitiesLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchCities.fulfilled, (state, action) => {
			state.cities = action.payload.city;
			state.isCitiesLoading = false;
		});

		// Rejected
		builder.addCase(fetchCities.rejected, (state, action) => {
			state.isCitiesLoading = true;
			console.log('rejected:', action.payload);
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = citiesSlice.actions;

// Reducer
export default citiesSlice.reducer;
