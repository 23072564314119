import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Icons
import { FaAngleDoubleUp } from 'react-icons/fa';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './GoToTopComponent.styles.css';

const GoToTopComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const [showScrollToTop, setShowScrollToTop] = useState(false);
	window.addEventListener('scroll', () => {
		window.scrollY > 400 ? setShowScrollToTop(true) : setShowScrollToTop(false);
	});

	return (
		<OverlayTrigger
			placement='top'
			overlay={
				<Tooltip className='text-capitalize'>{t('words:goTop')}</Tooltip>
			}
		>
			<div
				className='back-to-top '
				style={{
					display: showScrollToTop ? 'flex' : 'none',
				}}
				onClick={() =>
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth',
					})
				}
			>
				<FaAngleDoubleUp />
			</div>
		</OverlayTrigger>
	);
};

export default GoToTopComponent;
