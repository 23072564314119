import "country-flag-icons/3x2/flags.css";
import { GB, SA } from "country-flag-icons/react/3x2";
import React, { useEffect, useState } from "react";
import { Container, Image, Nav, NavDropdown, Navbar } from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// Images
import DefaultLogo from "./../../assets/images/logos/logo.png";

// Icons
import { HiOutlineMenu } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";

// Redux
import { useSelector } from "react-redux";
// import { fetchMonths } from './../../store/reducers/months.reducer';

// i18next
import { useTranslation } from "react-i18next";

// Styles
import "./NavbarComponent.styles.css";

// Components
import CertificateCheckModalComponent from "./../CertificateCheckModalComponent/CertificateCheckModalComponent";

const NavbarComponent = () => {
  // i18next
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang ?? "ar");
    // eslint-disable-next-line
  }, [lang]);

  const location = useLocation();
  const navigate = useNavigate();

  // States
  const [isExpanded, setIsExpanded] = useState(false);
  const [certificateCheckModalShow, setCertificateCheckModalShow] =
    useState(false);
  const [showTrainingDropdown, setShowTrainingDropdown] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showRequestsDropdown, setShowRequestsDropdown] = useState(false);

  const { sections } = useSelector((state) => state.sections);
  const { companyLogo } = useSelector((state) => state.settingsData);

  // Navbar Handlers
  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };
  const handleClose = () => setIsExpanded(false);

  // Dropdown toggle
  // Training
  const showTrainingDropdownMenu = (e) => {
    setShowTrainingDropdown(!showTrainingDropdown);
  };
  const hideTrainingDropdownMenu = (e) => {
    setShowTrainingDropdown(false);
  };

  // Services
  const showServicesDropdownMenu = (e) => {
    setShowServicesDropdown(!showServicesDropdown);
  };
  const hideServicesDropdownMenu = (e) => {
    setShowServicesDropdown(false);
  };

  // Requests
  const showRequestsDropdownMenu = (e) => {
    setShowRequestsDropdown(!showRequestsDropdown);
  };
  const hideRequestsDropdownMenu = (e) => {
    setShowRequestsDropdown(false);
  };

  return (
    <Navbar
      dir={lang === "en" ? "ltr" : "rtl"}
      lang={lang ?? "ar"}
      bg="white"
      fixed="top"
      expand="lg"
      expanded={isExpanded}
      className="border d-flex justify-content-center align-items-center flex-column"
      style={{
        boxShadow: "0 0 4px #35305b",
      }}
    >
      <Container
        fluid="md"
        className="py-2"
        id="navbar-content"
        style={{
          paddingRight: "1.2rem",
          paddingLeft: "1.2rem",
        }}
      >
        {/* Company Logo */}
        <Navbar.Brand as={Link} to={`/${lang}`} onClick={handleClose}>
          <Image
            fluid
            src={companyLogo ?? DefaultLogo}
            className="text-capitalize w-100 h-100 mr-0"
            alt="company logo"
            style={{
              objectFit: "contain",
              objectPosition: "center",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = require("./../../assets/images/logos/logo.png");
            }}
          />
        </Navbar.Brand>

        {/* Navbar Toggler Icon */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
          style={{
            order: window.innerWidth < 992 ? 1 : 0,
          }}
        >
          <HiOutlineMenu />
        </Navbar.Toggle>

        {/* Navbar Items */}
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{
            order: window.innerWidth < 992 ? 1 : 0,
            minHeight: "48px", // REQUIRED!!! Due to Offcanvas blinking
          }}
        >
          <Nav className={lang === "en" ? "ms-auto" : "me-auto"}>
            {/* Home */}
            <NavLink
              end
              to={`/${lang}`}
              className={({ isActive }) =>
                isActive
                  ? "text-capitalize nav-link active"
                  : "text-capitalize nav-link"
              }
              onClick={handleClose}
            >
              {t("words:navbar.home")}
            </NavLink>

            {/* Training Courses */}
            <NavDropdown
              title={
                <>
                  <span>{t("words:navbar.trainingCourses")}</span>
                  <IoIosArrowDown />
                </>
              }
              active={location.pathname.includes("section")}
              show={showTrainingDropdown}
              onMouseEnter={showTrainingDropdownMenu}
              onMouseLeave={hideTrainingDropdownMenu}
              onClick={showTrainingDropdownMenu}
              id="training-courses-nav-dropdown"
              className="text-capitalize"
            >
              {sections.map((section, index) => (
                <NavDropdown.Item
                  key={index}
                  as={NavLink}
                  to={`/${lang}/section/${section.id}`}
                  className="text-capitalize"
                  onClick={handleClose}
                >
                  {section.title}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            {/* Services */}
            <NavDropdown
              title={
                <>
                  <span>{t("words:navbar.services")}</span>
                  <IoIosArrowDown />
                </>
              }
              active={location.pathname.includes("training-plan")}
              id="services-nav-dropdown"
              show={showServicesDropdown}
              onMouseEnter={showServicesDropdownMenu}
              onMouseLeave={hideServicesDropdownMenu}
              onClick={showServicesDropdownMenu}
              className="text-capitalize"
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${lang}/training-plan`}
                className="text-capitalize"
                onClick={handleClose}
              >
                {t("words:navbar.trainingPlans")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to={`/${lang}/`}
                className="text-capitalize"
                onClick={(event) => {
                  event.preventDefault();
                  setCertificateCheckModalShow(true);
                  handleClose();
                }}
              >
                {t("words:navbar:verifyCertificate")}
              </NavDropdown.Item>

              {/* Certificate Modal */}
              <CertificateCheckModalComponent
                show={certificateCheckModalShow}
                onHide={() => {
                  setCertificateCheckModalShow(false);
                }}
                setCertificateCheckModalShow={setCertificateCheckModalShow}
              />
            </NavDropdown>

            {/* Request Course */}
            <NavDropdown
              title={
                <>
                  <span>{t("words:navbar.requestSpecialCourse")}</span>
                  <IoIosArrowDown />
                </>
              }
              active={location.pathname.includes("course-request")}
              id="request-course-nav-dropdown"
              show={showRequestsDropdown}
              onMouseEnter={showRequestsDropdownMenu}
              onMouseLeave={hideRequestsDropdownMenu}
              onClick={showRequestsDropdownMenu}
              className="text-capitalize"
            >
              <NavDropdown.Item
                as={NavLink}
                to={`/${lang}/course-request/contract`}
                className="text-capitalize"
                onClick={handleClose}
              >
                {t("words:navbar.contractTrainingCourse")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to={`/${lang}/course-request/individual`}
                className="text-capitalize"
                onClick={handleClose}
              >
                {t("words:navbar.individualTrainingCourse")}
              </NavDropdown.Item>
            </NavDropdown>

            {/* About Us */}
            <NavLink
              to={`/${lang}/about-us`}
              className={({ isActive }) =>
                isActive
                  ? "text-capitalize nav-link active"
                  : "text-capitalize nav-link"
              }
              onClick={handleClose}
            >
              {t("words:navbar.aboutUs")}
            </NavLink>

            {/* Contact Us */}
            <NavLink
              to={`/${lang}/contact-us`}
              className={({ isActive }) =>
                isActive
                  ? "text-capitalize nav-link active"
                  : "text-capitalize nav-link"
              }
              onClick={handleClose}
            >
              {t("words:navbar.contactUs")}
            </NavLink>

            {/* Translate */}
            <Navbar.Text
              id="translation"
              role="button"
              className="text-uppercase px-lg-2 fw-normal"
              onClick={() => {
                handleClose();
                navigate(`/${lang === "ar" ? "en" : "ar"}`);
              }}
              style={{
                fontFamily: "Cairo",
              }}
            >
              <span
                style={{
                  margin: lang === "en" ? "0 8px 0 0" : "0 0 0 8px",
                }}
              >
                {lang === "en" ? <SA /> : <GB />}
              </span>
              {t("words:navbar.otherLanguage")}
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
