import moment from "moment";
import "moment/locale/ar";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiDetail } from "react-icons/bi";
import { FaDownload, FaShare } from "react-icons/fa";
import { FiCopy, FiFilter } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";
import "react-phone-number-input/style.css";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "react-toastify/dist/ReactToastify.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { trimEmptyTags } from "./../../helpers/general";
import { fetchSingleCourse } from "./../../store/reducers/courses.reducer";

// i18next
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { useGetCoursesDataByIdQuery } from "../../store/RTKQuery/coursesApiSlice";

// Styles
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./../../styles/MobileNumberComponent.styles.css";
import "./CourseDetailsPage.styles.css";

// Components
import BreadcrumbComponent from "./../../components/BreadcrumbComponent/BreadcrumbComponent";
import ButtonComponent from "./../../components/ButtonComponent/ButtonComponent";
import CourseCardComponent from "./../../components/CourseCardComponent/CourseCardComponent";
import CourseSubscriptionComponent from "./../../components/CourseSubscriptionComponent/CourseSubscriptionComponent";
import IconTextComponent from "./../../components/IconTextComponent/IconTextComponent";
import MainPreloaderComponent from "./../../components/MainPreloaderComponent/MainPreloaderComponent";
import MetaTagsComponent from "./../../components/_MetaTagsComponent/MetaTagsComponent";

const CourseDetailsPage = () => {
  // i18next
  const { lang, section_id, course_id } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [lang]);

  useEffect(() => {
    i18n.changeLanguage(lang ?? "ar");
    // eslint-disable-next-line
  }, [lang]);

  // States
  const [copyToClipboard, setCopyToClipboard] = useState(false); // eslint-disable-line
  const [shareModalShow, setShareModalShow] = useState(false);

  moment.locale("en");

  const shareURL = window.location.href;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux
  // useEffect(() => {
  // 	dispatch(
  // 		fetchSingleCourse({
  // 			language: lang ?? 'ar',
  // 			searchParams: { id: course_id },
  // 		})
  // 	);
  // 	// eslint-disable-next-line
  // }, [lang, course_id]);

  // Redux
  const { categories } = useSelector((state) => state.categories);
  const { cities } = useSelector((state) => state.cities);
  // const {
  // 	singleCourse: { course, relatedCourses },
  // 	isSingleCourseLoading,
  // } = useSelector((state) => state.courses);

  //RTKQuery
  const {
    data: courseData,
    isLoading: courseLoading,
    isError: courseError,
  } = useGetCoursesDataByIdQuery({
    lang: lang ?? "ar",
    searchParams: { id: parseInt(course_id) },
  });
  console.log("Related Courses Data:", courseData?.related_courses);

  const breadcrumbItems =
    courseLoading || courseError ? (
      <MainPreloaderComponent />
    ) : (
      [
        {
          title: t("words:breadcrumb.home"),
          href: `/${lang}`,
          isActive: false,
        },
        {
          title: courseData?.course?.Section,
          href: `/${lang}/section/${section_id}`,
          isActive: false,
        },
        {
          title: courseData?.course?.category,
          href: `/${lang}/section/${section_id}/category/${courseData?.course?.category_id}`,
          isActive: false,
        },
        {
          title:
            courseData?.course?.category_id === null
              ? t("words:breadcrumb.conferenceDetails")
              : t("words:breadcrumb.courseDetails"),
          href: "",
          isActive: true,
        },
      ]
    );

  return courseLoading || courseError ? (
    <MainPreloaderComponent />
  ) : (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      lang={lang ?? "ar"}
      id="course-details-page"
      className="page overflow-visible"
    >
      <>
        {/* Meta Tags */}
        <MetaTagsComponent pageTitle={t("words:windowTab.courseDetails")} />

        {/* Breadcrumb */}
        <BreadcrumbComponent
          title={courseData?.course?.title}
          items={
            courseData?.course?.category
              ? breadcrumbItems
              : breadcrumbItems.filter(
                  (_, index) => index !== breadcrumbItems.length - 2
                )
          }
        />

        <Container fluid="md">
          <Row className="g-4">
            <Col xs={12} lg={8}>
              <Row xs={1} className="g-4">
                <Col>
                  {/* Course Preview Media */}
                  {courseData?.course?.use_media === 1 ? (
                    <iframe
                      className="rounded-2 w-100"
                      width="100%"
                      height="480"
                      src={courseData?.course?.video_link.replace(
                        "/watch?v=",
                        "/embed/"
                      )}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={t("words:coursePreview")}
                    />
                  ) : (
                    <Image
                      fluid
                      src={
                        courseData?.course?.image ??
                        require("./../../assets/images/logos/logo.png")
                      }
                      alt="course preview thumbnail"
                      className="course-preview rounded-2 text-capitalize w-100 h-100"
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        height: "28rem",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require("./../../assets/images/logos/logo.png");
                      }}
                    />
                  )}
                </Col>

                {/* Course Title */}
                <Col>
                  <h5
                    className="display-6 fw-bold p-0 m-0"
                    style={{
                      fontSize: "24px",
                      color: "#11142d",
                    }}
                  >
                    {courseData?.course?.title}
                  </h5>
                </Col>

                {/* Course Hint */}
                <Col>
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "#b94a4b",
                    }}
                  >
                    {courseData?.course?.hint}
                  </p>
                </Col>

                <Col>
                  <Row className="g-4">
                    {/* Enroll Now */}
                    <Col
                      xs={courseData?.course?.brochure ? 12 : 8}
                      sm={courseData?.course?.brochure ? 7 : 8}
                      md={courseData?.course?.brochure ? 6 : 8}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <CourseSubscriptionComponent
                        lang={lang ?? "ar"}
                        course={courseData?.course}
                        isConference={
                          courseData?.course?.category_id === null
                            ? true
                            : false
                        }
                      />
                    </Col>

                    {/* Download Brochure */}
                    {courseData?.course?.brochure && (
                      <Col
                        xs={8}
                        sm={5}
                        md={4}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <ButtonComponent
                          title={t("words:buttons.downloadBrochure")}
                          href={courseData?.course?.brochure}
                          target={true}
                          icon={
                            <FaDownload
                              className="position-relative"
                              style={{
                                margin:
                                  lang === "en" ? "0 8px 0 0" : "0 0 0 8px",
                                zIndex: "10",
                              }}
                            />
                          }
                          styles={{
                            button: {
                              width: "fit-content",
                              margin: "0 auto",
                            },
                          }}
                        />
                      </Col>
                    )}

                    {/* Share Course */}
                    <Col
                      xs={courseData?.course?.brochure ? 4 : 4}
                      sm={courseData?.course?.brochure ? 12 : 4}
                      md={courseData?.course?.brochure ? 2 : 4}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <IconTextComponent
                        className="share-btn"
                        icon={<FaShare size={20} />}
                        text={t("words:share")}
                        textStyles={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                        onClick={() => setShareModalShow(true)}
                      />

                      {/* Course Share */}
                      <Modal
                        show={shareModalShow}
                        onHide={() => {
                          setShareModalShow(false);
                          setCopyToClipboard(false);
                        }}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="text-capitalize">
                            {t("words:share")}
                          </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="p-0">
                          <>
                            <div
                              dir={lang === "en" ? "ltr" : "rtl"}
                              lang={lang ?? "ar"}
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                padding: "0.375rem 0.75rem",
                              }}
                            >
                              <WhatsappShareButton
                                url={shareURL}
                                className="share-icon-container"
                              >
                                <WhatsappIcon size={50} round={true} />
                                <div className="share-icon-title text-capitalize">
                                  {t("words:socials.whatsapp")}
                                </div>
                              </WhatsappShareButton>

                              <FacebookShareButton
                                url={shareURL}
                                className="share-icon-container"
                              >
                                <FacebookIcon size={50} round={true} />
                                <div className="share-icon-title text-capitalize">
                                  {t("words:socials.facebook")}
                                </div>
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={shareURL}
                                className="share-icon-container"
                              >
                                <TwitterIcon size={50} round={true} />
                                <div className="share-icon-title text-capitalize">
                                  {t("words:socials.twitter")}
                                </div>
                              </TwitterShareButton>

                              <EmailShareButton
                                url={shareURL}
                                className="share-icon-container"
                              >
                                <EmailIcon size={50} round={true} />
                                <div className="share-icon-title text-capitalize">
                                  {t("words:socials.email")}
                                </div>
                              </EmailShareButton>
                            </div>
                            <FormGroup
                              as={Col}
                              className="mb-3 position-relative"
                            >
                              <Form.Text
                                className="form-control"
                                style={{
                                  backgroundColor: "#e9ecef",
                                  paddingTop: "1rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                {window.location.href}
                              </Form.Text>
                              <CopyToClipboard
                                text={window.location.href}
                                onCopy={() => setCopyToClipboard(true)}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  overlay={
                                    <Popover id="popover-basic">
                                      <Popover.Body
                                        dir={lang === "en" ? "ltr" : "rtl"}
                                        lang={lang ?? "ar"}
                                        className="text-capitalize"
                                        style={{
                                          color: "rgb(0, 113, 220)",
                                        }}
                                      >
                                        {t("words:copied")}
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: "50%",
                                      right: "0.75rem",
                                      transform: "translate(0, -50%)",
                                    }}
                                  >
                                    <FiCopy size={30} />
                                  </div>
                                </OverlayTrigger>
                              </CopyToClipboard>
                            </FormGroup>
                          </>
                        </Modal.Body>
                      </Modal>
                    </Col>
                  </Row>

                  {/* Course Tabs */}
                  <Row
                    style={{
                      paddingTop: "100px",
                      marginTop: "-15px",
                    }}
                  >
                    <Tabs
                      defaultActiveKey="0"
                      id="fill-tab-example"
                      className="mb-3 text-capitalize"
                      fill
                      style={{
                        marginBottom: "45px",
                        borderBottom: "1px solid #e5f1fb",
                      }}
                    >
                      {courseData?.course?.tabs.map((tabDetails, index) => (
                        <Tab
                          key={index}
                          eventKey={index}
                          title={tabDetails.title}
                          dangerouslySetInnerHTML={{
                            __html: trimEmptyTags(tabDetails.description),
                          }}
                        ></Tab>
                      ))}
                    </Tabs>
                  </Row>
                </Col>
              </Row>

              {/* Related Courses */}
              {courseData?.related_courses?.data &&
                courseData?.related_courses?.data.length > 0 && (
                  <Row className="pt-4 g-4">
                    <Col>
                      <h3 className="my-0 py-0 fw-bold text-capitalize">
                        {t("words:relatedCourses")}
                      </h3>
                    </Col>
                  </Row>
                )}
              {courseData?.related_courses?.data &&
              courseData?.related_courses?.data.length > 0 ? (
                courseData.related_courses.data.length > 3 ? (
                  <Col className="mt-0">
                    <Swiper
                      dir={lang === "en" ? "ltr" : "rtl"}
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        768: { slidesPerView: 2, spaceBetween: 20 },
                        991: { slidesPerView: 3, spaceBetween: 20 },
                      }}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      grabCursor={true}
                      loop={true}
                      modules={[Autoplay]}
                    >
                      {courseData.related_courses.data.map((course, index) => (
                        <SwiperSlide
                          key={index}
                          style={{
                            padding: "30px 0.5rem 25px",
                            borderRadius: "12px",
                          }}
                        >
                          <CourseCardComponent course={course} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Col>
                ) : (
                  <Row
                    xs={1}
                    sm={2}
                    lg={3}
                    className="p-0 pt-4 g-4"
                    style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}
                  >
                    {courseData.related_courses.data.map((course, index) => (
                      <Fade key={index} delay={index * 50} direction="up">
                        <Col>
                          <CourseCardComponent course={course} />
                        </Col>
                      </Fade>
                    ))}
                  </Row>
                )
              ) : (
                <></> // or null, if you prefer
              )}
            </Col>

            {/* Categories & Cities Search */}
            <Col xs={12} lg={4} className="search-items position-relative">
              <Row xs={1} className="g-4">
                {/* Course Details */}
                <Col className="course-details position-relative">
                  <Card
                    className="overflow-hidden"
                    style={{
                      minHeight: "28rem",
                      position: "sticky",
                      top: "8rem",
                    }}
                  >
                    <Card.Header
                      as="h5"
                      className="d-flex justify-content-between align-items-center text-capitalize"
                    >
                      {/* Card Title */}
                      <span>
                        <IconTextComponent
                          text={
                            courseData?.course?.category_id === null
                              ? "words:conferenceDetails"
                              : t("words:courseDetails")
                          }
                          icon={<BiDetail size={20} />}
                        />
                      </span>

                      {/* Is New Badge */}
                      {courseData?.course?.is_new && (
                        <span
                          className="course-badge text-bg-warning text-capitalize fw-light animate__animated animate__heartBeat animate__infinite animate__slow animate__delay-2s"
                          style={{
                            "--animate-delay": "0.75s",
                            top: "15px",
                            left: lang === "en" ? "unset" : "20px",
                            right: lang === "en" ? "20px" : "unset",
                          }}
                        >
                          {t("words:new")}
                        </span>
                      )}
                    </Card.Header>
                    <Card.Body
                      style={{
                        color: "#689dec",
                        backgroundColor: "#f9fafd",
                      }}
                    >
                      {/* Section */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:section")}:`}
                        </span>
                        <span className="text-capitalize">
                          {courseData?.course?.Section}
                        </span>
                      </Card.Text>

                      {/* Category */}
                      {courseData?.course?.category && (
                        <Card.Text>
                          <span className="detail-title">
                            {`${t("words:category")}:`}
                          </span>
                          <span className="text-capitalize">
                            {courseData?.course?.category}
                          </span>
                        </Card.Text>
                      )}

                      {/* Code */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:code")}:`}
                        </span>
                        <span className="text-capitalize">
                          {courseData?.course?.code}
                        </span>
                      </Card.Text>

                      {/* City */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:city")}:`}
                        </span>
                        <span className="text-capitalize">
                          {courseData?.course?.city}
                        </span>
                      </Card.Text>

                      {/* Start Date */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:startDate")}:`}
                        </span>
                        <span>
                          {moment(courseData?.course?.start_date).format(
                            t("words:dateFormatter")
                          )}
                        </span>
                      </Card.Text>

                      {/* End Date */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:endDate")}:`}
                        </span>
                        <span>
                          {moment(courseData?.course?.end_date).format(
                            t("words:dateFormatter")
                          )}
                        </span>
                      </Card.Text>

                      {/* Period */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:duration")}:`}
                        </span>
                        <span>
                          {moment
                            .duration(
                              new moment(courseData?.course?.end_date)
                                .add(1, "days")
                                .diff(
                                  new moment(courseData?.course?.start_date)
                                )
                            )
                            .locale(lang ?? "ar")
                            .humanize(true)
                            .split(" ")
                            .filter((_, index) => index > 0)
                            .join(" ")}
                        </span>
                      </Card.Text>

                      {/* Price */}
                      <Card.Text>
                        <span className="detail-title">
                          {`${t("words:price")}:`}
                        </span>
                        <span className="text-capitalize">{`$${courseData?.course?.price}`}</span>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer
                      style={{
                        backgroundColor: "#f9fafd",
                      }}
                    >
                      {/* Enroll Now */}
                      <Col className="d-flex align-items-center justify-content-center">
                        <CourseSubscriptionComponent
                          course={courseData?.course}
                          isConference={
                            courseData?.course?.category_id === null
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Card.Footer>
                  </Card>
                </Col>

                {/* Categories */}
                <Col>
                  <Card>
                    <Card.Header>
                      <IconTextComponent
                        text={t("words:labels.category")}
                        icon={<FiFilter size={20} />}
                      />
                    </Card.Header>
                    <Card.Body>
                      {categories.map((category, index) => (
                        <Card.Text
                          key={index}
                          className="text-capitalize text-limit"
                          style={{ "--lines": 1 }}
                          onClick={() => {
                            navigate({
                              pathname: `/${lang}/search`,
                              search: createSearchParams({
                                category_id: category.id,
                              }).toString(),
                            });
                          }}
                        >
                          {category.title}
                        </Card.Text>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>

                {/* Cities */}
                <Col>
                  <Card>
                    <Card.Header>
                      <IconTextComponent
                        text={t("words:labels.city")}
                        icon={<MdOutlineLocationOn size={20} />}
                      />
                    </Card.Header>
                    <Card.Body>
                      {cities.map((city, index) => (
                        <Card.Text
                          key={index}
                          className="text-capitalize text-limit"
                          style={{ "--lines": 1 }}
                          onClick={() => {
                            navigate({
                              pathname: `/${lang}/search`,
                              search: createSearchParams({
                                city_id: city.id,
                              }).toString(),
                            });
                          }}
                        >
                          {city.title}
                        </Card.Text>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    </section>
  );
};

export default CourseDetailsPage;
