import "moment/locale/ar";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { trimEmptyTags } from "./../../helpers/general";

// Icons

// i18next
import { useTranslation } from "react-i18next";

// Redux
import { useSelector } from "react-redux";

// Styles
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./HomePage.styles.css";

// Components
import CategoryCardComponent from "./../../components/CategoryCardComponent/CategoryCardComponent";
import CityCardComponent from "./../../components/CityCardComponent/CityCardComponent";
import CourseCardComponent from "./../../components/CourseCardComponent/CourseCardComponent";
import FeatureCardComponent from "./../../components/FeatureCardComponent/FeatureCardComponent";
import GoToTopComponent from "./../../components/GoToTopComponent/GoToTopComponent";
import WhatsappIconComponent from "./../../components/WhatsappIconComponent/WhatsappIconComponent";
import InstructorRegistrationFormComponent from "./../../components/_Forms/InstructorRegistrationFormComponent/InstructorRegistrationFormComponent";
import MailboxSubscriptionFormComponent from "./../../components/_Forms/MailboxSubscriptionFormComponent/MailboxSubscriptionFormComponent";
import MainSearchFormComponent from "./../../components/_Forms/MainSearchFormComponent/MainSearchFormComponent";
import MetaTagsComponent from "./../../components/_MetaTagsComponent/MetaTagsComponent";
import CoursesSliderComponent from "../../components/CoursesSliderComponent/CoursesSliderComponent";

const HomePage = () => {
  // i18next
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang ?? "ar");
    // eslint-disable-next-line
  }, [lang]);

  moment.locale("en");

  const { homeData, mostEnrolledCourses, confirmedCourses } = useSelector(
    (state) => state.homeData
  );
  const { categories } = useSelector((state) => state.categories);
  const { cities } = useSelector((state) => state.cities);

  const [filteredCourses, setFilteredCourses] = useState([]);

  const [instructorModalShow, setInstructorModalShow] = useState(false);
  const [mailboxModalShow, setMailboxModalShow] = useState(false);

  useEffect(() => {
    setFilteredCourses(confirmedCourses);
  }, [confirmedCourses]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [lang]);

  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      lang={lang ?? "ar"}
      id="home-page"
      className="page"
    >
      {/* Meta Tags */}
      <MetaTagsComponent pageTitle={t("words:windowTab.home")} />

      {/* Scroll To Top Button */}
      <GoToTopComponent />

      {/* Whatsapp Icon Button */}
      <WhatsappIconComponent />

      <Container
        fluid="xs"
        className="banner-area position-relative d-flex justify-content-center align-items-center"
        style={{
          "--bg-image": `url(${homeData?.home_banner?.image})`,
        }}
      >
        {/* Hero Section */}
        <Container
          fluid="md"
          className="hero-section position-relative d-flex justify-content-start align-items-center"
          style={{
            zIndex: 10,
          }}
        >
          {/* Search Form */}
          <MainSearchFormComponent />
        </Container>

        {/* Categories Carousel */}
        <Container
          className="position-absolute top-100 py-3 overflow-hidden"
          style={{
            transform: "translateY(-30%)",
            zIndex: 100,
            paddingRight: "1.2rem",
            paddingLeft: "1.2rem",
          }}
        >
          {categories && (
            <Fade direction="down" delay={20}>
              <Swiper
                dir={lang === "en" ? "ltr" : "rtl"}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1440: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                grabCursor={true}
                loop={true}
                modules={[Autoplay]}
              >
                {categories.map((category, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      padding: "30px 0.5rem 25px",
                      borderRadius: "12px",
                    }}
                  >
                    <Link
                      to={`/${lang}/section/${category.section_id}/category/${category.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <CategoryCardComponent category={category} />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Fade>
          )}
        </Container>
      </Container>

      {/* Cities Slider */}
      <Container
        fluid="lg"
        style={{
          paddingTop: "12.5rem",
          // paddingBottom: '2.5rem',
        }}
      >
        {cities.length > 0 && (
          <Container
            fluid="md"
            className="pt-5 overflow-hidden"
            style={{
              paddingBottom: "3.5rem",
            }}
          >
            <Fade direction="up" delay={50}>
              <h2
                className="mb-4 fw-bold text-capitalize"
                style={{
                  color: "#11142d",
                  width: "70%",
                  fontSize: window.innerWidth < 768 ? "20px" : "28px",
                }}
              >
                {t("words:availableCities")}
              </h2>
            </Fade>
            <Fade delay={50} direction="up">
              <Swiper
                dir={lang === "en" ? "ltr" : "rtl"}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                grabCursor={true}
                loop={true}
                navigation={true}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
              >
                {cities.map((city, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      paddingRight: "0.5rem",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <CityCardComponent city={city} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Fade>
          </Container>
        )}
      </Container>

      {/* Most Requested Courses */}
      {mostEnrolledCourses?.length > 0 && (
        <Container
          fluid="md"
          className="py-5 overflow-hidden"
          style={{
            paddingRight: "1.2rem",
            paddingLeft: "1.2rem",
          }}
        >
          <Fade delay={50} direction="up">
            <h2
              className={`text-capitalize mb-4 fw-bold ${
                window.innerWidth < 768 ? "text-center" : ""
              }`}
              style={{
                color: "#11142d",
                fontSize: window.innerWidth < 768 ? "20px" : "28px",
              }}
            >
              {t("sentences:mostRequestedCourses")}
            </h2>
          </Fade>
          <Fade direction="up">
            <Swiper
              dir={lang === "en" ? "ltr" : "rtl"}
              slidesPerView={1}
              spaceBetween={20}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 4,
                  spaceBetween: 25,
                },
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              grabCursor={true}
              loop={true}
              navigation={true}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Navigation, Pagination]}
            >
              {mostEnrolledCourses &&
                mostEnrolledCourses.map((course, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      paddingRight: "0.5rem",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <CourseCardComponent course={course} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Fade>
        </Container>
      )}

      {/* Features */}
      {homeData?.features?.length > 0 && (
        <Container
          fluid="md"
          className="py-5"
          style={{
            paddingRight: "1.2rem",
            paddingLeft: "1.2rem",
          }}
        >
          <Fade delay={50} direction="up">
            <Row xs={1} className="g-1 justify-content-center">
              <Col>
                <h2
                  className="mb-4 text-capitalize text-center"
                  style={{
                    color: "#11142d",
                    fontSize: window.innerWidth < 768 ? "20px" : "28px",
                  }}
                >
                  {homeData["join_us"].title}
                </h2>
              </Col>
              <Col md={8}>
                <p
                  className="text-center"
                  style={{
                    color: "#808191",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: trimEmptyTags(homeData["join_us"].description),
                  }}
                ></p>
              </Col>
            </Row>
          </Fade>

          <Row
            xs={1}
            md={2}
            xl={3}
            className="g-4 mt-2"
            style={{
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
            }}
          >
            {homeData.features.map((feature, index) => (
              <Fade key={index} delay={index * 50} direction="up">
                <Col>
                  {/* Join As Instructor */}
                  {feature.id === 18 && (
                    <>
                      <FeatureCardComponent
                        feature={feature}
                        onClick={() => setInstructorModalShow(true)}
                      />
                      <Modal
                        dir={lang === "en" ? "ltr" : "rtl"}
                        lang={lang ?? "ar"}
                        show={instructorModalShow}
                        onHide={() => setInstructorModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Body>
                          <InstructorRegistrationFormComponent
                            setInstructorModalShow={setInstructorModalShow}
                          />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}

                  {/* Contact Us */}
                  {feature.id === 17 && (
                    <Link
                      to={`/${lang}/contact-us`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <FeatureCardComponent feature={feature} />
                    </Link>
                  )}

                  {/* Mailbox Subscription */}
                  {feature.id === 8 && (
                    <>
                      <FeatureCardComponent
                        feature={feature}
                        onClick={() => setMailboxModalShow(true)}
                      />
                      <Modal
                        dir={lang === "en" ? "ltr" : "rtl"}
                        lang={lang ?? "ar"}
                        show={mailboxModalShow}
                        onHide={() => setMailboxModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Body>
                          <MailboxSubscriptionFormComponent
                            mailboxModalShow={mailboxModalShow}
                            setMailboxModalShow={setMailboxModalShow}
                          />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </Col>
              </Fade>
            ))}
          </Row>
        </Container>
      )}

      {/* Confirmed Courses */}
      {confirmedCourses?.length > 0 && (
        <Container
          fluid="md"
          className="py-5 overflow-hidden"
          style={{
            paddingRight: "1.2rem",
            paddingLeft: "1.2rem",
          }}
        >
          <Fade direction="up" delay={50}>
            <h2
              className={`text-capitalize mb-4 fw-bold ${
                window.innerWidth < 768 ? "text-center" : ""
              }`}
              style={{
                color: "#11142d",
                fontSize: window.innerWidth < 768 ? "20px" : "28px",
                marginBottom: "45px",
              }}
            >
              {t("sentences:confirmedCourses")}
            </h2>
          </Fade>
          <Fade direction="up" delay={50}>
            {/* Categories Filter */}
            <Swiper
              dir={lang === "en" ? "ltr" : "rtl"}
              slidesPerView={"auto"}
              spaceBetween={window.innerWidth < 992 ? 10 : 30}
              grabCursor={true}
              navigation={true}
              modules={[Navigation]}
              className="courses-filter overflow-hidden"
              style={{
                marginBottom: "30px",
                paddingRight: "55px",
                paddingLeft: "55px",
              }}
            >
              <SwiperSlide
                className="text-nowrap text-capitalize nav-link active"
                onClick={(event) => {
                  setFilteredCourses(confirmedCourses);

                  document
                    .querySelectorAll(".courses-filter .nav-link")
                    .forEach((navLink) => {
                      if (navLink.classList.contains("active")) {
                        navLink.classList.remove("active");
                      }
                    });

                  if (!event.target.classList.contains("active")) {
                    event.target.classList.add("active");
                  }
                }}
              >
                {t("words:allCourses")}
              </SwiperSlide>

              {Array.from(
                new Set(confirmedCourses?.map((course) => course.category))
              )
                ?.filter(
                  (category) =>
                    category !== "" &&
                    category !== null &&
                    category !== undefined
                )
                ?.map((category, index) => (
                  <SwiperSlide
                    key={index}
                    className="text-nowrap nav-link"
                    onClick={(event) => {
                      setFilteredCourses(
                        confirmedCourses.filter(
                          (course) => course.category === category
                        )
                      );

                      document
                        .querySelectorAll(".courses-filter .nav-link")
                        .forEach((navLink) => {
                          if (navLink.classList.contains("active")) {
                            navLink.classList.remove("active");
                          }
                        });

                      if (!event.target.classList.contains("active")) {
                        event.target.classList.add("active");
                      }
                    }}
                  >
                    {category}
                  </SwiperSlide>
                ))}
            </Swiper>

            {/* Filtered Courses */}
            <CoursesSliderComponent
              courses={filteredCourses}
              hasNavigation={false}
            />
          </Fade>
        </Container>
      )}

      {/* Certificates */}
      {homeData?.certificates?.length > 0 && (
        <Container
          fluid="md"
          className="certificates"
          style={{
            marginTop: "1.2rem",
            paddingRight: "1.2rem",
            paddingLeft: "1.2rem",
          }}
        >
          <Fade delay={100}>
            <Swiper
              className="overflow-hidden"
              dir={lang === "en" ? "ltr" : "rtl"}
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              grabCursor={true}
              loop={true}
              modules={[Autoplay]}
              style={{
                borderTop: "1px solid #e5f1fb",
              }}
            >
              {homeData.certificates.map((certificate, index) => (
                <SwiperSlide
                  key={index}
                  className="d-flex justify-content-center align-items-center h-100"
                >
                  <Image
                    fluid
                    src={certificate.image}
                    alt="certificate thumbnail"
                    className="certificate-image img-fluid text-capitalize w-100"
                    style={{
                      height: "10rem",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = require("./../../assets/images/logos/logo.png");
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Fade>
        </Container>
      )}
    </section>
  );
};

export default HomePage;
